import { Colors } from '@blueprintjs/core';
import PropTypes from 'prop-types';

import Text from '../Text';
import Tooltip from '../Tooltip';
import Box from '../Box';
import Icon from '../Icon';

const ErrorIconTooltip = ({ children, ...containerProps }) => {
  return (
    <Tooltip
      placement="bottom-start"
      hoverOpenDelay={300}
      content={
        <Box
          style={{
            overflowWrap: 'break-word',
            whiteSpace: 'wrap',
            maxWidth: '300px',
          }}
        >
          <Text disableGutter tag="div">
            {children}
          </Text>
        </Box>
      }
    >
      <Box {...containerProps}>
        <Icon name="circle-exclamation" color={Colors.RED3} secondaryColor="Colors.RED3" swapOpacity />
      </Box>
    </Tooltip>
  );
};

ErrorIconTooltip.propTypes = {
  children: PropTypes.string.isRequired,
  containerProps: PropTypes.object,
};

ErrorIconTooltip.defaultProps = {
  containerProps: {},
};

export default ErrorIconTooltip;
