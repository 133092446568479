/* eslint-disable no-template-curly-in-string */
import * as yup from 'yup';

yup.setLocale({
  string: {
    min: 'Min length is ${min} character(s).',
    max: 'Max length is ${max} character(s).',
  },
  mixed: {
    required: 'Required field.',
  },
  number: {
    integer: 'This field must be an integer.',
    lessThan: 'This field must be less than ${less}.',
    moreThan: 'This field must be more than ${more}.',
    min: 'This field must be greater than or equal to ${min}.',
    max: 'This field must be less than or equal to ${max}.',
  },
});

yup.addMethod(yup.string, 'validJSON', function (errorMessage) {
  return this.test(`test-json`, errorMessage, function (value) {
    const { path, createError } = this;
    // Possibility for field to be optional
    if (value === '' || value === undefined) {
      return true;
    }

    try {
      JSON.parse(value);
    } catch (e) {
      return createError({ path, message: errorMessage });
    }
    return true;
  });
});

yup.addMethod(yup.string, 'validTransformationReturn', function (errorMessage = 'Use characters a-z, 0-9 and _') {
  return this.test(`test-return`, errorMessage, function (value) {
    const { path, createError } = this;

    if (value?.length > 0 && !value[0].match(/[a-z]/)) {
      return createError({ path, message: 'Value must start with a lowercase letter' });
    }

    const rgx = /[a-z0-9_]*$/;

    if (rgx.test(value)) {
      return true;
    }

    return createError({ path, message: errorMessage });
  });
});

yup.addMethod(
  yup.string,
  'validEntityName',
  function (
    errorMessage = 'First character must be a letter (A-Z, a-z) or underscore (_). Other characters may include letters (A-Z, a-z), numbers (0-9), underscores (_), hyphens(-).',
  ) {
    return this.test(`test-entity-name`, errorMessage, function (value) {
      const { path, createError } = this;

      const rgx = /^[a-zA-Z_][a-zA-Z0-9_\- ]{2,249}$/;

      if (rgx.test(value)) {
        return true;
      }

      return createError({ path, message: errorMessage });
    });
  },
);

yup.addMethod(
  yup.string,
  'validEntityLabel',
  function (errorMessage = 'Only numbers (0-9) and letters (a-z) are allowed.') {
    return this.test(`test-entity-name`, errorMessage, function (value) {
      const { path, createError } = this;

      const rgx = /^[a-zA-Z0-9]{1,3}$/;

      if (rgx.test(value)) {
        return true;
      }

      return createError({ path, message: errorMessage });
    });
  },
);

yup.addMethod(yup.array, 'unique', function (message, mapper = (a) => a) {
  return this.test('unique', message, (list) => {
    return list.length === new Set(list.map(mapper)).size;
  });
});

yup.addMethod(yup.array, 'uniqueByFields', function (message, aMapper = (a) => a, bMapper = (b) => b) {
  return this.test('unique-by-fields', message, (list) => {
    return list.length * 2 === new Set([...list.map(aMapper), ...list.map(bMapper)]).size;
  });
});

yup.addMethod(yup.array, 'every', function (message, mapper = (a) => a) {
  return this.test('every', message, function (list) {
    const { path, createError } = this;
    return list.every(mapper) ? createError({ path, message }) : true;
  });
});

yup.addMethod(yup.array, 'uniquePairs', function (errorMessage) {
  return this.test(`test-unique-pairs`, errorMessage, function (value) {
    const errorMessages = value
      .filter(({ left, right }) => left && right)
      .map(({ left, right }) => `Duplicate relationship found [${left}] <=> [${right}]`);
    const uniqueErrorMessages = new Set(errorMessages);

    if (errorMessages.length !== uniqueErrorMessages.size) {
      const duplicateErrorMessage = errorMessages.find((message, index) => errorMessages.indexOf(message) !== index);

      return this.createError({
        path: 'conditions',
        message: duplicateErrorMessage,
      });
    }

    return true;
  });
});

yup.addMethod(
  yup.array,
  'differentValues',
  function (errorMessage, fields, path, isFormFieldDynamicArrayWithNoParent = false) {
    const [fieldA, fieldB] = fields;

    return this.test(`test-different-values`, errorMessage, function (value) {
      if (!isFormFieldDynamicArrayWithNoParent) {
        const condition = value.some((obj) => obj[fieldA] === obj[fieldB]);

        if (condition) {
          return this.createError({
            path,
            message: errorMessage,
          });
        }

        return true;
      }

      const conditionIndex = this.originalValue.findIndex((obj) => obj[fieldA] === obj[fieldB]);

      if (conditionIndex >= 0) {
        return this.createError({
          path: `${path}.${conditionIndex}.${fieldA}`,
          message: errorMessage,
        });
      }

      return true;
    });
  },
);

export default yup;
