import { Tooltip } from '@blueprintjs/core';
import PropTypes from 'prop-types';
import { useLayoutEffect, useRef, useState } from 'react';

import Box from './Box';
import Text from './Text';

export const EllipseText = ({ value, limitType, limit, textStyle, textProps, toolTipContentStyle }) => {
  const textRef = useRef(null);
  const [isTextMultiLine, setIsTextMultiLine] = useState(true);

  useLayoutEffect(() => {
    if (!textRef.current) {
      return;
    }
    setIsTextMultiLine(textRef.current.scrollHeight / 2 >= textRef.current.clientHeight);
  }, [value]);

  if (!value) return null;

  return (
    <Tooltip
      disabled={limitType === 'chars' ? String(value).length < limit : !isTextMultiLine}
      content={
        <Box
          style={{
            overflowWrap: 'break-word',
            whiteSpace: 'wrap',
            maxWidth: '300px',
            ...toolTipContentStyle,
          }}
        >
          <Text disableGutter tag="div">
            {value}
          </Text>
        </Box>
      }
    >
      <Text
        tagName="div"
        textRef={textRef}
        style={{
          whiteSpace: limitType === 'chars' ? 'nowrap' : 'normal',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          ...textStyle,
        }}
        {...(limitType === 'lines' ? { clampLines: limit } : {})}
        {...textProps}
      >
        {value}
      </Text>
    </Tooltip>
  );
};

EllipseText.propTypes = {
  value: PropTypes.string.isRequired,
  limitType: PropTypes.oneOf(['chars', 'lines']),
  limit: PropTypes.number,
  textStyle: PropTypes.object,
  toolTipContentStyle: PropTypes.object,
  textProps: PropTypes.object,
};

EllipseText.defaultProps = {
  limitType: 'chars',
  textStyle: {},
  toolTipContentStyle: {},
  textProps: {},
  limit: 39,
};
