import { Button, Collapse, Colors } from '@blueprintjs/core';
import { DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, arrayMove, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { cloneElement, forwardRef, useCallback, useImperativeHandle, useState } from 'react';

import { getNestedPropertyValue } from '../utils/functions';

import Box from './Box';
import Icon from './Icon';
import Stack from './Stack';
import './Table2.scss';
import Text from './Text';

const TableRow = ({
  record,
  showRowsDivider,
  index,
  isLast,
  headerColumns,
  renderColumn,
  recordsMetadata,
  expandedRowIndexes,
  children,
  style,
  className,
}) => {
  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        style={{
          width: '100%',
          ...style,
        }}
        className={classNames({
          'table-border': showRowsDivider && !isLast && !expandedRowIndexes[index],
          [className]: className,
        })}
        px={2}
        py={0.5}
        gap={2}
      >
        {headerColumns.map(({ field, basis, empty, style: headerStyle = {} }) => (
          <Box
            key={field}
            className="column-wrapper"
            style={{
              ...(basis ? { flexBasis: basis } : { flex: 1 }),
              wordBreak: 'break-word',
              ...headerStyle,
            }}
          >
            {renderColumn({ index, field, record, empty })}
          </Box>
        ))}

        {children}
      </Stack>

      {headerColumns.map(({ field }) => {
        if (recordsMetadata?.[index]?.[field]?.type === 'collapse') {
          return (
            <Collapse key={field} className="fill" isOpen={expandedRowIndexes[index] === field}>
              {recordsMetadata[index][field].value}
            </Collapse>
          );
        }

        return null;
      })}
    </>
  );
};

TableRow.propTypes = {
  record: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  showRowsDivider: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  isLast: PropTypes.bool.isRequired,
  headerColumns: PropTypes.array.isRequired,
  renderColumn: PropTypes.func.isRequired,
  recordsMetadata: PropTypes.array.isRequired,
  expandedRowIndexes: PropTypes.object.isRequired,
  style: PropTypes.object,
  className: PropTypes.string,
};

TableRow.defaultProps = {
  style: {},
  className: '',
};

const SortableTableRow = ({ id, index, isFirst, isLast, setRecordsOrder, children }) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} className="draggable-row">
      {cloneElement(children, {
        children: (
          <Stack direction="row" alignItems="center" gap={1} className="column-wrapper">
            <Button
              style={{ visibility: isFirst ? 'hidden' : 'visible' }}
              onClick={() => setRecordsOrder((currentRecordsOrder) => arrayMove(currentRecordsOrder, index, index - 1))}
            >
              <Icon name="chevron-up" />
            </Button>

            <Button
              style={{ visibility: isLast ? 'hidden' : 'visible' }}
              onClick={() => setRecordsOrder((currentRecordsOrder) => arrayMove(currentRecordsOrder, index, index + 1))}
            >
              <Icon name="chevron-down" />
            </Button>

            <Button minimal {...listeners} style={{ cursor: isDragging ? 'grabbing' : 'grab' }}>
              <Icon name="grip-lines" />
            </Button>
          </Stack>
        ),
      })}
    </div>
  );
};

SortableTableRow.propTypes = {
  id: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
  isFirst: PropTypes.bool.isRequired,
  isLast: PropTypes.bool.isRequired,
  setRecordsOrder: PropTypes.func.isRequired,
};

const Table2 = forwardRef(
  (
    {
      headerColumns,
      records,
      recordsMetadata,
      containerProps,
      emptyMessage,
      emptyMessageStyles,
      maxHeight,
      minHeight,
      flexEndLastItem,
      showRowsDivider,
      showEmptyTable,
      showHeaderBorder,
      showBorderTop,
      sortable,
      minimal,
      tableStyle,
      tableClassName,
    },
    ref,
  ) => {
    const sensors = useSensors(useSensor(PointerSensor));
    const [recordsOrder, setRecordsOrder] = useState(Array.from({ length: records.length }, (_, i) => `${i}`));
    const [expandedRowIndexes, setExpandedRowIndexes] = useState({});

    const handleRecordMetadata = useCallback(
      ({ value, index, field, recordMetadata }) => {
        if (recordMetadata.type === 'collapse' && typeof value !== 'string') {
          return cloneElement(value, {
            onClick: () => {
              const tempState = { ...expandedRowIndexes };
              if (tempState[index] === field) {
                tempState[index] = null;
              } else {
                tempState[index] = field;
              }

              setExpandedRowIndexes({ ...tempState });
            },
            ...(expandedRowIndexes[index] === field ? recordMetadata.expandedProps : recordMetadata.collapsedProps),
          });
        }

        return value;
      },
      [expandedRowIndexes],
    );

    const renderColumn = useCallback(
      ({ index, field, record, empty }) => {
        const recordMetadata = recordsMetadata?.[index]?.[field];
        let value = getNestedPropertyValue(field, record);

        if (recordMetadata) {
          value = handleRecordMetadata({ value, index, field, recordMetadata });
        }

        return value || (empty ?? '-');
      },
      [handleRecordMetadata, recordsMetadata],
    );

    const handleDragEnd = ({ active, over }) => {
      if (active.id !== over.id) {
        setRecordsOrder((currentRecords) => {
          const oldIndex = currentRecords.indexOf(active.id);
          const newIndex = currentRecords.indexOf(over.id);
          return arrayMove(currentRecords, oldIndex, newIndex);
        });
      }
    };

    useImperativeHandle(ref, () => ({
      getOrderedRecords: () => recordsOrder.map((recordOrder) => records[recordOrder]),
    }));

    return (
      <DndContext sensors={sensors} modifiers={[restrictToVerticalAxis]} onDragEnd={handleDragEnd}>
        <Box
          className={tableClassName}
          style={{
            position: 'relative',
            width: '100%',
            ...(maxHeight ? { maxHeight, overflowY: 'auto' } : {}),
            ...(minHeight ? { minHeight } : {}),
            ...tableStyle,
          }}
          {...containerProps}
        >
          {/* Table Header row */}
          {!records?.length && !showEmptyTable ? (
            <Box py={1} px={2}>
              <Text disableGutter color={Colors.GRAY3} style={emptyMessageStyles}>
                {emptyMessage}
              </Text>
            </Box>
          ) : (
            <>
              <Stack
                direction="row"
                alignItems="center"
                mb={0.5}
                py={1}
                px={2}
                gap={2}
                className={classNames('table2-heading-wrapper', {
                  'table2-heading-wrapper--topBorder': showBorderTop,
                  'table2-heading-wrapper--border': showHeaderBorder,
                  'table2-heading-wrapper--minimal': minimal,
                })}
                style={{
                  position: 'sticky',
                  top: 0,
                  zIndex: 1,
                }}
              >
                {[...headerColumns, ...(sortable ? [{ name: 'Order' }] : [])].map(({ name, field, basis }, i) => (
                  <Box
                    key={field}
                    style={{
                      ...(basis ? { flexBasis: basis } : { flex: 1 }),
                    }}
                  >
                    <Text
                      disableGutter
                      className="table2-heading-wrapper--text"
                      small
                      style={{
                        ...(flexEndLastItem && i === headerColumns.length - 1 ? { textAlign: 'end' } : {}),
                      }}
                    >
                      {name}
                    </Text>
                  </Box>
                ))}
              </Stack>
              {/* Table body */}
              {sortable ? (
                <SortableContext items={recordsOrder} strategy={verticalListSortingStrategy}>
                  {recordsOrder.map((recordOrder, index) => {
                    return (
                      <SortableTableRow
                        id={recordOrder}
                        key={recordOrder}
                        index={index}
                        isFirst={index === 0}
                        isLast={index === recordsOrder.length - 1}
                        setRecordsOrder={setRecordsOrder}
                      >
                        <TableRow
                          index={index}
                          record={records[recordOrder]}
                          isLast={index === recordsOrder.length - 1}
                          showRowsDivider={showRowsDivider}
                          headerColumns={headerColumns}
                          renderColumn={renderColumn}
                          recordsMetadata={recordsMetadata}
                          expandedRowIndexes={expandedRowIndexes}
                        />
                      </SortableTableRow>
                    );
                  })}
                </SortableContext>
              ) : (
                records.map((record, index) => (
                  <TableRow
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    index={index}
                    record={record}
                    isLast={index === records.length - 1}
                    showRowsDivider={showRowsDivider}
                    headerColumns={headerColumns}
                    renderColumn={renderColumn}
                    recordsMetadata={recordsMetadata}
                    expandedRowIndexes={expandedRowIndexes}
                    style={record.style}
                    className={record.className}
                  />
                ))
              )}
            </>
          )}
        </Box>
      </DndContext>
    );
  },
);

Table2.propTypes = {
  headerColumns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
      field: PropTypes.string.isRequired,
      styles: PropTypes.object,
    }),
  ),
  records: PropTypes.array,
  recordsMetadata: PropTypes.array,
  containerProps: PropTypes.object,
  emptyMessage: PropTypes.string,
  emptyMessageStyles: PropTypes.object,
  maxHeight: PropTypes.string,
  minHeight: PropTypes.string,
  flexEndLastItem: PropTypes.bool,
  showRowsDivider: PropTypes.bool,
  showEmptyTable: PropTypes.bool,
  showHeaderBorder: PropTypes.bool,
  showBorderTop: PropTypes.bool,
  sortable: PropTypes.bool,
  minimal: PropTypes.bool,
  tableStyle: PropTypes.object,
  tableClassName: PropTypes.string,
};

Table2.defaultProps = {
  headerColumns: [],
  records: [],
  recordsMetadata: [],
  containerProps: {},
  emptyMessage: 'There are no records to show',
  emptyMessageStyles: {},
  maxHeight: undefined,
  minHeight: undefined,
  flexEndLastItem: false,
  showRowsDivider: false,
  showEmptyTable: false,
  showHeaderBorder: false,
  showBorderTop: false,
  sortable: false,
  minimal: false,
  tableStyle: {},
  tableClassName: '',
};

export default Table2;
