import { Text, MenuItem, Button, Colors } from '@blueprintjs/core';
import { MultiSelect as BlueprintMultiSelect } from '@blueprintjs/select';
import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';
import reactRequiredIf from 'react-required-if';

import { getNestedPropertyValue } from '../../utils/functions';
import Box from '../Box';
import Stack from '../Stack';
import Icon from '../Icon';

import InputHelperLabel from './InputHelperLabel';
import InputLabel from './InputLabel';
import ErrorIconTooltip from './ErrorIconTooltip';

const defaultCreateNewItemRenderer = (query, active, handleClick) => {
  return (
    <MenuItem
      icon="add"
      text={`Create "${query}"`}
      roleStructure="listoption"
      active={active}
      onClick={handleClick}
      shouldDismissPopover={false}
    />
  );
};

const defaultFilterItem = (query, item, _index, exactMatch) => {
  const normalizedSearchBy = item.label?.toLowerCase();
  const normalizedQuery = query?.toLowerCase();

  if (exactMatch) {
    return normalizedSearchBy === normalizedQuery;
  } else {
    return item.label.indexOf(normalizedQuery) >= 0;
  }
};

const MultiSelect = ({
  id,
  formik,
  items,
  itemRenderer,
  showClearButton,
  handleClear,
  renderTag,
  label,
  handleItemSelect,
  tagInputProps,
  filterItem,
  allowCreate,
  createNewItemRenderer,
  createNewItemFromQuery,
  noResults,
  onRemoveCb,
  onItemSelectCb,
  labelStyle,
  containerProps,
  tooltipContent,
  tooltipProps,
  helperText,
  errorTextStyle,
  ...rest
}) => {
  const getPropValue = useCallback((path, object) => getNestedPropertyValue(path, object), []);
  const isError = getPropValue(id, formik.touched) && Boolean(getPropValue(id, formik.errors));
  const itemValues = useMemo(() => getPropValue(id, formik.values) || [], [getPropValue, id, formik.values]);

  const getSelectedItemIndex = (val) => {
    return itemValues.indexOf(val);
  };

  const isItemSelected = (val) => {
    return getSelectedItemIndex(val) !== -1;
  };

  const handleTagRemove = (item, index) => {
    formik.setFieldValue(
      id,
      itemValues.filter((_, i) => i !== index),
    );

    if (onRemoveCb && typeof onRemoveCb === 'function') {
      onRemoveCb(item);
    }
  };

  const defaultItemRenderer = (item, { handleClick, handleFocus, modifiers, ref }) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }

    return (
      <MenuItem
        selected={isItemSelected(item.value)}
        shouldDismissPopover={false}
        active={modifiers.active}
        disabled={modifiers.disabled}
        elementRef={ref}
        key={item.value}
        label={<Text>{item.extraLabel}</Text>}
        onClick={handleClick}
        onFocus={handleFocus}
        roleStructure="listoption"
        text={<Text>{item.label}</Text>}
      />
    );
  };

  const defaultHandleItemSelect = (item) => {
    if (!isItemSelected(item.value)) {
      formik.setFieldValue(id, [...itemValues, item.value]);
    } else {
      handleTagRemove(item.value, getSelectedItemIndex(item.value));
    }

    if (onItemSelectCb && typeof onItemSelectCb === 'function') {
      onItemSelectCb(item);
    }
  };

  const defaultHandleClear = () => {
    formik.setFieldValue(id, []);
  };

  return (
    <Box position="relative" width="100%" pb={0.5} {...containerProps}>
      <InputLabel
        id={id}
        label={label}
        labelStyle={labelStyle}
        tooltipContent={tooltipContent}
        tooltipProps={tooltipProps}
      />

      <BlueprintMultiSelect
        className={{ 'bp5-input-group bp5-intent-danger': isError }}
        createNewItemFromQuery={allowCreate ? createNewItemFromQuery : undefined}
        createNewItemRenderer={allowCreate ? createNewItemRenderer : null}
        items={items || []}
        itemsEqual={(a, b) => {
          const valA = typeof a === 'string' ? a : a.value;
          const valB = typeof b === 'string' ? b : b.value;
          return valA === valB;
        }}
        itemPredicate={filterItem}
        itemRenderer={itemRenderer || defaultItemRenderer}
        tagRenderer={renderTag || ((itemValue) => items.find((item) => item.value === itemValue)?.label)}
        selectedItems={itemValues}
        onItemSelect={handleItemSelect || defaultHandleItemSelect}
        onClear={showClearButton ? handleClear || defaultHandleClear : undefined}
        noResults={noResults}
        onRemove={handleTagRemove}
        resetOnSelect
        tagInputProps={{
          ...tagInputProps,
          rightElement: (
            <Stack
              direction="row"
              alignItems="center"
              gap={0.5}
              style={{ height: 30, position: 'relative' }}
              className="input-group-right-element"
            >
              {isError && <ErrorIconTooltip>{getNestedPropertyValue(id, formik.errors)}</ErrorIconTooltip>}
              {tagInputProps.rightElement}
              {getPropValue(id, formik.values) && showClearButton && (
                <Button
                  icon={<Icon name="close" />}
                  minimal
                  onClick={() => {
                    formik.setFieldValue(id, []);
                    formik.setFieldTouched(id, false);
                  }}
                  style={{
                    color: Colors.GRAY4,
                    marginRight: '2px',
                  }}
                />
              )}
            </Stack>
          ),
        }}
        {...rest}
      />

      <InputHelperLabel
        id={id}
        isError={isError}
        errorTextStyle={errorTextStyle}
        formikErrors={formik?.errors}
        helperText={helperText}
      />
    </Box>
  );
};

MultiSelect.propTypes = {
  id: PropTypes.string.isRequired,
  formik: PropTypes.object.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      extraLabel: PropTypes.string,
    }),
  ).isRequired,
  itemRenderer: PropTypes.func,
  showClearButton: PropTypes.bool,
  handleClear: PropTypes.func,
  renderTag: PropTypes.func,
  label: PropTypes.string.isRequired,
  handleItemSelect: PropTypes.func,
  tagInputProps: PropTypes.object,
  filterItem: PropTypes.func,
  allowCreate: PropTypes.bool,
  createNewItemRenderer: PropTypes.func,
  createNewItemFromQuery: reactRequiredIf(PropTypes.func, (props) => props.allowCreate),
  noResults: PropTypes.node,
  onRemoveCb: PropTypes.func,
  onItemSelectCb: PropTypes.func,
  labelStyle: PropTypes.object,
  containerProps: PropTypes.object,
  tooltipContent: PropTypes.node,
  tooltipProps: PropTypes.object,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  errorTextStyle: PropTypes.object,
};

MultiSelect.defaultProps = {
  itemRenderer: null,
  showClearButton: false,
  allowCreate: false,
  handleClear: null,
  handleItemSelect: null,
  tagInputProps: {},
  createNewItemFromQuery: null,
  noResults: <MenuItem disabled text="No results." roleStructure="listoption" />,
  createNewItemRenderer: defaultCreateNewItemRenderer,
  filterItem: defaultFilterItem,
  renderTag: undefined,
  onRemoveCb: undefined,
  onItemSelectCb: undefined,
  labelStyle: {},
  containerProps: {},
  tooltipContent: null,
  tooltipProps: {},
  helperText: null,
  errorTextStyle: {},
};

export default MultiSelect;
