import config from './config.json';

export const FEATURE_FLAG = import.meta.env.VITE_FEATURE_FLAG;

export const IAM_API_BASE_URL = config.NC_IAM_API;

export const REGISTRY_BASE_URL = config.NC_REGISTRY_API;

export const GENERAL_ERR_MSG = 'Oops. Something went wrong, please try again.';

export const spacingUnit = 8;

export const TOPBAR_HEIGHT = 70;

export const ISO_DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_TIME_FORMAT = 'DD MMM YYYY hh:mm A';
export const DATE_TIME_FORMAT_2 = 'MMMM DD, YYYY hh:mm A';

export const CAST_TYPE_TO_PSQL_TYPE_MAP = {
  string: 'VARCHAR',
  integer: 'INTEGER',
  long: 'BIGINT',
  float: 'REAL',
  double: 'DOUBLE',
  timestamp: 'TIMESTAMP',
  date: 'DATE',
  boolean: 'BOOLEAN',
  decimal: 'DECIMAL',
};

export const ERROR_CODES = {
  INSUFFICIENT_PERMISSION: 'A100',
};

export const API_ERROR_CODE_TO_MESSAGE_MAP = {
  K110: 'Application already exists.',
  K114: 'Application in invalid state.',
  K112: 'Application not found.',
  K115: 'Application not ready.',
  K113: 'Application run not found.',
  K005: 'Kuberneters object already exists.',
  K003: 'Kubernetes communication error.',
  K004: 'Kubernetes object not found.',
  K111: 'Root application not found.',
  K120: 'Application run creation failed.',
  K100: 'Secret already exists.',
  K101: 'Secret not found.',
  K000: 'Unexpected postgres error.',
  K002: 'Unhandled k8s-manager error.',
  K001: 'Unsupported request type.',
  K121: 'Validation error.',
  KF001: 'Kafka client error.',
  KF004: 'Kafka resource not found.',
  KF003: 'Topic already exists.',
  KF005: 'Invalid input.',
  D001: 'Postgres connection failure.',
  D003: 'Row already exists.',
  D002: 'Row not found.',
  TR004: 'Unsupported schema action.',
  TR002: 'Statement syntax error.',
  TR001: 'Table already exists.',
  TR003: 'Unsupported type.',
  D000: 'Unhandled postgres error occurred.',
  KF002: 'Unhandled Kafka error.',
  TR000: 'Unhandled Trino error.',
  ES101: 'DataSource connection secret already exists.',
  D113: 'Data Product already exists.',
  ES308: 'Data product builder invalid.',
  ES306: 'Data product missing builder.',
  ES310: 'Data product entity_info missing.',
  ES300: 'Data product missing schema.',
  D105: 'Data Product not found.',
  ES309: 'Data product has been published.',
  ES311: 'The name cannot be changed because the Data product table is already defined.',
  ES301: 'Spark job already assigned.',
  ES302: 'Spark job not assigned.',
  ES304: 'Spark job not scheduled.',
  ES303: 'Spark job already scheduled.',
  ES305: 'Invalid streaming product action.',
  ES307: 'Data product using builder.',
  ES103: 'DataSource connection required.',
  D111: 'Data Source already exists.',
  ES102: 'DataSource entity_info missing.',
  D103: 'Data Source not found.',
  D110: 'Data System already exists.',
  ES000: 'DataSystem entity_info missing.',
  D102: 'Data System not found.',
  D112: 'Data Unit already exists.',
  ES202: 'DataUnit missing configuration.',
  ES200: 'DataUnit entity_info missing.',
  ES201: 'DataUnit missing schema.',
  D104: 'Data Unit not found.',
  ES203: 'DataUnit schema update error.',
  D107: 'Element already exists.',
  D100: 'Element not found.',
  D116: 'Empty secret not allowed.',
  L000: 'Entity already linked to a parent.',
  L001: 'Entity can not be deleted with linked children.',
  D101: 'Entity not found.',
  D118: 'Field not found.',
  DQ100: 'Invalid data quality expectation settings.',
  SP101: 'Invalid spark configuration.',
  SP100: 'Invalid spark file.',
  D117: 'Journal note already exists.',
  ES100: 'DataSource connection required to add secret.',
  D114: 'Output already exists.',
  ES400: 'Output entity_info missing.',
  D106: 'Output not found.',
  L002: 'Entity can not be linked to itself.',
  L003: 'System Entity can not be removed from its parent.',
  SE001: 'Secret connected.',
  D108: 'Secret already exists.',
  D109: 'Spark already exists.',
  D115: 'System element is immutable.',
  T002: 'System tag is immutable.',
  T001: 'Tag in use.',
  WH001: 'Webhook error.',
  WH002: 'Webhook wrong data format.',
  A001: 'Authorization token required.',
  A103: 'Problem with Identity Access Manager.',
  A100: 'Insufficient permissions.',
  A002: 'Authorization token invalid.',
  A102: 'Resource has invalid format.',
  C001: 'Encountered errors while attempting to connect to or process requests for the service.',
  S001: 'Signature invalid.',
  AK001: 'Access key id already exists.',
  AK002: 'Access key id not found.',
  R118: 'Subscription to data product is already in given status.',
  R100: 'Core already registered.',
  R112: 'Core already subscribed to a data product.',
  R117: 'Core still has data product(s).',
  R114: 'Core host is not defined.',
  R101: 'Core not registered.',
  R113: 'Core not subscribed to a data product.',
  R110: 'Data Product already registered.',
  R115: 'Data product has active subscriptions.',
  R111: 'Data Product not registered.',
  I001: 'Duplicate entry',
  A004: 'Authorization failed.',
  I007: 'Could not call gateway callback.',
  I004: 'Group not found.',
  E002: 'Missing header.',
  I003: 'Not enough permissions.',
  I002: 'Policy not found.',
  I005: 'Principal not found.',
  AK003: 'Maximum number of secret keys reached.',
  I000: 'System entry',
  R000: 'Unhandled registry postgres error occurred.',
  A201: 'Problem with User Manager Service.',
  A202: 'Could not get users from User Manager Service.',
  A203: 'Missed fields in the user description.',
  I006: 'User not found.',
  V000: 'Validation error',
};
