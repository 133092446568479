import { Button } from '@blueprintjs/core';
import propTypes from 'prop-types';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { useUserStore } from '../store/userStore';

import Icon from './Icon';
import { toast } from './Toaster/Toaster';

const DeepLinkEntityButton = ({ entity }) => {
  const { userAccount } = useUserStore();
  const { tab } = useParams();

  const [coreIdentifier, , entityType, entityIdentifier] = useMemo(() => {
    const metadataArray = entity.urn.split(':').slice(3);
    metadataArray[2] = metadataArray[2].replace(/_/g, '-').replace(/^output$/, 'application');

    return metadataArray;
  }, [entity.urn]);
  return (
    <Button
      minimal
      small
      intent="primary"
      onClick={() => {
        // must be without newlines, for deep link to work when shared as a link
        navigator.clipboard.writeText(
          `${window.location.origin}/${entityType}?account_identifier=${
            userAccount.identifier
          }&core_identifier=${coreIdentifier}&entity_identifier=${entityIdentifier}&entity_type=${entityType}${
            tab ? `&tab=${tab}` : ''
          }&deep_link=true`,
        );
        toast.success('Copied deep link to clipboard');
      }}
    >
      <Icon name="link" size="sm" />
    </Button>
  );
};

DeepLinkEntityButton.propTypes = {
  entity: propTypes.object.isRequired,
};

export default DeepLinkEntityButton;
