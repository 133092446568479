import React from 'react';
import PropTypes from 'prop-types';

import { getNodeDataStyles } from '../features/DataLandscapeFeature/constants';

import Box from './Box';
import Text from './Text';

const SIZE_CHART = {
  data_system: {
    s: {
      width: '30px',
      height: '15px',
      border: '0.9px',
      text: '8px',
    },
    m: {
      width: '44px',
      height: '28px',
      border: '2px',
      text: '12px',
    },
    l: {
      width: '60px',
      height: '48px',
      border: '3px',
      text: '18px',
    },
  },
  data_source: {
    s: {
      width: '22px',
      height: '22px',
      border: '0.9px',
      text: '8px',
    },
    m: {
      width: '40px',
      height: '40px',
      border: '2px',
      text: '14px',
    },
    l: {
      width: '60px',
      height: '60px',
      border: '3px',
      text: '16px',
    },
  },
  data_unit: {
    s: {
      width: '36px',
      height: '18px',
      border: '1px',
      text: '8px',
    },
    m: {
      width: '44px',
      height: '28px',
      border: '2px',
      text: '12px',
    },
  },
  data_product: {
    s: {
      width: 32,
      height: 30,
      border: '1.5',
      text: '8px',
      transform: 'rotate(150 15 15) translate(-1)',
      points:
        '27.99038105676658,22.5 15.000000000000002,30 2.0096189432334235,22.500000000000007 2.009618943233418,7.500000000000004 14.999999999999996,0 27.99038105676658,7.500000000000005',
    },
    m: {
      width: 50,
      height: 50,
      border: '3',
      text: '14px',
      transform: 'rotate(150 25 25) translate(-2)',
      points:
        '46.65063509461097,37.5 25,50 3.349364905389038,37.50000000000001 3.349364905389031,12.500000000000007 24.999999999999996,0 46.65063509461097,12.500000000000009',
    },
  },
  application: {
    s: {
      width: '20px',
      height: '20px',
      border: '2px',
      text: '8px',
    },
    m: {
      width: '40px',
      height: '40px',
      border: '2px',
      text: '12px',
    },
  },
};

const EntityShape = ({ type, data, size }) => {
  const { color, fontColor } = getNodeDataStyles(data);
  // Remove <b></b> tags from label
  const label = data?.label?.replace(/<b>/g, '').replace(/<\/b>/g, '');
  // NOTE: due to dashboards, we need to check if sizeMetric exists
  const sizeMetric = SIZE_CHART[type] ? SIZE_CHART[type][size] : {};

  if (type === 'data_product') {
    return (
      <Box
        position="relative"
        width={`${sizeMetric.width}px`}
        height={`${sizeMetric.height}px`}
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexShrink="0"
      >
        <svg
          height={sizeMetric.height}
          width={sizeMetric.width + (size === 's' ? 0 : 4)}
          style={{ position: 'absolute' }}
        >
          <polygon
            transform={sizeMetric.transform}
            fill={color.background}
            stroke={color.border}
            strokeWidth={sizeMetric.border}
            points={sizeMetric.points}
          />
        </svg>
        <Text
          color={fontColor}
          fontWeight="bold"
          disableGutter
          style={{ fontSize: sizeMetric.text, zIndex: 1, userSelect: 'none' }}
        >
          {label || '---'}
        </Text>
      </Box>
    );
  }

  if (type === 'data_source') {
    return (
      <Box
        position="relative"
        width={sizeMetric.width}
        height={sizeMetric.height}
        borderRadius="50%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        backgroundColor={color.background}
        border={`${sizeMetric.border} solid ${color.border}`}
        flexShrink="0"
      >
        <Text
          color={fontColor}
          disableGutter
          fontWeight="bold"
          style={{ fontSize: sizeMetric.text, zIndex: 0, userSelect: 'none' }}
        >
          {label || '---'}
        </Text>
      </Box>
    );
  }

  if (type === 'data_system') {
    return (
      <Box
        width={sizeMetric.width}
        height={sizeMetric.height}
        borderRadius="3px"
        display="flex"
        alignItems="center"
        justifyContent="center"
        backgroundColor={color.background}
        border={`${sizeMetric.border} solid ${color.border}`}
        flexShrink="0"
      >
        <Text
          color={fontColor}
          disableGutter
          fontWeight="bold"
          style={{ fontSize: sizeMetric.text, userSelect: 'none' }}
        >
          {label || '---'}
        </Text>
      </Box>
    );
  }

  if (type === 'data_unit') {
    return (
      <Box
        width={sizeMetric.width}
        height={sizeMetric.height}
        borderRadius="14px"
        display="flex"
        alignItems="center"
        justifyContent="center"
        backgroundColor={color.background}
        border={`${sizeMetric.border} solid ${color.border}`}
        flexShrink="0"
      >
        <Text
          color={fontColor}
          disableGutter
          fontWeight="bold"
          style={{ fontSize: sizeMetric.text, userSelect: 'none' }}
        >
          {label || '---'}
        </Text>
      </Box>
    );
  }

  if (type === 'application') {
    return (
      <Box
        width={sizeMetric.width}
        height={sizeMetric.height}
        borderRadius="2px"
        display="flex"
        alignItems="center"
        justifyContent="center"
        backgroundColor={color.background}
        border={`${sizeMetric.border} solid ${color.border}`}
        flexShrink="0"
        style={{
          transform: 'rotate(45deg)',
        }}
        m={0.4}
      >
        <Text
          color={fontColor}
          disableGutter
          fontWeight="bold"
          style={{ fontSize: sizeMetric.text, transform: 'rotate(-45deg)', userSelect: 'none' }}
        >
          {label || '---'}
        </Text>
      </Box>
    );
  }

  return label;
};

EntityShape.propTypes = {
  type: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  size: PropTypes.oneOf(['s', 'm', 'l']),
};

EntityShape.defaultProps = {
  size: 'm',
};

export default EntityShape;
