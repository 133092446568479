import { Route, Routes } from 'react-router-dom';

import SelectCore from '../../components/Topbar/components/SelectCore';
import { useTopbar } from '../../components/Topbar/hooks/useTopbar';

import DataSourceDetails from './pages/DataSourceDetails/DataSourceDetails';

const DataSourceFeature = () => {
  useTopbar(<SelectCore />);
  return (
    <Routes>
      <Route path="/details/:id/:tab?" element={<DataSourceDetails />} />
    </Routes>
  );
};

export default DataSourceFeature;
