import { useContext, useLayoutEffect } from 'react';

import { TopbarContext } from '../components/TopbarProvider';

export const useTopbar = (leftElement = <div />, rightElement = null) => {
  const context = useContext(TopbarContext);
  if (context === undefined) {
    throw new Error('useTopbar must be used within a TopbarProvider');
  }

  useLayoutEffect(() => {
    context.setLeftElement(leftElement);
    context.setRightElement(rightElement);
  }, [context, leftElement, rightElement]);

  return context;
};
