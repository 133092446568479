import { Divider, Tab, Tabs } from '@blueprintjs/core';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useFormik } from 'formik';

import './EntityAddTabs.scss';
import Box from '../Box';
import Stack from '../Stack';
import Text from '../Text';
import TextInput from '../form/TextInput';
import Select from '../form/Select';

import EntityAddNew from './components/EntityAddNew';
import EntityAddLink from './components/EntityAddLink';
import { ENTITIES_RELATION_METADATA_MAP, SEARCH_PLACEHOLDERS_MAP, ENTITIES_METADATA_MAP } from './constants';

const EntityAddTabs = ({
  sourceEntityId,
  sourceEntityType,
  targetLinkType,
  canLinkToMultipleParents,
  queriesToInvalidateOnAdd,
  onClose,
}) => {
  const [selectedTabId, setSelectedTabId] = useState('existing');
  const targetEntityTypes = ENTITIES_RELATION_METADATA_MAP[sourceEntityType][targetLinkType];

  const formik = useFormik({
    initialValues: {
      search: '',
      listEntityType: 'all',
    },
  });

  return (
    <Box width="100%" className="entity-add-tabs-wrapper">
      <Divider />
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Tabs
          id="tabs"
          className="entity-tabs"
          renderActiveTabPanelOnly
          selectedTabId={selectedTabId}
          onChange={(id) => {
            setSelectedTabId(id);
            formik.resetForm();
          }}
        >
          <Tab
            id="existing"
            title="Link existing"
            panel={
              <EntityAddLink
                sourceEntityType={sourceEntityType}
                sourceEntityId={sourceEntityId}
                targetLinkType={targetLinkType}
                canLinkToMultipleParents={canLinkToMultipleParents}
                queriesToInvalidateOnAdd={queriesToInvalidateOnAdd}
                onClose={onClose}
                filterValues={formik.values}
              />
            }
          />
          <Tab
            id="new-source"
            title="Link new"
            panel={
              <EntityAddNew
                sourceEntityId={sourceEntityId}
                sourceEntityType={sourceEntityType}
                targetLinkType={targetLinkType}
                queriesToInvalidateOnAdd={queriesToInvalidateOnAdd}
                onClose={onClose}
              />
            }
          />
          {selectedTabId === 'existing' && (
            <Stack direction="row" alignItems="center" gap={3} style={{ marginLeft: 'auto' }}>
              <TextInput
                id="search"
                leftIcon="magnifying-glass"
                formik={formik}
                placeholder={`Search ${
                  formik.values.listEntityType === 'all'
                    ? targetEntityTypes.map((entityType) => SEARCH_PLACEHOLDERS_MAP[entityType]).join(', ')
                    : SEARCH_PLACEHOLDERS_MAP[formik.values.listEntityType]
                }`}
                containerProps={{
                  width: '250px',
                  paddingBottom: 0,
                }}
              />
              {targetEntityTypes.length > 1 && (
                <Stack direction="row" alignItems="center" justifyContent="flex-end" gap={1}>
                  <Text disableGutter>Show</Text>
                  <Select
                    showEmptyOption={false}
                    id="listEntityType"
                    formik={formik}
                    items={[
                      {
                        label: 'All',
                        value: 'all',
                      },
                      ...targetEntityTypes.map((entityType) => ({
                        label: ENTITIES_METADATA_MAP[entityType].name,
                        value: entityType,
                      })),
                    ]}
                    leftIconName="sort"
                  />
                </Stack>
              )}
            </Stack>
          )}
        </Tabs>
      </Stack>
    </Box>
  );
};

EntityAddTabs.propTypes = {
  sourceEntityType: PropTypes.string.isRequired,
  sourceEntityId: PropTypes.string.isRequired,
  targetLinkType: PropTypes.oneOf(['child', 'parent']).isRequired,
  onClose: PropTypes.func.isRequired,
  canLinkToMultipleParents: PropTypes.bool,
  queriesToInvalidateOnAdd: PropTypes.array,
};

export default EntityAddTabs;
