import PropTypes from 'prop-types';

import Box from '../Box';

/*
 used to wrap form components that don't support right and left elements
*/
export const CustomInputGroup = ({ leftElement, rightElement, children }) => {
  return (
    <Box
      style={{
        position: 'relative',
      }}
    >
      {leftElement && (
        <span
          style={{
            position: 'absolute',
            left: '0',
            height: '100%',
            top: '0',
            display: 'flex',
            alignItems: 'center',
            paddingRight: 10,
          }}
        >
          {leftElement}
        </span>
      )}
      {children}

      {rightElement && (
        <span
          style={{
            position: 'absolute',
            right: '0',
            height: '100%',
            top: '0',
            display: 'flex',
            alignItems: 'center',
            paddingRight: 10,
          }}
        >
          {rightElement}
        </span>
      )}
    </Box>
  );
};

CustomInputGroup.propTypes = {
  leftElement: PropTypes.node,
  rightElement: PropTypes.node,
  children: PropTypes.node.isRequired,
};
