import { AnchorButton, Button, Divider, Popover, SpinnerSize } from '@blueprintjs/core';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useCallback, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import Box from '../../../../../../components/Box';
import Card2 from '../../../../../../components/Card2';
import DataSchemaTable from '../../../../../../components/DataSchemaTable/DataSchemaTable';
import DeleteConfirmationAlert from '../../../../../../components/DeleteConfirmationAlert';
import EntityAddTabs from '../../../../../../components/EntityAddTabs/EntityAddTabs';
import EntityShape from '../../../../../../components/EntityShape';
import EntityStatus from '../../../../../../components/EntityStatus';
import Icon from '../../../../../../components/Icon';
import Spinner from '../../../../../../components/Spinner';
import Stack from '../../../../../../components/Stack';
import Table2 from '../../../../../../components/Table2';
import Text from '../../../../../../components/Text';
import { toast } from '../../../../../../components/Toaster/Toaster';
import Tooltip from '../../../../../../components/Tooltip';
import VisNetwork from '../../../../../../components/VisNetwork';
import gatewayApi from '../../../../../../services/gatewayApi';
import queryClient from '../../../../../../services/queryClient';
import { useDataUnitStore } from '../../../../../../store/dataUnitStore';
import { getApiErrorMessage } from '../../../../../../utils/functions';
import { getNetworkWithEdgesDefaultOptions } from '../../../../../../utils/networkHelpers';
import { getNodeNetworkData } from '../../../../../DataLandscapeFeature/constants';

import DataUnitAddEditConfiguration from './components/DataUnitAddEditConfiguration';

const NETWORK_OPTIONS = getNetworkWithEdgesDefaultOptions('m');

const dataSourceHeaderColumns = [
  {
    name: 'Source',
    field: 'name',
  },
  {
    name: '',
    field: '',
  },
  {
    name: 'Owner organization',
    field: 'owner',
  },

  {
    name: 'Status',
    field: 'status',
  },
  {
    name: 'Actions',
    field: 'actions',
    basis: '150px',
  },
];

const dataProductHeaderColumns = [
  {
    name: 'Data Product',
    field: 'name',
  },
  {
    name: '',
    field: '',
  },
  {
    name: 'Owner organization',
    field: 'owner',
  },
  {
    name: 'Status',
    field: 'status',
  },
  {
    name: 'Actions',
    field: 'actions',
    basis: '150px',
  },
];
const dataUnitHeaderColumns = [
  {
    name: 'Data Unit (this data unit)',
    field: 'name',
  },
  {
    name: 'Type',
    field: 'type',
  },
  {
    name: 'Data',
    field: 'data',
  },
  {
    name: 'Owner organization',
    field: 'owner',
  },
  {
    name: 'Status',
    field: 'status',
  },
  {
    name: 'Actions',
    field: 'actions',
    basis: '150px',
  },
];

const DataUnitConnectionsTab = () => {
  const navigate = useNavigate();
  const { dataUnit } = useDataUnitStore();
  const [network, setNetwork] = useState({});
  const [isAddDataProduct, setIsAddDataProduct] = useState(false);
  const [isAddDataSource, setIsAddDataSource] = useState(false);
  const [isConnectDataUnit, setIsConnectDataUnit] = useState(false);

  const [dataSourceToUnlink, setDataSourceToUnlink] = useState(null);
  const [dataProductToUnlink, setDataProductToUnlink] = useState(null);
  const [isShowLandscapeView, setIsShowLandscapeView] = useState(localStorage.getItem('landscapeView') === 'true');

  const { mutateAsync: unlinkDataSource, isLoading: isUnlinkDataSourceLoading } = useMutation(
    async () => {
      await gatewayApi.delete(
        `/link/data_source/${dataSourceToUnlink?.identifier}/data_unit/${dataUnit?.entity?.identifier}`,
      );
      await queryClient.invalidateQueries(['entityLinks', dataUnit?.entity?.identifier]);
    },
    {
      onSuccess: () => {
        setDataSourceToUnlink(null);
      },
      onError: (err) => {
        toast.error(getApiErrorMessage(err?.response?.data));
      },
    },
  );

  const { mutateAsync: unlinkDataProduct, isLoading: isUnlinkDataProductLoading } = useMutation(
    async () => {
      await gatewayApi.delete(
        `/link/data_unit/${dataUnit?.entity?.identifier}/data_product/${dataProductToUnlink?.identifier}`,
      );
      await queryClient.invalidateQueries(['entityLinks', dataUnit?.entity?.identifier]);
    },
    {
      onSuccess: () => {
        setDataProductToUnlink(null);
      },
      onError: (err) => {
        toast.error(getApiErrorMessage(err?.response?.data));
      },
    },
  );

  const { data: dataUnitLinks, isLoading: isDataUnitLinksLoading } = useQuery(
    ['entityLinks', dataUnit?.entity?.identifier],
    async () => {
      const result = await gatewayApi.get(`/data_unit/${dataUnit.entity.identifier}/link`);
      return result.data;
    },
    {
      enabled: !!dataUnit?.entity?.identifier,
    },
  );

  const { data: dataUnitSchema, isLoading: isDataUnitSchemaLoading } = useQuery(
    ['schema', dataUnit?.entity?.identifier],
    async () => {
      const result = await gatewayApi.get(`/data_unit/${dataUnit.entity.identifier}/schema`);
      return result.data;
    },
    {
      enabled: !!dataUnit?.entity?.identifier,
    },
  );

  const { data: dataSourceData } = useQuery(
    ['dataSource', dataUnitLinks?.parents?.[0]?.identifier],
    async () => {
      const result = await gatewayApi.get(`/data_source/${dataUnitLinks?.parents?.[0]?.identifier}`);
      return result.data;
    },
    {
      enabled: !!dataUnitLinks?.parents?.[0]?.identifier,
    },
  );

  const networkData = useMemo(() => {
    if (dataUnit && dataUnitLinks) {
      return getNodeNetworkData(
        { ...dataUnit.entity, ...dataUnit.entity_info, entity_type: 'data_unit' },
        dataUnitLinks,
      );
    }
    return { nodes: [], edges: [] };
  }, [dataUnit, dataUnitLinks]);

  const handleNodeClick = useCallback(
    (event) => {
      const clickedNodeId = event.nodes[0];
      const clickedNode = networkData.nodes.find((node) => node.identifier === clickedNodeId);
      if (clickedNode) {
        const nodeType = clickedNode.output_type || clickedNode.entity_type;
        const nodeTypeSlug = nodeType.replaceAll('_', '-');
        navigate(`/${nodeTypeSlug}/details/${clickedNodeId}`);
      }
    },
    [navigate, networkData?.nodes],
  );

  const disableDataUnitInfo = useMemo(() => {
    if (
      dataUnit?.entity?.is_system ||
      !dataUnitLinks?.parents?.length ||
      !dataSourceData?.connection?.connection_type
    ) {
      let reason = '';
      if (dataUnit?.entity?.is_system) {
        reason = "Can't edit system created entity.";
      } else if (!dataUnitLinks?.parents?.length) {
        reason = 'Connect to a Data Source before configuring the Data Unit.';
      } else if (!dataSourceData?.connection?.connection_type) {
        reason = 'Data Source is missing connection configuration.';
      }
      return {
        disabled: true,
        reason,
      };
    }

    return {
      disabled: false,
      reason: '',
    };
  }, [dataSourceData?.connection?.connection_type, dataUnit?.entity?.is_system, dataUnitLinks?.parents?.length]);

  const toggleLandscapeView = useCallback(() => {
    const newLandScapeView = !isShowLandscapeView;
    setIsShowLandscapeView(newLandScapeView);
    localStorage.setItem('landscapeView', newLandScapeView ? 'true' : 'false');
  }, [isShowLandscapeView]);

  return (
    <Box marginBottom="64px">
      <Card2
        title="Connected entities"
        bodyStyle={{ padding: 0 }}
        headerActions={
          <Button icon={<Icon name={isShowLandscapeView ? 'eye-slash' : 'eye'} />} onClick={toggleLandscapeView}>
            {isShowLandscapeView ? 'Hide landscape view' : 'Show landscape view'}
          </Button>
        }
      >
        {isShowLandscapeView && (
          <Box width="100%" style={{ padding: '1px' }}>
            <VisNetwork
              data={{ nodes: networkData.nodes, edges: networkData.edges }}
              options={NETWORK_OPTIONS}
              style={{ height: '400px', width: '100%' }}
              isLoading={isDataUnitLinksLoading}
              getNetwork={setNetwork}
              events={{
                selectNode: handleNodeClick,
                hoverNode: () => {
                  network.canvas.body.container.style.cursor = 'pointer';
                },
                blurNode: () => {
                  network.canvas.body.container.style.cursor = 'default';
                },
              }}
            />
          </Box>
        )}

        <Table2
          records={dataUnitLinks?.parents?.map((dataSource) => ({
            name: (
              <Stack direction="row" alignItems="center" gap={1}>
                <EntityShape size="s" type="data_source" data={dataSource} />
                <Link to={`/data-source/details/${dataSource.identifier}`}>{dataSource.name}</Link>
              </Stack>
            ),
            owner: <Text disableGutter>{dataSource.owner || '-'}</Text>,
            status: (
              <EntityStatus entityType="data_source" entityId={dataSource.identifier} entityState={dataSource.state} />
            ),
            actions: (
              <Button
                outlined
                intent="primary"
                onClick={() => {
                  setDataSourceToUnlink(dataSource);
                }}
              >
                Remove link
              </Button>
            ),
          }))}
          headerColumns={dataSourceHeaderColumns}
          showEmptyTable
          showRowsDivider
        />

        {!dataUnitLinks?.parents?.length && (
          <Button
            icon={<Icon name="square-plus" />}
            intent="primary"
            minimal
            onClick={() => setIsAddDataSource(true)}
            style={{ margin: '8px' }}
            disabled={isAddDataSource}
          >
            Add data source
          </Button>
        )}

        {isAddDataSource && (
          <EntityAddTabs
            sourceEntityId={dataUnit?.entity?.identifier}
            sourceEntityType="data_unit"
            targetLinkType="parent"
            onClose={() => setIsAddDataSource(false)}
          />
        )}

        <Table2
          recordsMetadata={[
            {
              data: {
                type: 'collapse',
                expandedProps: {
                  children: 'Hide schema',
                },
                collapsedProps: {
                  children: 'View schema',
                },
                value: isDataUnitSchemaLoading ? (
                  <Stack direction="row" alignItems="center" justifyContent="center" height="50px" gap={1}>
                    <Text disableGutter>Loading schema</Text>
                    <Spinner size={SpinnerSize.SMALL} />
                  </Stack>
                ) : (
                  <DataSchemaTable data={dataUnitSchema?.fields} isEditable={false} isCompact />
                ),
              },
            },
          ]}
          records={[
            {
              name: (
                <Stack direction="row" alignItems="center" gap={1}>
                  <EntityShape size="s" type="data_unit" data={{ ...dataUnit?.entity, ...dataUnit?.entity_info }} />
                  <Text disableGutter>{dataUnit?.entity?.name}</Text>
                </Stack>
              ),
              owner: <Text disableGutter>{dataUnit?.entity?.owner || '-'}</Text>,
              type: dataUnit?.configuration?.data_unit_type,
              data: (
                <Button
                  style={{ whiteSpace: 'nowrap' }}
                  icon={<Icon name="rectangle-list" />}
                  minimal
                  disabled={!dataUnit?.entity?.state?.healthy}
                />
              ),
              status: (
                <EntityStatus
                  entityType="data_unit"
                  entityId={dataUnit?.entity?.identifier}
                  entityState={dataUnit?.entity?.state}
                />
              ),
              actions: (
                <Tooltip content={disableDataUnitInfo.reason} condition={disableDataUnitInfo.disabled}>
                  <Popover
                    isOpen={isConnectDataUnit}
                    content={
                      <DataUnitAddEditConfiguration
                        onClose={() => setIsConnectDataUnit(false)}
                        dataSourceConnectionType={dataSourceData?.connection?.connection_type}
                        dataUnitConfigurationType={dataUnit?.configuration?.data_unit_type}
                      />
                    }
                  >
                    <AnchorButton
                      outlined
                      intent="primary"
                      onClick={() => setIsConnectDataUnit(true)}
                      disabled={disableDataUnitInfo.disabled}
                    >
                      {dataUnit?.configuration ? 'Edit connection' : 'Connect'}
                    </AnchorButton>
                  </Popover>
                </Tooltip>
              ),
            },
          ]}
          headerColumns={dataUnitHeaderColumns}
          showBorderTop
        />

        <Table2
          records={dataUnitLinks?.children?.map((dataProduct) => ({
            name: (
              <Stack direction="row" alignItems="center" gap={1}>
                <EntityShape size="s" type="data_product" data={dataProduct} />
                <Link to={`/data-product/details/${dataProduct.identifier}`}>{dataProduct.name}</Link>
              </Stack>
            ),
            owner: <Text disableGutter>{dataProduct.owner || '-'}</Text>,
            status: (
              <EntityStatus
                entityType="data_product"
                entityId={dataProduct.identifier}
                entityState={dataProduct.state}
                minimal
              />
            ),
            actions: (
              <Button
                outlined
                intent="primary"
                onClick={() => {
                  setDataProductToUnlink(dataProduct);
                }}
              >
                Remove link
              </Button>
            ),
          }))}
          headerColumns={dataProductHeaderColumns}
          showEmptyTable
          showBorderTop
          showRowsDivider
        />

        {dataUnitLinks?.children?.length > 0 && <Divider />}

        <Button
          icon={<Icon name="square-plus" />}
          intent="primary"
          minimal
          onClick={() => setIsAddDataProduct(true)}
          style={{ margin: '8px' }}
          disabled={isAddDataProduct}
        >
          Add data product
        </Button>

        {isAddDataProduct && (
          <EntityAddTabs
            sourceEntityId={dataUnit?.entity?.identifier}
            sourceEntityType="data_unit"
            targetLinkType="child"
            onClose={() => setIsAddDataProduct(false)}
          />
        )}
      </Card2>

      <DeleteConfirmationAlert
        isOpen={!!dataProductToUnlink}
        heading="Are you sure you want to remove linking?"
        description={
          <Text disableGutter>
            Removing the linking between entities may break the data flow.
            <br />
            It does not remove the entity.
          </Text>
        }
        onConfirm={unlinkDataProduct}
        onCancel={() => setDataProductToUnlink(null)}
        isLoading={isUnlinkDataProductLoading}
      />

      <DeleteConfirmationAlert
        isOpen={!!dataSourceToUnlink}
        heading="Are you sure you want to remove linking?"
        description={
          <Text disableGutter>
            Removing the linking between entities may break the data flow.
            <br />
            It does not remove the entity.
          </Text>
        }
        onConfirm={unlinkDataSource}
        onCancel={() => setDataSourceToUnlink(null)}
        isLoading={isUnlinkDataSourceLoading}
      />
    </Box>
  );
};

export default DataUnitConnectionsTab;
