import { Button, Colors, MenuItem, Tag } from '@blueprintjs/core';
import { Suggest } from '@blueprintjs/select';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { useCallback, useMemo, useState } from 'react';

import gatewayApi from '../../services/gatewayApi';
import queryClient from '../../services/queryClient';
import { useDataProductStore } from '../../store/dataProductStore';
import { capitalizeFirstLetter, formatSnakeCaseToWords, getApiErrorMessage } from '../../utils/functions';
import yup from '../../utils/validator';
import { EllipseText } from '../EllipseText';
import Icon from '../Icon';
import Stack from '../Stack';
import Table2 from '../Table2';
import Text from '../Text';
import { toast } from '../Toaster/Toaster';
import TextInput from '../form/TextInput';

const DATA_SCHEMA_HEADERS = [
  {
    name: 'Field',
    field: 'field',
  },
  {
    name: 'Description',
    field: 'description',
  },
  {
    name: 'PII',
    field: 'PII',
    basis: '100px',
  },
  {
    name: 'Classifications',
    field: 'classifications',
    basis: '300px',
  },
];

const DataSchemaDescriptionField = ({ field, isEditable, updateDescription, isUpdateDescriptionLoading }) => {
  const [isEditMode, setIsEditMode] = useState(false);

  const saveDescription = useCallback(
    async (event, formik) => {
      await formik.setFieldTouched('description', true);
      const { description: descriptionError } = await formik.validateForm();
      if (descriptionError) return;
      return updateDescription(event.target.value).then(() => setIsEditMode(false));
    },
    [updateDescription],
  );

  return (
    <Formik
      initialValues={{
        description: field || '',
      }}
      validationSchema={yup.object({
        description: yup.string().max(1500),
      })}
    >
      {(formik) =>
        // eslint-disable-next-line no-nested-ternary
        isEditable ? (
          // eslint-disable-next-line no-nested-ternary
          isEditMode ? (
            <TextInput
              id="description"
              formik={formik}
              disabled={isUpdateDescriptionLoading}
              autoFocus
              onBlur={(e) => saveDescription(e, formik)}
              onKeyDown={(e) => {
                if (e.code === 'Enter') {
                  saveDescription(e, formik);
                } else if (e.code === 'Escape') {
                  setIsEditMode(false);
                  formik.setFieldValue('description', field || '');
                }
              }}
              clearable={false}
            />
          ) : !formik.values.description ? (
            <Button icon={<Icon name="pen-to-square" />} onClick={() => setIsEditMode(true)} minimal>
              Edit description
            </Button>
          ) : (
            <Stack direction="row" alignItems="center" gap={1}>
              <EllipseText value={formik.values.description} limitType="lines" limit={1} />

              <Button icon={<Icon name="pen-to-square" />} onClick={() => setIsEditMode(true)} minimal />
            </Stack>
          )
        ) : (
          <Text disableGutter style={{ flexBasis: '90%', display: formik.values.description ? 'block' : 'none' }}>
            {formik.values.description}
          </Text>
        )
      }
    </Formik>
  );
};

DataSchemaDescriptionField.propTypes = {
  field: PropTypes.string,
  isEditable: PropTypes.bool.isRequired,
  updateDescription: PropTypes.func.isRequired,
  isUpdateDescriptionLoading: PropTypes.bool.isRequired,
};

DataSchemaDescriptionField.defaultProps = {
  field: '',
};

const DataSchemaPiiField = ({ isPii, isEditable, togglePii, isTogglePiiLoading }) => {
  return isPii ? (
    <Tag onRemove={!isEditable || isTogglePiiLoading ? null : togglePii}>PII</Tag>
  ) : (
    <Button
      style={{ display: isEditable ? 'block' : 'none', padding: 0 }}
      onClick={togglePii}
      disabled={!isEditable || isTogglePiiLoading}
      minimal
      intent="primary"
    >
      Mark as PII
    </Button>
  );
};

DataSchemaPiiField.propTypes = {
  isPii: PropTypes.bool.isRequired,
  isEditable: PropTypes.bool.isRequired,
  togglePii: PropTypes.func.isRequired,
  isTogglePiiLoading: PropTypes.bool.isRequired,
};

const SKIPPED_CLASSIFICATIONS_TAGS = ['pii'];

const DataSchemaClassificationsField = ({
  tags,
  fieldTags,
  isEditable,
  addTag,
  deleteTag,
  isAddTagLoading,
  isFieldTagsLoading,
}) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [tagQuery, setTagQuery] = useState('');

  const filteredTags = useMemo(() => tags?.filter((t) => !SKIPPED_CLASSIFICATIONS_TAGS.includes(t)), [tags]);
  const filteredFieldTags = useMemo(
    () =>
      fieldTags?.filter((t) => {
        return !tags?.includes(t.tag) && !SKIPPED_CLASSIFICATIONS_TAGS.includes(t.tag);
      }),
    [fieldTags, tags],
  );

  const createNewItemRenderer = useCallback(
    (query, active, handleClick) => (
      <MenuItem
        icon="add"
        text={`Add "${query}"`}
        active={active}
        onClick={handleClick}
        shouldDismissPopover={false}
        roleStructure="listoption"
      />
    ),
    [],
  );

  const renderMenuItem = useCallback(
    (tag, { handleClick, handleFocus, modifiers, ref }) => (
      <MenuItem
        text={formatSnakeCaseToWords(tag.tag)}
        active={modifiers.active}
        key={tag.tag}
        disabled={modifiers.disabled}
        onClick={handleClick}
        onFocus={handleFocus}
        ref={ref}
        roleStructure="listoption"
      />
    ),
    [],
  );
  return (
    <Stack direction="row" alignItems="center" gap={1} style={{ flexWrap: 'wrap' }}>
      {filteredTags?.map((tag, index) => (
        <Tag
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          onRemove={isEditable && (() => deleteTag(tag))}
        >
          {formatSnakeCaseToWords(tag)}
        </Tag>
      ))}
      {isEditMode ? (
        <Suggest
          popoverProps={{ minimal: true, onClosing: () => setIsEditMode(false) }}
          items={filteredFieldTags}
          inputProps={{
            autoFocus: true,
          }}
          inputValueRenderer={(tag) => tag.tag}
          itemRenderer={renderMenuItem}
          onQueryChange={(query) => setTagQuery(query.tag)}
          query={tagQuery}
          itemListPredicate={(query, items) => {
            return items.filter((item) => item.tag.toLowerCase().includes(query.toLowerCase()));
          }}
          onItemSelect={(item) => {
            const tag = (item.tag || item).toLowerCase().replace(/\s/g, '_');
            addTag(tag);
            setIsEditMode(false);
          }}
          createNewItemFromQuery={(query) => query}
          createNewItemRenderer={createNewItemRenderer}
          noResults={<MenuItem text="No results." roleStructure="listoption" />}
        />
      ) : (
        <Button
          style={{ display: isEditable ? 'block' : 'none', padding: 0 }}
          onClick={() => setIsEditMode(true)}
          disabled={!isEditable || isAddTagLoading || isFieldTagsLoading}
          minimal
          intent="primary"
        >
          Add a tag
        </Button>
      )}
    </Stack>
  );
};

DataSchemaClassificationsField.propTypes = {
  fieldTags: PropTypes.array,
  tags: PropTypes.array.isRequired,
  isEditable: PropTypes.bool.isRequired,
  addTag: PropTypes.func.isRequired,
  deleteTag: PropTypes.func.isRequired,
  isAddTagLoading: PropTypes.bool.isRequired,
  isFieldTagsLoading: PropTypes.bool.isRequired,
};

DataSchemaClassificationsField.defaultProps = {
  fieldTags: [],
};

const DataSchemaTable = ({ data: propsData, isEditable, isCompact, maxFullHeight }) => {
  const { setDataProductData, dataProduct } = useDataProductStore();

  const { data: fieldTags, isLoading: isFieldTagsLoading } = useQuery(['fieldTags'], async () => {
    // TODO:: should be replaced with a single call to /tag?scope=FIELD once the API is ready
    const [systemTagsRes, nonSystemRes] = await Promise.all([
      gatewayApi.get('/tag?scope=FIELD&system_defined=true'),
      gatewayApi.get('/tag?scope=FIELD&system_defined=false'),
    ]);

    return [...systemTagsRes.data.tags, ...nonSystemRes.data.tags];
  });

  const { mutateAsync: updateDescription, isLoading: isUpdateDescriptionLoading } = useMutation(
    async ({ field, val }) => {
      const prevFields = [...dataProduct.fields];
      const changedField = prevFields.findIndex((f) => f.name === field.name);
      prevFields[changedField] = {
        ...prevFields[changedField],
        description: val,
      };
      await gatewayApi.put(`/data_product/${dataProduct?.entity?.identifier}/metadata`, {
        fields: {
          [prevFields[changedField].name]: {
            tags: [],
            description: val,
          },
        },
        tags: [],
      });
      return prevFields;
    },
    {
      onSuccess: (prevFields) => {
        setDataProductData({
          dataProduct: {
            ...dataProduct,
            fields: prevFields,
          },
        });
      },
      onError: (err) => {
        toast.error(getApiErrorMessage(err?.response?.data));
      },
    },
  );

  const { mutateAsync: togglePii, isLoading: isTogglePiiLoading } = useMutation(
    async (field) => {
      const toggledVal = !field.tags?.includes('pii');
      const changedField = dataProduct.fields.find((fld) => fld.name === field.name);

      if (toggledVal) {
        await gatewayApi.put(`/data_product/${dataProduct?.entity?.identifier}/metadata`, {
          fields: {
            [changedField.name]: {
              tags: ['pii'],
              description: changedField.description || '',
            },
          },
          tags: [],
        });
      } else {
        await gatewayApi.delete(`/data_product/${dataProduct?.entity?.identifier}/metadata`, {
          data: {
            fields: {
              [changedField.name]: {
                tags: ['pii'],
                description: changedField.description || '',
              },
            },
            tags: [],
          },
        });
      }

      return { toggledVal, field };
    },
    {
      onSuccess: ({ toggledVal, field }) => {
        if (toggledVal === undefined) return;

        const tempFields = [...dataProduct.fields];
        const updatedIndex = tempFields.findIndex((fld) => fld.name === field.name);
        tempFields[updatedIndex] = {
          ...tempFields[updatedIndex],
          tags: !toggledVal
            ? tempFields[updatedIndex].tags.filter((tag) => tag !== 'pii')
            : [...(tempFields[updatedIndex].tags || []), 'pii'],
        };

        setDataProductData({
          dataProduct: {
            ...dataProduct,
            fields: tempFields,
          },
        });
      },
      onError: (err) => {
        toast.error(getApiErrorMessage(err?.response?.data));
      },
    },
  );

  const { mutateAsync: addTag, isLoading: isAddTagLoading } = useMutation(
    async ({ field, val }) => {
      const prevFields = [...dataProduct.fields];
      const changedField = prevFields.findIndex((f) => f.name === field.name);

      prevFields[changedField] = {
        ...prevFields[changedField],
        tags: [...prevFields[changedField].tags, val],
      };

      const tagExists = fieldTags.some((tag) => tag.tag === val);

      if (!tagExists) {
        await gatewayApi.post(`/tag`, {
          tag: val,
          scope: 'FIELD',
        });
        await queryClient.invalidateQueries(['fieldTags']);
      }
      await gatewayApi.put(`/data_product/${dataProduct?.entity?.identifier}/metadata`, {
        fields: {
          [prevFields[changedField].name]: {
            tags: [val],
          },
        },
        tags: [],
      });

      return prevFields;
    },
    {
      onSuccess: (prevFields) => {
        setDataProductData({
          dataProduct: {
            ...dataProduct,
            fields: prevFields,
          },
        });
      },
      onError: (err) => {
        toast.error(getApiErrorMessage(err?.response?.data));
      },
    },
  );

  const { mutateAsync: deleteTag } = useMutation(
    async ({ field, val }) => {
      const prevFields = [...dataProduct.fields];
      const changedField = prevFields.findIndex((f) => f.name === field.name);

      const filteredTags = prevFields[changedField].tags.filter((tag) => tag !== val);
      prevFields[changedField] = {
        ...prevFields[changedField],
        tags: [...filteredTags],
      };

      await gatewayApi.delete(`/data_product/${dataProduct?.entity?.identifier}/metadata`, {
        data: {
          fields: {
            [prevFields[changedField].name]: {
              tags: [val],
            },
          },
          tags: [],
        },
      });

      return prevFields;
    },
    {
      onSuccess: (prevFields) => {
        setDataProductData({
          dataProduct: {
            ...dataProduct,
            fields: prevFields,
          },
        });
      },
      onError: (err) => {
        toast.error(getApiErrorMessage(err?.response?.data));
      },
    },
  );

  const headers = isCompact ? DATA_SCHEMA_HEADERS.filter((header) => header.field !== 'PII') : DATA_SCHEMA_HEADERS;

  return (
    <Table2
      headerColumns={headers}
      showRowsDivider
      maxHeight={maxFullHeight ? 'calc(100vh - 320px)' : '100%'}
      records={propsData?.map((field) => {
        return {
          field: (
            <Stack direction="row" alignItems="center" flexBasis="33%" gap={1}>
              <Text disableGutter>{field.name}</Text>
              <Tag color={Colors.ORANGE5} style={{ fontWeight: 300 }}>
                {capitalizeFirstLetter(field.data_type.column_type)}
              </Tag>
            </Stack>
          ),
          description: (
            <DataSchemaDescriptionField
              field={field?.description}
              isEditable={isEditable}
              updateDescription={(val) =>
                updateDescription({
                  val,
                  field,
                })
              }
              isUpdateDescriptionLoading={isUpdateDescriptionLoading}
            />
          ),
          PII: (
            <DataSchemaPiiField
              isPii={field.tags?.includes('pii')}
              isEditable={isEditable}
              togglePii={() => togglePii(field)}
              isTogglePiiLoading={isTogglePiiLoading}
            />
          ),
          classifications: (
            <DataSchemaClassificationsField
              tags={field.tags}
              fieldTags={fieldTags}
              isEditable={isEditable}
              addTag={(val) =>
                addTag({
                  val,
                  field,
                })
              }
              deleteTag={(val) =>
                deleteTag({
                  val,
                  field,
                })
              }
              isSystemTagsLoading={isFieldTagsLoading}
              isFieldTagsLoading={isFieldTagsLoading}
              isAddTagLoading={isAddTagLoading}
            />
          ),
        };
      })}
      emptyMessage="Add Connected Data Inputs & transformations first to create a schema."
      emptyMessageStyles={{ textAlign: 'center', width: '100%', padding: '8px 0' }}
    />
  );
};

DataSchemaTable.propTypes = {
  data: PropTypes.array,
  isEditable: PropTypes.bool,
  isCompact: PropTypes.bool,
  maxFullHeight: PropTypes.bool,
};

DataSchemaTable.defaultProps = {
  data: [],
  isEditable: true,
  isCompact: false,
  maxFullHeight: false,
};

export default DataSchemaTable;
