import { Dialog as BlueprintDialog, DialogBody, DialogFooter } from '@blueprintjs/core';
import PropTypes from 'prop-types';

import Text from './Text';

const Dialog = ({
  actions,
  footerChildren,
  title,
  children,
  icon,
  autoFocus,
  canEscapeKeyClose,
  canOutsideClickClose,
  enforceFocus,
  isCloseButtonShown,
  onClose,
  isOpen,
  onOpened,
  onClosed,
  useOverflowScrollContainer,
  minimal,
  className,
  dialogProps,
  bodyProps,
  footerProps,
}) => {
  return (
    <BlueprintDialog
      className={className}
      autoFocus={autoFocus}
      title={title}
      icon={icon}
      canEscapeKeyClose={canEscapeKeyClose}
      canOutsideClickClose={canOutsideClickClose}
      enforceFocus={enforceFocus}
      isCloseButtonShown={isCloseButtonShown}
      isOpen={isOpen}
      onClose={onClose}
      onOpened={onOpened}
      onClosed={onClosed}
      {...dialogProps}
    >
      <DialogBody useOverflowScrollContainer={useOverflowScrollContainer} {...bodyProps}>
        {typeof children === 'string' ? <Text>{children}</Text> : children}
      </DialogBody>

      {(actions || footerChildren) && (
        <DialogFooter minimal={minimal} actions={actions} {...footerProps}>
          {footerChildren}
        </DialogFooter>
      )}
    </BlueprintDialog>
  );
};

Dialog.propTypes = {
  actions: PropTypes.node,
  title: PropTypes.string,
  children: PropTypes.node,
  icon: PropTypes.string,
  footerChildren: PropTypes.node,
  autoFocus: PropTypes.bool,
  canEscapeKeyClose: PropTypes.bool,
  canOutsideClickClose: PropTypes.bool,
  enforceFocus: PropTypes.bool,
  isCloseButtonShown: PropTypes.bool,
  onClose: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
  onOpened: PropTypes.func,
  onClosed: PropTypes.func,
  useOverflowScrollContainer: PropTypes.bool,
  dialogProps: PropTypes.object,
  bodyProps: PropTypes.object,
  footerProps: PropTypes.object,
  minimal: PropTypes.bool,
  className: PropTypes.string,
};

Dialog.defaultProps = {
  actions: null,
  title: '',
  children: null,
  icon: 'info-sign',
  footerChildren: null,
  autoFocus: true,
  canEscapeKeyClose: true,
  canOutsideClickClose: true,
  enforceFocus: true,
  isCloseButtonShown: true,
  useOverflowScrollContainer: true,
  dialogProps: {},
  bodyProps: {},
  footerProps: {},
  minimal: false,
  className: '',
  onOpened: () => {},
  onClosed: () => {},
};

export default Dialog;
