import { Label as BlueprintLabel, Position } from '@blueprintjs/core';
import PropTypes from 'prop-types';

import Tooltip from '../Tooltip';

const InputLabel = ({ id, label, labelStyle, tooltipContent, tooltipProps }) => {
  if (!label) {
    return null;
  }

  const Label = (
    <BlueprintLabel
      htmlFor={id}
      style={{
        marginBottom: '4px',
        textDecoration: tooltipContent ? 'underline dotted' : 'none',
        ...labelStyle,
      }}
    >
      {label}
    </BlueprintLabel>
  );

  return tooltipContent ? (
    <Tooltip placement={Position.TOP} content={tooltipContent} condition={!!tooltipContent} {...tooltipProps}>
      {Label}
    </Tooltip>
  ) : (
    Label
  );
};

InputLabel.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  labelStyle: PropTypes.object,
  tooltipContent: PropTypes.node,
  tooltipProps: PropTypes.object,
};

export default InputLabel;
