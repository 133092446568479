import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Divider } from '@blueprintjs/core';
import { useFormik } from 'formik';

import yup from '../utils/validator';

import Text from './Text';
import TextInput from './form/TextInput';
import Box from './Box';

import './DeleteConfirmationAlert.scss';

const DeleteConfirmationAlert = ({
  heading,
  description,
  confirmationText,
  confirmationInputLabel,
  cancelButtonText,
  confirmButtonText,
  intent,
  isOpen,
  isLoading,
  onConfirm,
  onCancel,
  ...rest
}) => {
  const formik = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema: yup.object({
      name: yup.string().required().equals([confirmationText], 'Invalid name. Please try again.'),
    }),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (_, { resetForm }) => {
      // Check if type of onConfirm func is async
      if (onConfirm.constructor.name === 'AsyncFunction') {
        await onConfirm();
        resetForm();
        return;
      }
      onConfirm();
      resetForm();
    },
  });

  return (
    <Alert
      cancelButtonText={cancelButtonText}
      confirmButtonText={confirmButtonText}
      intent={intent}
      isOpen={isOpen}
      loading={formik.isSubmitting || isLoading}
      onCancel={onCancel}
      onConfirm={confirmationText ? formik.handleSubmit : onConfirm}
      style={{ width: '500px', maxWidth: '500px', padding: 0 }}
      className="container"
      {...rest}
    >
      {heading && (
        <>
          <Box px={2} py={1}>
            <Text large disableGutter>
              {heading}
            </Text>
          </Box>
          <Divider />
        </>
      )}
      <Box px={2} py={1}>
        {typeof description === 'string' ? <Text disableGutter>{description}</Text> : description}
        {confirmationText && <TextInput id="name" label={confirmationInputLabel} formik={formik} />}
      </Box>
      <Divider />
    </Alert>
  );
};

DeleteConfirmationAlert.propTypes = {
  heading: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  cancelButtonText: PropTypes.string,
  confirmButtonText: PropTypes.string,
  confirmationInputLabel: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  intent: PropTypes.oneOf(['none', 'primary', 'success', 'warning', 'danger']),
  isOpen: PropTypes.bool,
  isLoading: PropTypes.bool,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  confirmationText: PropTypes.string,
};

DeleteConfirmationAlert.defaultProps = {
  heading: undefined,
  cancelButtonText: 'Cancel',
  confirmButtonText: 'Confirm',
  confirmationInputLabel: 'Name',
  intent: 'danger',
  isOpen: false,
  isLoading: false,
  onConfirm: () => {},
  onCancel: () => {},
  confirmationText: undefined,
};

export default DeleteConfirmationAlert;
