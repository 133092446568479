import { useEffect } from 'react';

import useFirstRender from './useFirstRender';

// Hook simmilar to useEffect, but it will not run on first render, only on updates
const useUpdateEffect = (effect, deps) => {
  const isFirstRender = useFirstRender();

  useEffect(() => {
    if (!isFirstRender) return effect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};

export default useUpdateEffect;
