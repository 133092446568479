import { useQuery, useQueryErrorResetBoundary } from '@tanstack/react-query';
import { useErrorHandler } from 'react-error-boundary';

import { ERROR_CODES } from '../constants';

export function useSecureQuery(key, queryFn, options = {}) {
  const handleError = useErrorHandler();

  const { reset } = useQueryErrorResetBoundary();

  const mergedOptions = {
    ...options,
    onError: (error) => {
      if (error === ERROR_CODES.INSUFFICIENT_PERMISSION) {
        handleError(error);
        return;
      }

      if (options.onError) {
        options.onError(error);
      }
    },
    onRetry: () => {
      reset();
    },
  };

  const result = useQuery(key, queryFn, mergedOptions);

  return result;
}
