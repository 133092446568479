import PropTypes from 'prop-types';
import { useEffect } from 'react';

import Stack from '../../../../../../../components/Stack';
import TextInput from '../../../../../../../components/form/TextInput';
import yup from '../../../../../../../utils/validator';

const DataSourceCoreConnection = ({ formik, setValidationSchema }) => {
  useEffect(() => {
    setValidationSchema((currentSchema) => {
      const { connection_type } = currentSchema;

      return {
        connection_type,
        host: yup.string().required(),
        partition: yup.string().required(),
        account: yup.string().required(),
        identifier: yup.string().required(),
        access_key_id: yup.string().required(),
        secret_access_key: yup.object({
          env_key: yup
            .string()
            .max(250, 'Max characters is 250.')
            .matches(
              /^[A-Z][A-Z0-9_]*$/,
              'Must start with a letter and contain only letters, numbers, and underscores.',
            )
            .required(),
        }),
      };
    });

    return () => {
      setValidationSchema((currentSchema) => {
        const { connection_type } = currentSchema;
        return { connection_type };
      });
    };
  }, [setValidationSchema]);

  return (
    <Stack direction="row" width="100%" gap={1} style={{ flexWrap: 'wrap' }}>
      <TextInput
        id="host"
        label="Core Name or ID"
        formik={formik}
        disabled
        containerProps={{
          style: {
            flexBasis: '30%',
          },
        }}
      />
      <TextInput
        id="partition"
        label="Partition"
        formik={formik}
        disabled
        containerProps={{
          style: {
            flexBasis: '30%',
          },
        }}
      />
      <TextInput
        id="account"
        label="Account"
        formik={formik}
        disabled
        containerProps={{
          style: {
            flexBasis: '30%',
          },
        }}
      />
      <TextInput
        id="identifier"
        label="Identifier"
        formik={formik}
        disabled
        containerProps={{
          style: {
            flexBasis: '30%',
          },
        }}
      />
      <TextInput
        id="access_key_id"
        label="Access key ID"
        formik={formik}
        disabled
        containerProps={{
          style: {
            flexBasis: '30%',
          },
        }}
      />
      <TextInput
        id="secret_access_key.env_key"
        label="Secret access key"
        formik={formik}
        disabled
        containerProps={{
          style: {
            flexBasis: '30%',
          },
        }}
      />
    </Stack>
  );
};

DataSourceCoreConnection.propTypes = {
  formik: PropTypes.object.isRequired,
  setValidationSchema: PropTypes.func.isRequired,
};

export default DataSourceCoreConnection;
