import { ENTITY_TYPE_TO_API_ENTITY_TYPE_MAP } from './constants';
/**
 * Constructs the url for the linking API based on source/target entity types
 * @param {string} sourceEntityId identifier (ID) of the source entity
 * @param {*} sourceEntityType type of entity (e.g. data_product)
 * @param {*} targetEntityId identifier (ID) of the target entity
 * @param {*} targetEntityType type of target entity (e.g. data_unit)
 * @param {*} targetLinkType type of link (e.g. parent)
 * @returns {string} constructed url
 */
export const getLinkingApiUrl = ({
  sourceEntityId,
  sourceEntityType,
  targetEntityId,
  targetEntityType,
  targetLinkType,
}) => {
  const url =
    targetLinkType === 'child'
      ? `${ENTITY_TYPE_TO_API_ENTITY_TYPE_MAP[sourceEntityType]}/${sourceEntityId}/${ENTITY_TYPE_TO_API_ENTITY_TYPE_MAP[targetEntityType]}/${targetEntityId}`
      : `${ENTITY_TYPE_TO_API_ENTITY_TYPE_MAP[targetEntityType]}/${targetEntityId}/${ENTITY_TYPE_TO_API_ENTITY_TYPE_MAP[sourceEntityType]}/${sourceEntityId}`;

  return `/link/${url}`;
};
