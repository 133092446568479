import { drawExternalLabel } from '../../utils/networkHelpers';

const LABEL_BOX_HEIGHT = 40;

export const ENTITY_NODE_COLORS = {
  subscribed: {
    background: '#BDADFF',
    border: '#BDADFF',
    fontColor: '#000000',
  },
  connected: {
    background: '#634DBF',
    border: '#634DBF',
    fontColor: '#FFFFFF',
  },
  unconnected: {
    background: '#FFFFFF',
    border: '#907CE2',
    fontColor: '#000000',
  },
};

export const getDataSystemNodeStyles = (entity) => ({
  shape: 'custom',
  chosen: false,
  borderWidth: 2,
  borderWidthSelected: 2,
  ctxRenderer: ({ ctx, x, y, style }) => {
    const fontSize = 16;
    const nodeWidth = 64;
    const nodeHeight = 28;

    return {
      drawNode() {
        ctx.beginPath();
        ctx.fillStyle = entity.color.background;
        ctx.strokeStyle = entity.color.border;
        ctx.lineWidth = style.borderWidth;
        ctx.roundRect(x - nodeWidth / 2, y - nodeHeight / 2, nodeWidth, nodeHeight, 3);
        ctx.stroke();
        ctx.fill();
        ctx.closePath();
        // Draw label
        ctx.beginPath();
        ctx.fillStyle = entity.fontColor;
        ctx.font = `bold ${fontSize}px Nunito`;
        ctx.textBaseline = 'top';
        ctx.textAlign = 'center';
        const textMeasure = ctx.measureText(entity.label);
        const textHeight = textMeasure.actualBoundingBoxAscent + textMeasure.actualBoundingBoxDescent;
        ctx.fillText(entity.label, x, y - textHeight / 2, nodeWidth);
        ctx.closePath();
      },
      drawExternalLabel: () => drawExternalLabel({ ctx, x, y, entity, nodeHeight: nodeHeight / 2 }),
      nodeDimensions: {
        width: nodeWidth + style.borderWidth * 2, // this will serve like a padding for more hover/grab area
        height: nodeHeight + style.borderWidth * 2 + LABEL_BOX_HEIGHT, // this will serve like a padding for more hover/grab area
      },
    };
  },
});

export const getDataSourceNodeStyles = (entity) => ({
  shape: 'custom',
  size: 32,
  chosen: false,
  borderWidth: 2,
  borderWidthSelected: 2,
  ctxRenderer: ({ ctx, x, y, style }) => {
    return {
      drawNode() {
        const nodeSize = style.size;
        const fontSize = 16;
        // Draw inned circle
        ctx.beginPath();
        ctx.arc(x, y, nodeSize, 0, 2 * Math.PI);
        ctx.fillStyle = entity.color.background;
        ctx.fill();
        ctx.closePath();
        // Draw border
        ctx.beginPath();
        ctx.arc(x, y, nodeSize, 0, 2 * Math.PI);
        ctx.strokeStyle = entity.color.border;
        ctx.lineWidth = style.borderWidth;
        ctx.stroke();
        ctx.closePath();
        // Draw label
        ctx.beginPath();
        ctx.fillStyle = entity.fontColor;
        ctx.font = `bold ${fontSize}px Nunito`;
        ctx.textBaseline = 'top';
        ctx.textAlign = 'center';
        const textMeasure = ctx.measureText(entity.label);
        const textHeight = textMeasure.actualBoundingBoxAscent + textMeasure.actualBoundingBoxDescent;
        ctx.fillText(entity.label, x, y - textHeight / 2, nodeSize);
        ctx.closePath();
      },
      drawExternalLabel: () => drawExternalLabel({ ctx, x, y, entity, nodeHeight: style.size }),
      nodeDimensions: {
        width: style.size + style.borderWidth * 2, // this will serve like a padding for more hover/grab area
        height: style.size + style.borderWidth * 2 + LABEL_BOX_HEIGHT, // this will serve like a padding for more hover/grab area
      },
    };
  },
});

export const getDataUnitNodeStyles = (entity) => ({
  shape: 'custom',
  chosen: false,
  borderWidth: 2,
  borderWidthSelected: 2,
  ctxRenderer: ({ ctx, x, y, style }) => {
    const fontSize = 14;
    const nodeWidth = 50;
    const nodeHeight = 30;

    return {
      drawNode() {
        ctx.beginPath();
        ctx.roundRect(x - nodeWidth / 2, y - nodeHeight / 2, nodeWidth, nodeHeight, 16);
        ctx.fillStyle = entity.color.background;
        ctx.fill();
        ctx.closePath();
        // Draw border
        ctx.beginPath();
        ctx.strokeStyle = entity.color.border;
        ctx.lineWidth = style.borderWidth;
        ctx.roundRect(x - nodeWidth / 2, y - nodeHeight / 2, nodeWidth, nodeHeight, 16);
        ctx.stroke();
        ctx.closePath();
        // Draw label
        ctx.beginPath();
        ctx.fillStyle = entity.fontColor;
        ctx.font = `bold ${fontSize}px Nunito`;
        ctx.textBaseline = 'top';
        ctx.textAlign = 'center';
        const textMeasure = ctx.measureText(entity.label);
        const textHeight = textMeasure.actualBoundingBoxAscent + textMeasure.actualBoundingBoxDescent;
        ctx.fillText(entity.label, x, y - textHeight / 2, nodeWidth);
        ctx.closePath();
      },
      drawExternalLabel: () => drawExternalLabel({ ctx, x, y, entity, nodeHeight: nodeHeight / 2 }),
      nodeDimensions: {
        width: nodeWidth + style.borderWidth * 2, // this will serve like a padding for more hover/grab area
        height: nodeHeight + style.borderWidth * 2 + LABEL_BOX_HEIGHT, // this will serve like a padding for more hover/grab area
      },
    };
  },
});

export const getApplicationNodeStyles = (entity) => ({
  shape: 'custom',
  size: 32,
  chosen: false,
  borderWidth: 2,
  borderWidthSelected: 2,
  ctxRenderer: ({ ctx, x, y, style }) => {
    const fontSize = 14;
    const nodeSize = style.size;

    return {
      drawNode() {
        ctx.beginPath();
        ctx.lineTo(x, y + nodeSize);
        ctx.lineTo(x + nodeSize, y);
        ctx.lineTo(x, y - nodeSize);
        ctx.lineTo(x - nodeSize, y);
        ctx.fillStyle = entity.color.background;
        ctx.fill();
        ctx.closePath();
        // Border
        ctx.beginPath();
        ctx.lineTo(x, y + nodeSize);
        ctx.lineTo(x + nodeSize, y);
        ctx.lineTo(x, y - nodeSize);
        ctx.lineTo(x - nodeSize, y);
        ctx.strokeStyle = entity.color.background;
        ctx.lineWidth = style.borderWidth;
        ctx.closePath();
        ctx.stroke();
        // Draw label
        ctx.beginPath();
        ctx.fillStyle = entity.fontColor;
        ctx.font = `bold ${fontSize}px Nunito`;
        ctx.textBaseline = 'top';
        ctx.textAlign = 'center';
        ctx.fillText(entity.label, x, y - (fontSize / 2 - 2), nodeSize);
        ctx.closePath();
      },
      drawExternalLabel: () => drawExternalLabel({ ctx, x, y, entity, nodeHeight: nodeSize }),
      nodeDimensions: {
        width: nodeSize + style.borderWidth * 2, // this will serve like a padding for more hover/grab area
        height: nodeSize + style.borderWidth * 2 + LABEL_BOX_HEIGHT, // this will serve like a padding for more hover/grab area
      },
    };
  },
});

export const getDataProductNodeStyles = (entity) => ({
  shape: 'custom',
  size: 32,
  chosen: false,
  borderWidth: 2,
  borderWidthSelected: 2,
  ctxRenderer: ({ ctx, x, y, style }) => {
    const fontSize = 14;
    const nodeSize = style.size;

    return {
      drawNode() {
        const sides = 6;
        const a = (Math.PI * 2) / sides;
        ctx.beginPath();
        ctx.moveTo(x + nodeSize, y);
        for (let i = 0; i < sides; i++) {
          ctx.lineTo(x + nodeSize * Math.cos(a * i), y + nodeSize * Math.sin(a * i));
        }
        ctx.fillStyle = entity.color.background;
        ctx.fill();
        ctx.closePath();
        // Border
        ctx.beginPath();
        for (let i = 0; i < sides; i++) {
          ctx.lineTo(x + nodeSize * Math.cos(a * i), y + nodeSize * Math.sin(a * i));
        }
        ctx.closePath();
        ctx.strokeStyle = entity.color.border;
        ctx.lineWidth = style.borderWidth;
        ctx.stroke();
        // Draw label
        ctx.beginPath();
        ctx.fillStyle = entity.fontColor;
        ctx.font = `bold ${fontSize}px Nunito`;
        ctx.textBaseline = 'top';
        ctx.textAlign = 'center';
        // Y correction of 1px due to hexagon shape
        ctx.fillText(entity.label, x, y - (fontSize / 2 - 1), nodeSize);
        ctx.closePath();
      },
      drawExternalLabel: () => drawExternalLabel({ ctx, x, y, entity, nodeHeight: nodeSize - 4 }),
      nodeDimensions: {
        width: nodeSize + style.borderWidth * 2, // this will serve like a padding for more hover/grab area
        height: nodeSize + style.borderWidth * 2 + LABEL_BOX_HEIGHT, // this will serve like a padding for more hover/grab area
      },
    };
  },
});

// TODO: Refactor this once data lineage graph is ready (for data lineage task)
export const SPARK_JOB_NODE_BASE_STYLE = {
  shape: 'square',
  size: 30,
  borderWidthSelected: 4,
  labelHighlightBold: false,
  chosen: false,
  color: {
    border: '#7f2b05',
    background: '#F55B14',
    hover: '#F55B14',
  },
  font: {
    multi: 'html',
    size: 16,
    vadjust: -47,
    face: 'Nunito',
    bold: {
      size: 16,
      face: 'Nunito',
    },
  },
};

export const TYPES_LABEL_MAP = {
  data_system: 'Data System',
  data_product: 'Data Product',
  data_unit: 'Data Unit',
  data_source: 'Data Source',
  application: 'Application',
  job: 'Spark job',
};

export const getNodeDataStyles = (node = {}) => {
  const isHealthy = node.state?.healthy || false;
  let colorKey = 'unconnected';

  if (isHealthy) {
    colorKey = node.subscribed ? 'subscribed' : 'connected';
  }

  return {
    borderWidth: isHealthy ? 0 : 3,
    borderWidthSelected: 3,
    color: {
      border: ENTITY_NODE_COLORS[colorKey].border,
      background: ENTITY_NODE_COLORS[colorKey].background,
      hover: ENTITY_NODE_COLORS[colorKey].border,
    },
    fontColor: ENTITY_NODE_COLORS[colorKey].fontColor,
  };
};

const nodeCommonFields = (entity) => {
  return {
    ...entity,
    ...getNodeDataStyles(entity),
    id: entity.identifier,
  };
};

export const getNodeData = (entity, entityType, outputType = null) => {
  // NOTE: Dashboard is not supported anymore. This is fix for old data untill we remove them from DB.
  if (outputType === 'dashboard') {
    return {};
  }

  const nodeData = {
    application: getApplicationNodeStyles,
    data_product: getDataProductNodeStyles,
    data_source: getDataSourceNodeStyles,
    data_system: getDataSystemNodeStyles,
    data_unit: getDataUnitNodeStyles,
  };
  const commonFields = nodeCommonFields(entity);
  const nodeStyles = nodeData[outputType || entityType](commonFields);

  return {
    ...commonFields,
    ...nodeStyles,
    entity_type: entityType,
  };
};

export const getNodeNetworkData = (node, links) => {
  return {
    nodes: [
      getNodeData(node, node.entity_type, node.output_type),
      ...links.parents.map((entity) => getNodeData(entity, entity.entity_type, entity.output_type)),
      ...links.children
        .filter((entity) => entity.output_type !== 'dashboard')
        .map((entity) => getNodeData(entity, entity.entity_type, entity.output_type)),
    ],
    edges: [
      ...links.parents.map((entity) => ({ from: entity.identifier, to: node.identifier })),
      ...links.children.map((entity) => ({
        from: node.identifier,
        to: entity.identifier,
      })),
    ],
  };
};

/**
 * @param {{child: Object, parent: Object}[]} links
 * @returns {{
 *  nodes: Array,
 *  edges: Array
 * }}
 */
export const getAllLinksNetworkData = (links, hiddenEntityType) => {
  const nodes = Object.values(
    links.reduce((acc, link) => {
      const { parent, child } = link;
      acc[parent.identifier] = getNodeData(parent, parent.entity_type, parent.output_type);
      acc[child.identifier] = getNodeData(child, child.entity_type, child.output_type);
      return acc;
    }, {}),
  )
    .filter((node) => node.entity_type !== hiddenEntityType)
    .filter((node) => node.output_type !== 'dashboard') // NOTE: Dashboard is not supported anymore. This is fix for old data untill we remove them from DB.
    .filter((node) => node.identifier); // Because there are still dashboards in the system, we need to filter by identifier in order not to have empty object here

  const edges = links.flatMap(({ parent, child }) => {
    if (child.entity_type === hiddenEntityType) {
      const parentNodeGrandChildren = links.filter((link) => link.parent.identifier === child.identifier);
      return parentNodeGrandChildren.map((grandChild) => ({
        from: parent.identifier,
        to: grandChild.child.identifier,
      }));
    }

    return {
      from: parent.identifier,
      to: child.identifier,
    };
  });

  return { nodes, edges };
};

export const assembleDataLandscapeNodesWithLevel = (nodes, edges) => {
  const childrenIds = edges.map((edge) => edge.to);
  const rootNodes = nodes.filter((node) => !childrenIds.includes(node.identifier));

  const results = {};

  const addLevelRecursive = (currentNodes, currentLevel) => {
    currentNodes.forEach((currentNode) => {
      const currentNodeChildrenIds = edges
        ?.filter((edge) => edge.from === currentNode?.identifier)
        .map((edge) => edge.to);

      const currentNodeChildrenNodes = currentNodeChildrenIds.map((id) =>
        nodes.find((node) => node?.identifier === id),
      );

      if (currentNode) {
        results[currentNode.identifier] = { ...currentNode, level: currentLevel };
      }

      addLevelRecursive(currentNodeChildrenNodes, currentLevel + 1);
    });
  };

  addLevelRecursive(rootNodes, 1);
  return Object.values(results);
};
