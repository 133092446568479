import { Alignment, Checkbox as BlueprintCheckbox } from '@blueprintjs/core';
import PropTypes from 'prop-types';
import { useCallback } from 'react';

import { getNestedPropertyValue } from '../../utils/functions';
import Box from '../Box';

import ErrorText from './ErrorText';

const Checkbox = ({ id, formik, label, disabled, onChange, inline, alignIndicator, containerProps, ...rest }) => {
  const getPropValue = useCallback((path, object) => getNestedPropertyValue(path, object), []);
  const isError = getPropValue(id, formik.touched) && Boolean(getPropValue(id, formik.errors));

  return (
    <Box position="relative" pb={0.5} {...containerProps}>
      <BlueprintCheckbox
        checked={getPropValue(id, formik.values)}
        onChange={onChange || ((e) => formik.setFieldValue(id, e.target.checked))}
        disabled={disabled}
        inline={inline}
        alignIndicator={alignIndicator}
        style={{ width: 'max-content' }}
        {...rest}
      >
        {label}
      </BlueprintCheckbox>
      {isError && <ErrorText>{getPropValue(id, formik.errors)}</ErrorText>}
    </Box>
  );
};

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  formik: PropTypes.object.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  inline: PropTypes.bool,
  alignIndicator: PropTypes.oneOf([Alignment.LEFT, Alignment.RIGHT]),
  containerProps: PropTypes.object,
};

Checkbox.defaultProps = {
  disabled: false,
  onChange: null,
  inline: false,
  alignIndicator: Alignment.LEFT,
  containerProps: {},
};

export default Checkbox;
