import { useEffect, useRef } from 'react';

/**
 * The hook works exactly like useEffect,
 * except it is called only a single time when the component mount.
 */
function useSingleEffect(effect) {
  const destroy = useRef();
  const calledOnce = useRef(false);
  const renderAfterCalled = useRef(false);

  if (calledOnce.current) renderAfterCalled.current = true;

  useEffect(() => {
    if (calledOnce.current) return;

    calledOnce.current = true;
    destroy.current = effect();

    return () => {
      if (!renderAfterCalled.current) return;
      if (destroy.current) destroy.current();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

export default useSingleEffect;
