import { Spinner as BlueprintSpinner, SpinnerSize } from '@blueprintjs/core';
import PropTypes from 'prop-types';

const Spinner = ({ intent, size, value, centered, ...rest }) => {
  return (
    <BlueprintSpinner
      intent={intent}
      size={size}
      value={value}
      style={{
        ...(centered && {
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }),
      }}
      {...rest}
    />
  );
};

Spinner.propTypes = {
  intent: PropTypes.oneOf(['none', 'primary', 'success', 'warning', 'danger']),
  size: PropTypes.oneOf([SpinnerSize.SMALL, SpinnerSize.LARGE, SpinnerSize.STANDARD]),
  value: PropTypes.number,
  centered: PropTypes.bool,
};

Spinner.defaultProps = {
  intent: 'none',
  size: SpinnerSize.STANDARD,
  value: undefined,
  centered: false,
};

export default Spinner;
