export const SEARCH_PLACEHOLDERS_MAP = {
  data_system: 'data systems',
  data_source: 'data sources',
  data_unit: 'data units',
  data_product: 'data products',
  application: 'applications',
};

export const ENTITY_TYPE_TO_API_ENTITY_TYPE_MAP = {
  data_system: 'data_system',
  data_source: 'data_source',
  data_unit: 'data_unit',
  data_product: 'data_product',
  application: 'output',
};

export const ENTITIES_METADATA_MAP = {
  data_system: {
    name: 'Data System',
    listQueryName: 'dataSystems',
  },
  data_source: {
    name: 'Data Source',
    listQueryName: 'dataSources',
  },
  data_unit: {
    name: 'Data Unit',
    listQueryName: 'dataUnits',
  },
  data_product: {
    name: 'Data Product',
    listQueryName: 'dataProducts',
  },
  application: {
    name: 'Application',
    listQueryName: 'outputs',
  },
};

export const ENTITIES_RELATION_METADATA_MAP = {
  data_system: {
    parent: [],
    child: ['data_source'],
  },
  data_source: {
    parent: ['data_system'],
    child: ['data_unit'],
  },
  data_unit: {
    parent: ['data_source'],
    child: ['data_product'],
  },
  data_product: {
    parent: ['data_unit', 'data_product'],
    child: ['application', 'data_product'],
  },
  application: {
    parent: ['data_product'],
    child: [],
  },
};

export const OUTPUT_TYPES = [{ label: 'Application', value: 'application' }];
