import { Button, Divider, Spinner } from '@blueprintjs/core';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';

import gatewayApi from '../../../services/gatewayApi';
import iamApi from '../../../services/iamApi';
import queryClient from '../../../services/queryClient';
import yup from '../../../utils/validator';
import TextInput from '../../form/TextInput';
import TextArea from '../../form/TextArea';
import MultiSelect from '../../form/MultiSelect';
import { toast } from '../../Toaster/Toaster';
import Stack from '../../Stack';
import Box from '../../Box';
import { getLinkingApiUrl } from '../utils';
import RadioGroup from '../../form/RadioGroup';
import {
  ENTITIES_METADATA_MAP,
  ENTITIES_RELATION_METADATA_MAP,
  ENTITY_TYPE_TO_API_ENTITY_TYPE_MAP,
} from '../constants';
import { useUserStore } from '../../../store/userStore';
import { getApiErrorMessage } from '../../../utils/functions';

const EntityAddNew = ({ onClose, sourceEntityId, sourceEntityType, targetLinkType, queriesToInvalidateOnAdd }) => {
  const relatedEntityTypes = ENTITIES_RELATION_METADATA_MAP[sourceEntityType][targetLinkType];
  const { userAccount } = useUserStore();
  const { isLoading: isCreateTargetEntityLoading, mutateAsync: createTargetEntity } = useMutation(
    async (formData) => {
      const { name, label, description, owner, contact_ids, links, targetEntityType } = formData;

      const entityRes = await gatewayApi.post(`/${ENTITY_TYPE_TO_API_ENTITY_TYPE_MAP[targetEntityType]}`, {
        entity: {
          name,
          label,
          description,
          ...(targetEntityType === 'application' && { output_type: targetEntityType }),
        },
        entity_info: { owner, contact_ids, links },
      });

      const targetEntityId = entityRes.data.identifier;

      await gatewayApi.post(
        getLinkingApiUrl({ sourceEntityId, sourceEntityType, targetEntityId, targetEntityType, targetLinkType }),
      );
      return entityRes.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['entityLinks', sourceEntityId]);
        onClose();
      },
      onError: (err) => {
        toast.error(getApiErrorMessage(err?.response?.data));
      },
    },
  );

  const formik = useFormik({
    initialValues: {
      name: '',
      label: '',
      owner: userAccount?.owner,
      description: '',
      contact_ids: [],
      links: [],
      targetEntityType: relatedEntityTypes?.[0],
    },
    validationSchema: yup.object({
      name: yup.string().required().max(250).validEntityName(),
      label: yup.string().max(3).required().validEntityLabel(),
      description: yup.string().max(1000),
      owner: yup.string().max(255),
      contact_ids: yup.array(),
    }),
    onSubmit: async (formData) => {
      await createTargetEntity(formData);

      for await (const query of queriesToInvalidateOnAdd) {
        await queryClient.invalidateQueries(query);
      }
    },
  });

  const { data: users, isFetching: isUsersLoading } = useQuery(
    ['nonSystemUserList'],
    async () => {
      const res = await iamApi.get('/users?system=false');
      return res.data.users;
    },
    {
      initialData: [],
    },
  );

  return (
    <Box width="100%" px={1}>
      {isUsersLoading ? (
        <Box py={2}>
          <Spinner />
        </Box>
      ) : (
        <>
          {relatedEntityTypes?.length > 1 && (
            <RadioGroup
              inline
              id="targetEntityType"
              formik={formik}
              optionsProps={relatedEntityTypes?.map((entityType) => ({
                label: ENTITIES_METADATA_MAP[entityType].name,
                value: entityType,
              }))}
              containerProps={{ pb: 0 }}
            />
          )}

          <Stack direction="row" gap={2}>
            <TextInput id="name" formik={formik} label="Name" placeholder="Please enter" />
            <TextInput id="owner" formik={formik} label="Owner organization" placeholder="Please enter" />
          </Stack>

          <Stack direction="row" gap={2}>
            <TextInput id="label" formik={formik} label="Initials" placeholder="Please enter" />
            <MultiSelect
              fill
              showClearButton
              formik={formik}
              id="contact_ids"
              label="Contact(s)"
              items={users.map((user) => ({ label: user?.username, value: user?.identifier }))}
            />
          </Stack>

          <TextArea
            id="description"
            formik={formik}
            label="Description"
            placeholder="Enter text"
            fill
            style={{ minHeight: '102px', minWidth: '100%' }}
          />

          <Divider />

          <Stack direction="row" justifyContent="flex-end" gap={1} py={1}>
            <Button onClick={onClose}>Cancel</Button>
            <Button intent="primary" onClick={formik.handleSubmit} loading={isCreateTargetEntityLoading}>
              Add
            </Button>
          </Stack>
        </>
      )}
    </Box>
  );
};

EntityAddNew.propTypes = {
  sourceEntityId: PropTypes.string.isRequired,
  sourceEntityType: PropTypes.string.isRequired,
  targetLinkType: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  queriesToInvalidateOnAdd: PropTypes.array,
};

EntityAddNew.defaultProps = {
  queriesToInvalidateOnAdd: [],
};

export default EntityAddNew;
