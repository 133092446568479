import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

/* 
  wrapper around react-error-boundary
  resets keys on location change to fix
  navigation related issues
*/

const ErrorBoundary = ({ children, ...rest }) => {
  const { key } = useLocation();
  return (
    <ReactErrorBoundary resetKeys={[key]} {...rest}>
      {children}
    </ReactErrorBoundary>
  );
};

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
