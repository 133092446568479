import { Colors } from '@blueprintjs/core';
import PropTypes from 'prop-types';
import { useLayoutEffect, useRef, useState } from 'react';

import Text from '../Text';
import Tooltip from '../Tooltip';
import Box from '../Box';

import './ErrorText.scss';

const ErrorText = ({ children, textStyles, containerProps }) => {
  const textRef = useRef(null);
  const [isTooltipEnabled, setIsTooltipEnabled] = useState(true);

  useLayoutEffect(() => {
    if (!textRef.current) {
      return;
    }
    setIsTooltipEnabled(textRef.current.scrollHeight / 2 >= textRef.current.clientHeight);
  }, [children]);

  return (
    <Tooltip
      disabled={!isTooltipEnabled}
      placement="bottom-start"
      className="error-tooltip"
      hoverOpenDelay={300}
      content={
        <Box
          style={{
            overflowWrap: 'break-word',
            whiteSpace: 'wrap',
            maxWidth: '300px',
          }}
        >
          <Text disableGutter tag="div">
            {children}
          </Text>
        </Box>
      }
    >
      <Box mt={0.5} {...containerProps}>
        <Text
          disableGutter
          style={{ color: Colors.RED3, fontSize: '12px', ...textStyles }}
          clampLines={1}
          textRef={textRef}
        >
          {children}
        </Text>
      </Box>
    </Tooltip>
  );
};

ErrorText.propTypes = {
  children: PropTypes.string.isRequired,
  textStyles: PropTypes.object,
  containerProps: PropTypes.object,
};
ErrorText.defaultProps = {
  textStyles: {},
  containerProps: {},
};

export default ErrorText;
