import { create } from 'zustand';

import { sanitizeString } from '../utils/functions';
import { CAST_TYPE_TO_PSQL_TYPE_MAP } from '../constants';

export const initialDataProductStore = {
  dataProduct: null,
  sparkJob: null,
  isSparkJobProcessing: false,
  transformations: [],
  builder: {},
};

export const useDataProductStore = create(
  (set, get) => ({
    ...initialDataProductStore,
    setDataProductData: (newData) => {
      set(newData);
    },
    addTransformation: (transformation) => {
      const prevTransformations = [...get().transformations];
      const id = prevTransformations.length;

      set({
        transformations: [...prevTransformations, { id, ...transformation }],
      });
    },
    removeTransformation: (idToRemove) => {
      const prevTransformations = [...get().transformations];
      const newTransformations = prevTransformations.filter(({ id }) => id !== idToRemove);
      const transformationsIdShift = newTransformations.map((t, i) => ({ ...t, id: i }));
      set({
        transformations: transformationsIdShift,
      });
    },
    generateSchemaFromTransformations: () => {
      const prevTransformations = get().transformations;
      const lastTransformation = prevTransformations[prevTransformations.length - 1];

      if (!lastTransformation || !lastTransformation.columns) {
        return null;
      }

      return Object.entries(lastTransformation?.columns).map(([columnName, columnType]) => ({
        data_type: { meta: {}, type: CAST_TYPE_TO_PSQL_TYPE_MAP[columnType] || columnType },
        description: '',
        name: columnName,
        optional: false,
        primary: false,
        tags: [],
      }));
    },
    generateTransformationsSparkRequest: (preview) => {
      const { transformations, dataProduct, builder } = get();
      const builderConfig = builder?.config || {};
      if (builderConfig.docker_tag) {
        delete builderConfig.docker_tag;
      }
      const isPivotTransformation = transformations.some((t) => t.metadata.type === 'pivot');
      return {
        config: builderConfig,
        inputs: dataProduct.sources.reduce((acc, source) => {
          acc[`input_${sanitizeString(source.identifier)}`] = {
            input_type: source.entity_type,
            identifier: source.identifier,
            preview_limit: isPivotTransformation ? -1 : 10,
          };
          return acc;
        }, {}),
        transformations: transformations.map((t) => {
          let sanitizedInput = '';
          if (t.metadata.inputType === 'transformation') {
            sanitizedInput = sanitizeString(t.metadata.input);
          } else {
            // This means that we have a data_unit or data_product as input
            sanitizedInput = t.metadata.input.startsWith('input_')
              ? t.metadata.input
              : `input_${sanitizeString(t.metadata.input)}`;
          }

          const normalizeTransformation = {
            ...t.metadata,
            input: sanitizedInput,
            output: sanitizeString(t.metadata.output),
            transform: t.metadata.type,
          };

          delete normalizeTransformation.inputType;

          return normalizeTransformation;
        }),
        finalisers: {
          enable_quality: true,
          enable_profiling: true,
          enable_classification: true,
          ...builder.finalisers,
          input: transformations[transformations.length - 1].metadata.output,
        },
        preview,
      };
    },
  }),
  {
    name: 'data_product_store',
  },
);
