import { Button, Colors, Menu, MenuItem, Popover } from '@blueprintjs/core';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useMutation } from '@tanstack/react-query';

import { DATE_TIME_FORMAT_2 } from '../../../constants';
import Icon from '../../Icon';
import Stack from '../../Stack';
import Text from '../../Text';
import { useUserStore } from '../../../store/userStore';
import TextInput from '../../form/TextInput';
import { EllipseText } from '../../EllipseText';
import { toast } from '../../Toaster/Toaster';
import queryClient from '../../../services/queryClient';
import gatewayApi from '../../../services/gatewayApi';
import { getApiErrorMessage } from '../../../utils/functions';

const MENU_ITEMS = [
  { label: 'Edit', value: 'edit', icon: 'edit' },
  { label: 'Delete', value: 'delete', icon: 'trash' },
];

const EntityNote = ({ note, entityId }) => {
  const { keycloak } = useUserStore();
  const [isEditMode, setIsEditMode] = useState(false);

  const { mutateAsync: updateNote, isLoading: isUpdateNoteLoading } = useMutation(
    async (payload) => {
      await gatewayApi.put(`/journal_note/${note.identifier}`, {
        note: payload.note,
        owner: note.owner,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['notes', entityId]);
      },
      onError: (err) => {
        toast.error(getApiErrorMessage(err?.response?.data));
      },
    },
  );

  const { mutateAsync: deleteNote, isLoading: isDeleteNoteLoading } = useMutation(
    async () => {
      await gatewayApi.delete(`/journal_note/${note.identifier}`);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['notes', entityId]);
        toast.success('Note deleted successfully.');
      },
      onError: (err) => {
        toast.error(getApiErrorMessage(err?.response?.data));
      },
    },
  );

  const formik = useFormik({
    initialValues: {
      note: note.note,
    },
    validationSchema: yup.object({
      note: yup.string().trim().required(),
    }),
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: (payload) => {
      updateNote({
        note: payload.note,
      });
    },
  });

  const handleMenuClick = useCallback(
    (item) => {
      switch (item.value) {
        case 'edit':
          setIsEditMode(true);
          break;
        case 'delete':
          deleteNote();
          break;
        default:
          break;
      }
    },
    [deleteNote],
  );

  return (
    <Stack key={note.identifier} direction="column" gap={1} width="100%">
      <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%">
        <Stack direction="row" gap={1} alignItems="center">
          <Icon size="xs" name="user" />
          <EllipseText
            value={note.owner}
            limit={14}
            toolTipContentStyle={{ maxWidth: '300px' }}
            textStyle={{
              fontSize: '12px',
              color: Colors.GRAY4,
              maxWidth: '90px',
            }}
          />
        </Stack>

        <Stack direction="row" alignItems="center" gap={0.5}>
          <Text small disableGutter color={Colors.GRAY4}>
            {dayjs(note.updated_at).format(DATE_TIME_FORMAT_2)}
          </Text>
          {keycloak?.tokenParsed?.preferred_username === note.owner && (
            <Popover
              content={
                <Menu>
                  {MENU_ITEMS.map((item) => (
                    <MenuItem
                      key={item.value}
                      icon={item.icon}
                      text={item.label}
                      onClick={() => handleMenuClick(item)}
                    />
                  ))}
                </Menu>
              }
              placement="bottom"
              minimal
              disabled={isDeleteNoteLoading}
            >
              <Button minimal>
                <Icon name="ellipsis-vertical" size="xs" />
              </Button>
            </Popover>
          )}
        </Stack>
      </Stack>
      {isEditMode ? (
        <form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
          <TextInput id="note" formik={formik} clearable={false} />
          <Stack direction="row" alignItems="center" justifyContent="flex-end" gap={1}>
            <Button
              onClick={() => {
                setIsEditMode(false);
                formik.resetForm();
              }}
            >
              Cancel
            </Button>
            <Button disabled={isUpdateNoteLoading} intent="primary" type="submit">
              Save
            </Button>
          </Stack>
        </form>
      ) : (
        <Text
          style={{
            wordBreak: 'break-all',
          }}
        >
          {note.note}
        </Text>
      )}
    </Stack>
  );
};

EntityNote.propTypes = {
  note: PropTypes.object.isRequired,
  entityId: PropTypes.string.isRequired,
};

export default EntityNote;
