import PropTypes from 'prop-types';
import classnames from 'classnames';

const relativeSizeOptions = ['2xs', 'xs', 'sm', 'lg', 'xl', '2xl'];
const literalSizeOptions = ['1x', '2x', '3x', '4x', '5x', '6x', '7x', '8x', '9x', '10x'];

const Icon = ({
  name,
  color,
  secondaryColor,
  size,
  fixedWidth,
  rotation,
  flip,
  animation,
  swapOpacity,
  style,
  ...rest
}) => {
  return (
    <i
      style={{ color, '--fa-secondary-color': secondaryColor, ...style }}
      className={classnames('fa-duotone', {
        'fa-fw': fixedWidth,
        'fa-swap-opacity': swapOpacity,
        [`fa-${name}`]: name,
        [`fa-${size}`]: size,
        [`fa-rotate-${rotation}`]: rotation,
        [`fa-flip-${flip}`]: flip,
        [`fa-${animation}`]: animation,
      })}
      {...rest}
    />
  );
};

Icon.propTypes = {
  color: PropTypes.string,
  name: PropTypes.string,
  secondaryColor: PropTypes.string,
  fixedWidth: PropTypes.bool,
  size: PropTypes.oneOf([...relativeSizeOptions, ...literalSizeOptions]),
  rotation: PropTypes.oneOf(['90', '180', '270']),
  flip: PropTypes.oneOf(['horizontal', 'vertical', 'both']),
  animation: PropTypes.oneOf(['spin', 'spin-pulse', 'beat', 'fade', 'beat-fade', 'bounce', 'flip', 'shake']),
  swapOpacity: PropTypes.bool,
  style: PropTypes.object,
};

Icon.defaultProps = {
  name: null,
  color: null,
  secondaryColor: null,
  size: null,
  fixedWidth: false,
  rotation: null,
  flip: null,
  animation: null,
  swapOpacity: false,
  style: {},
};

export default Icon;
