import { create } from 'zustand';
import { persist, subscribeWithSelector } from 'zustand/middleware';
import Keycloak from 'keycloak-js';

import queryClient from '../services/queryClient';
import gatewayApi from '../services/gatewayApi';
import iamApi from '../services/iamApi';
import registryApi from '../services/registryApi';
import config from '../config.json';

const INIT_OPTIONS = {
  url: config.NC_KEYCLOAK_URL,
  realm: config.NC_KEYCLOAK_REALM,
  clientId: config.NC_KEYCLOAK_CLIENT_ID,
};

const keycloak = new Keycloak(INIT_OPTIONS);

const initialAuthState = {
  userId: null,
  keycloak: null,
  theme: 'system',
  availableUserAccounts: [],
  userAccount: null,
};

export const useUserStore = create(
  subscribeWithSelector(
    persist(
      (set, get) => ({
        ...initialAuthState,
        initKeycloak: async () => {
          await keycloak.init({
            onLoad: 'login-required',
            checkLoginIframe: false,
          });
          const token = `Bearer ${keycloak.token}`;
          gatewayApi.defaults.headers.Authorization = token;
          iamApi.defaults.headers.Authorization = token;
          registryApi.defaults.headers.Authorization = token;

          set({
            userId: keycloak.tokenParsed.sub,
            keycloak,
          });
        },
        initUserAccount: async () => {
          const accountRes = await iamApi.get('/account');
          const accountsData = accountRes.data.accounts;
          const currentAccount = get().userAccount;

          const availableAccount =
            accountsData.find((account) => account.identifier === currentAccount?.identifier) || accountsData[0];

          registryApi.defaults.headers['x-account'] = availableAccount.name;
          // eslint-disable-next-line require-atomic-updates
          iamApi.defaults.headers['x-account'] = availableAccount.name;

          set({ availableUserAccounts: accountsData, userAccount: availableAccount });
        },
        handleLogout: async () => {
          await keycloak.logout({ redirectUri: window.location.origin });
          queryClient.clear();
          set(initialAuthState, true);
        },
        setTheme: (newTheme = null) => {
          const theme = newTheme || get().theme;
          let className = '';

          if (theme === 'system') {
            if (window?.matchMedia('(prefers-color-scheme: dark)').matches) {
              className = 'bp5-dark';
            }
          }

          if (theme === 'dark') {
            className = 'bp5-dark';
          }

          if (className) {
            document.getElementsByTagName('body')[0].className = className;
          } else {
            document.getElementsByTagName('body')[0].removeAttribute('class');
          }

          set({ theme });
        },
        isDarkTheme: () => {
          return get().theme === 'system'
            ? window?.matchMedia('(prefers-color-scheme: dark)').matches
            : get().theme === 'dark';
        },
        setUserAccount: (account) => {
          registryApi.defaults.headers['x-account'] = account.name;
          iamApi.defaults.headers['x-account'] = account.name;

          set({ userAccount: account });
        },
      }),
      {
        name: 'auth_store',
      },
    ),
  ),
);
