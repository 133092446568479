import PropTypes from 'prop-types';
import { useEffect } from 'react';

import Stack from '../../../../../../../components/Stack';
import TextInput from '../../../../../../../components/form/TextInput';
import yup from '../../../../../../../utils/validator';

const DataSourceS3Connection = ({ formik, setValidationSchema }) => {
  useEffect(() => {
    setValidationSchema((currentSchema) => {
      const { connection_type } = currentSchema;

      return {
        connection_type,
        access_key: yup.object({
          env_key: yup.string().required(),
        }),
        access_secret: yup.object({
          env_key: yup.string().required(),
        }),
        url: yup.string().required(),
      };
    });

    return () => {
      setValidationSchema((currentSchema) => {
        const { connection_type } = currentSchema;
        return { connection_type };
      });
    };
  }, [setValidationSchema]);

  return (
    <Stack direction="row" width="100%" gap={1}>
      <TextInput id="url" label="URL" formik={formik} />
      <TextInput id="access_key.env_key" label="Username (access key)" formik={formik} />
      <TextInput id="access_secret.env_key" label="Password (secret)" formik={formik} />
    </Stack>
  );
};

DataSourceS3Connection.propTypes = {
  formik: PropTypes.object.isRequired,
  setValidationSchema: PropTypes.func.isRequired,
};

export default DataSourceS3Connection;
