import { Suspense, lazy, useCallback, useLayoutEffect, useState } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';

import './App.scss';
import Box from './components/Box';
import ErrorBoundary from './components/ErrorBoundary';
import AppErrorBoundary from './components/ErrorPages/AppErrorBoundary';
import Spinner from './components/Spinner';
import Stack from './components/Stack';
import { toast } from './components/Toaster/Toaster';
import { TopbarProvider } from './components/Topbar/components/TopbarProvider';
import Sidebar from './components/sidebar/Sidebar';
import { ERROR_CODES, GENERAL_ERR_MSG } from './constants';
import DataSourceFeature from './features/DataSourceFeature';
import DataUnitFeature from './features/DataUnitFeature';
import useSingleEffect from './hooks/useSingleEffect';
import { useRegistryStore } from './store/registry';
import { useUserStore } from './store/userStore';

const SubscriptionsFeature = lazy(() => import('./features/SubscriptionsFeature'));
const DataLandscapeFeature = lazy(() => import('./features/DataLandscapeFeature/index.jsx'));
const UserPoliciesFeature = lazy(() => import('./features/UserManagement/index.jsx'));
const DataProductFeature = lazy(() => import('./features/DataProductFeature/index.jsx'));
const PublishedDataProductsFeature = lazy(() =>
  import('./features/DataProductFeature/pages/PublishedDataProducts/PublishedDataProducts.jsx'),
);
const DataSystemFeature = lazy(() => import('./features/DataSystemFeature/index.jsx'));
const ApplicationFeature = lazy(() => import('./features/ApplicationFeature/index.jsx'));
const MeshLandscapeFeature = lazy(() => import('./features/MeshLandscapeFeature/index.jsx'));

const App = () => {
  const { initKeycloak, initUserAccount, theme, setTheme, availableUserAccounts, setUserAccount } = useUserStore();
  const { initializeRegistry, setRegistryFromAccountAndCore, isLoading: isRegistryLoading } = useRegistryStore();
  const [sidebarExpanded, setSidebarExpanded] = useState(true);
  const navigate = useNavigate();
  const [initApp, setInitApp] = useState({
    loading: true,
    error: null,
  });
  useErrorHandler(initApp.error);

  const processDeepLink = useCallback(async () => {
    const url = new URL(window.location.href);

    const accountIdentifier = url.searchParams.get('account_identifier');
    const coreIdentifier = url.searchParams.get('core_identifier');
    const entityType = url.searchParams.get('entity_type');
    const entityIdentifier = url.searchParams.get('entity_identifier');
    const tab = url.searchParams.get('tab');
    const userAccount = availableUserAccounts.find((account) => account.identifier === accountIdentifier);

    if (!userAccount) {
      toast.error('Deep linked Account not found');
      navigate('/');
    }
    const coresData = await setRegistryFromAccountAndCore(userAccount, coreIdentifier);

    if (!coresData) {
      toast.error('Deep linked Core not found');
      navigate('/');
    }
    setUserAccount(userAccount);
    navigate(`/${entityType}/details/${entityIdentifier}${tab ? `/${tab}` : ''}`);
  }, [availableUserAccounts, navigate, setRegistryFromAccountAndCore, setUserAccount]);

  useSingleEffect(() => {
    setTheme();

    const url = new URL(window.location.href);
    const isDeepLink = url.searchParams.get('deep_link');

    (async () => {
      try {
        await initKeycloak();
        await initUserAccount();

        if (isDeepLink) {
          await processDeepLink();
        } else {
          await initializeRegistry();
        }
      } catch (err) {
        setInitApp((prevState) => ({
          ...prevState,
          // null here for it to be handled at router (this component) error boundary level
          error: Object.values(ERROR_CODES).includes(err) ? null : err?.response?.data?.message || GENERAL_ERR_MSG,
        }));
      } finally {
        setInitApp((prevState) => ({
          ...prevState,
          loading: false,
        }));
      }
    })();
  });

  useLayoutEffect(() => {
    const colorSchemeQuery = window?.matchMedia('(prefers-color-scheme: dark)');

    const handleSetTheme = () => {
      if (theme === 'system') {
        setTheme('system');
      }
    };

    colorSchemeQuery.addEventListener('change', handleSetTheme);

    return () => {
      colorSchemeQuery.removeEventListener('change', handleSetTheme);
    };
  }, [theme, setTheme]);

  return (
    <Box className="wrapper">
      {initApp.loading ? (
        <Spinner centered />
      ) : (
        <>
          <Sidebar expanded={sidebarExpanded} setExpanded={setSidebarExpanded} />
          <Stack
            ml={sidebarExpanded ? 32 : 8}
            transition="all 0.3s ease-in-out"
            minHeight="100vh"
            alignItems="unset"
            style={{
              position: 'relative',
            }}
          >
            {isRegistryLoading ? (
              <Spinner centered />
            ) : (
              <ErrorBoundary FallbackComponent={AppErrorBoundary}>
                <TopbarProvider>
                  <Suspense fallback={<Spinner />}>
                    <Routes>
                      <Route path="" element={<Navigate to="/mesh-landscape/" />} />
                      <Route path="/data-landscape/*" element={<DataLandscapeFeature />} />
                      <Route path="/mesh-landscape/*" element={<MeshLandscapeFeature />} />
                      <Route path="/published-data-products/*" element={<PublishedDataProductsFeature />} />
                      <Route path="/subscriptions/*" element={<SubscriptionsFeature />} />
                      <Route path="/data-product/*" element={<DataProductFeature />} />
                      <Route path="/data-system/*" element={<DataSystemFeature />} />
                      <Route path="/data-unit/*" element={<DataUnitFeature />} />
                      <Route path="/data-source/*" element={<DataSourceFeature />} />
                      <Route path="/application/*" element={<ApplicationFeature />} />
                      <Route path="/user-policies/*" element={<UserPoliciesFeature />} />
                      <Route exact path="*" element={<div>Not Found</div>} />
                    </Routes>
                  </Suspense>
                </TopbarProvider>
              </ErrorBoundary>
            )}
          </Stack>
        </>
      )}
    </Box>
  );
};

export default App;
