import { Colors } from '@blueprintjs/core';

import { useUserStore } from '../store/userStore';

export const generateInitialNodePositions = (
  nodes,
  positions,
  objectsInRow = 10,
  startX = 0,
  startY = 0,
  spaceX = 200,
  spaceY = 100,
) => {
  const newNodes = [];
  let currentX = startX;
  let currentY = startY;
  let rowIsEven = true;
  const shift = spaceX / 2;
  let rowCounter = 0;
  for (let i = 0; i < nodes.length; i++) {
    // coordinates.push({ x: currentX, y: currentY });
    const node = nodes[i];
    newNodes.push({
      ...node,
      x: positions?.[node.identifier]?.x || currentX,
      y: positions?.[node.identifier]?.y || currentY,
    });
    rowCounter++;

    if (rowCounter < objectsInRow) {
      // we are in the same row
      currentX += spaceX;
    } else {
      // we are move to the next row
      rowCounter = 0;
      rowIsEven = !rowIsEven;
      if (rowIsEven) {
        currentX = startX;
      } else {
        currentX = startX + shift;
      }
      currentY += spaceY;
    }
  }

  return newNodes;
};

/**
 * @param {('s'|'m'|'l')} size
 * @returns {object} network options
 */
export const getNetworkWithEdgesDefaultOptions = (size = 'm') => {
  const sizeChart = {
    s: {
      nodeSpacing: 100,
      levelSeparation: 200,
      treeSpacing: 120,
    },
    m: {
      nodeSpacing: 120,
      levelSeparation: 250,
      treeSpacing: 160,
    },
    l: {
      nodeSpacing: 150,
      levelSeparation: 300,
      treeSpacing: 200,
    },
  };

  return {
    physics: true,
    interaction: {
      zoomSpeed: 0.4,
      navigationButtons: false,
      hover: true,
      dragNodes: false,
    },
    layout: {
      randomSeed: 1,
      improvedLayout: false,
      hierarchical: {
        direction: 'LR',
        sortMethod: 'directed',
        parentCentralization: false,
        shakeTowards: 'roots',
        edgeMinimization: true,
        ...sizeChart[size],
      },
    },
  };
};

export const drawExternalLabel = ({ ctx, x, y, entity, nodeHeight, labelBoxWidth = 135, labelBoxHeight = 40 }) => {
  const paddingX = 10;
  const fontSize = 12;
  const labelMarginTop = 8;
  const labelX = x - labelBoxWidth / 2;
  const labelY = y + nodeHeight + labelMarginTop;
  const radius = 5;
  // Draw label box
  ctx.beginPath();
  ctx.moveTo(labelX, labelY + radius);
  ctx.arcTo(labelX, labelY + labelBoxHeight, labelX + radius, labelY + labelBoxHeight, radius);
  ctx.arcTo(
    labelX + labelBoxWidth,
    labelY + labelBoxHeight,
    labelX + labelBoxWidth,
    labelY + labelBoxHeight - radius,
    radius,
  );
  ctx.arcTo(labelX + labelBoxWidth, labelY, labelX + labelBoxWidth - radius, labelY, radius);
  ctx.arcTo(labelX, labelY, labelX, labelY + radius, radius);
  ctx.fillStyle = useUserStore.getState()?.isDarkTheme() ? Colors.DARK_GRAY5 : Colors.WHITE;
  ctx.fill();
  ctx.closePath();
  // Draw label
  const computedLabel = `${entity?.name}\n${entity?.owner || ''}`;
  computedLabel.split('\n').forEach((newLine, i) => {
    ctx.beginPath();
    ctx.font = `${fontSize}px Nunito`;
    ctx.fillStyle =
      i === 0
        ? Colors[useUserStore.getState()?.isDarkTheme() ? 'WHITE' : 'BLACK']
        : Colors[useUserStore.getState()?.isDarkTheme() ? 'GRAY5' : 'GRAY1'];
    ctx.textBaseline = 'top';
    ctx.textAlign = 'center';
    ctx.fillText(newLine, labelX + labelBoxWidth / 2, labelY + fontSize / 2 + i * 16, labelBoxWidth - paddingX * 2);
    ctx.closePath();
  });
};
