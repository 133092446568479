import { useErrorHandler } from 'react-error-boundary';
import PropTypes from 'prop-types';

import { ERROR_CODES } from '../../constants';
import Stack from '../Stack';
import Text from '../Text';
import noDataImgUrl from '../../assets/images/no_data.svg';

const AppErrorBoundary = ({ error }) => {
  const bubbleError = useErrorHandler();
  switch (error) {
    case ERROR_CODES.INSUFFICIENT_PERMISSION:
      return <InsufficientPermissionErrorPage />;
    default:
      return bubbleError(error);
  }
};

AppErrorBoundary.propTypes = {
  error: PropTypes.object.isRequired,
};

export default AppErrorBoundary;

const InsufficientPermissionErrorPage = () => {
  return (
    <div className="container">
      <Stack direction="column" height="100vh" alignItems="center" justifyContent="center">
        <img src={noDataImgUrl} alt="Something went wrong" />
        <Text tagName="h2">Unfortunately you do not have rights for this page.</Text>
      </Stack>
    </div>
  );
};
