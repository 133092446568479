import { Button, Divider, Tab, Tabs } from '@blueprintjs/core';
import { useMutation } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Icon from '../../../../components/Icon';
import Spinner from '../../../../components/Spinner';
import Stack from '../../../../components/Stack';
import Text from '../../../../components/Text';
import { toast } from '../../../../components/Toaster/Toaster';
import { useSecureQuery } from '../../../../hooks/useSecureQuery';
import gatewayApi from '../../../../services/gatewayApi';
import queryClient from '../../../../services/queryClient';
import { useDataUnitStore } from '../../../../store/dataUnitStore';
import { getApiErrorMessage, getSystemCreatedEntityDescription } from '../../../../utils/functions';
import yup from '../../../../utils/validator';

import DataUnitDetailsSidebar from './components/DataUnitDetailsSidebar';
import DataUnitActionsTab from './pages/DataUnitActionsTab/DataUnitActionsTab';
import DataUnitConnectionsTab from './pages/DataUnitConnectionsTab/DataUnitConnectionsTab';

const DataUnitDetails = () => {
  const { setDataUnitData } = useDataUnitStore();
  const navigate = useNavigate();
  const params = useParams();
  const [selectedTabId, setSelectedTabId] = useState(params?.tab || 'connections');
  const [isEditMode, setIsEditMode] = useState(false);

  const { mutateAsync: updateDataUnit } = useMutation(
    async (formData) => {
      const { name, label, description, owner, contact_ids, links } = formData;
      const entityRes = await gatewayApi.put(`/data_unit/${params?.id}`, { entity: { name, label, description } });
      const entityInfoRes = await gatewayApi.put(`/data_unit/${params?.id}/info`, { owner, contact_ids, links });
      return { entity: entityRes.data, entity_info: entityInfoRes.data };
    },
    {
      onSuccess: ({ entity, entity_info }) => {
        setDataUnitData({ dataUnit: { entity, entity_info } });
        queryClient.setQueryData(['dataUnit', params?.id], { entity, entity_info });
        toast.success('Changes saved.');
        setIsEditMode(false);
      },
    },
  );

  const formik = useFormik({
    initialValues: {
      identifier: '',
      name: '',
      description: '',
      label: '',
      owner: '',
      contact_ids: [],
      links: [],
    },
    validationSchema: yup.object({
      name: yup.string().required().max(250).validEntityName(),
      label: yup.string().max(3).required().validEntityLabel(),
      description: yup.string().max(1000),
      owner: yup.string().max(255),
      contact_ids: yup.array(),
    }),
    onSubmit: async (data) => {
      try {
        await updateDataUnit(data);
      } catch (err) {
        toast.error(getApiErrorMessage(err?.response?.data));
      }
    },
  });

  const { isFetching: isDataUnitLoading } = useSecureQuery(
    ['dataUnit', params?.id],
    async () => {
      const dataUnitRes = await gatewayApi.get(`/data_unit/${params?.id}`);
      const dataUnitData = { ...dataUnitRes.data };
      if (dataUnitRes.entity?.is_system) {
        dataUnitRes.entity.description = getSystemCreatedEntityDescription(dataUnitRes.entity);
      }

      return dataUnitData;
    },
    {
      onSuccess: (data) => {
        setDataUnitData({ dataUnit: data });

        const { name, description, label } = data.entity;

        formik.setValues({
          name,
          description,
          label,
          owner: data?.entity_info?.owner || '',
          contact_ids: data?.entity_info?.contact_ids || [],
          links: data?.entity_info?.links || [],
        });
      },
    },
  );

  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="center" px={2} py={1}>
        <Text large fontWeight="700" disableGutter>
          Data Unit
        </Text>

        <Button icon={<Icon name="close" />} onClick={() => navigate('/data-landscape')}>
          Close
        </Button>
      </Stack>

      <Divider />

      <Stack direction="row" flex="1" gap={1}>
        <DataUnitDetailsSidebar
          formik={formik}
          isEditMode={isEditMode}
          setIsEditMode={setIsEditMode}
          isLoading={isDataUnitLoading}
        />
        {isDataUnitLoading ? (
          <Stack p={2} direction="row" justifyContent="center" width="100%">
            <Spinner />
          </Stack>
        ) : (
          <Tabs
            id="tabs"
            className="entity-detail-page-tabs"
            renderActiveTabPanelOnly
            selectedTabId={selectedTabId}
            onChange={(id) => {
              setSelectedTabId(id);
              navigate(`/data-unit/details/${params?.id}/${id}`, {
                replace: true,
              });
            }}
          >
            <Tab id="connections" title="Connections" panel={<DataUnitConnectionsTab />} />
            <Tab id="actions" title="Actions" panel={<DataUnitActionsTab />} />
          </Tabs>
        )}
      </Stack>
    </>
  );
};

export default DataUnitDetails;
