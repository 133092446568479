import { create } from 'zustand';

export const initialDataSourceStore = {
  dataSource: null,
};

export const useDataSourceStore = create(
  (set) => ({
    ...initialDataSourceStore,
    setDataSourceData: (newData) => {
      set(newData);
    },
  }),
  {
    name: 'data_source_store',
  },
);
