import { create } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';

import registryApi from '../services/registryApi';
import iamApi from '../services/iamApi';
import gatewayApi from '../services/gatewayApi';
import queryClient from '../services/queryClient';

const initialRegistryState = {
  cores: [],
  selectedCore: null,
  gatewayApi: null,
  isLoading: false,
};

export const useRegistryStore = create(
  subscribeWithSelector(
    (set) => ({
      ...initialRegistryState,
      initializeRegistry: async () => {
        set((state) => ({ ...state, isLoading: true }));
        const coresRes = await registryApi.get('/core');
        const coresData = coresRes.data.cores;
        let selectedCore = coresData.find((core) => core.host);
        // Check local storage for last selected core
        const lastSelected = window.localStorage.getItem('selectedCore');
        if (lastSelected) {
          const lastSelectedData = JSON.parse(lastSelected);
          if (coresData.find((core) => core.urn === lastSelectedData.urn)) {
            selectedCore = lastSelectedData;
          }
        }

        const coreSplit = selectedCore?.urn.split(':');
        const selectedCoreIdentifier = coreSplit?.[coreSplit.length - 1];

        set({
          cores: coresData,
          selectedCore,
          selectedCoreIdentifier,
          gatewayApi: `${selectedCore?.host}/v2`,
          isLoading: false,
        });
        gatewayApi.defaults.baseURL = `${selectedCore?.host}/v2`;
      },
      setRegistryFromAccountAndCore: async (account, coreIdentifier) => {
        set((state) => ({ ...state, isLoading: true }));
        registryApi.defaults.headers['x-account'] = account.name;
        iamApi.defaults.headers['x-account'] = account.name;
        const coresRes = await registryApi.get('/core');
        const coresData = coresRes.data.cores;
        const selectedCore = coresData.find((core) => core.urn.split(':').slice(-1)[0] === coreIdentifier);
        if (!selectedCore) {
          return;
        }
        set((state) => ({
          ...state,
          cores: coresData,
          gatewayApi: `${selectedCore.host}/v2`,
          selectedCore,
          isLoading: false,
        }));
        gatewayApi.defaults.baseURL = `${selectedCore.host}/v2`;
        queryClient.clear();
        // Add last core selected to local storage
        window.localStorage.setItem('selectedCore', JSON.stringify(selectedCore));
        return coresData;
      },
      setCore: (newCore) => {
        set((state) => ({
          ...state,
          selectedCore: newCore,
          gatewayApi: `${newCore.host}/v2`,
        }));
        gatewayApi.defaults.baseURL = `${newCore.host}/v2`;
        queryClient.clear();
        // Add last core selected to local storage
        window.localStorage.setItem('selectedCore', JSON.stringify(newCore));
      },
    }),
    {
      name: 'registry_store',
    },
  ),
);
