import { Button, Divider, Tab, Tabs } from '@blueprintjs/core';
import { useMutation } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Icon from '../../../../components/Icon';
import Spinner from '../../../../components/Spinner';
import Stack from '../../../../components/Stack';
import Text from '../../../../components/Text';
import { toast } from '../../../../components/Toaster/Toaster';
import { useSecureQuery } from '../../../../hooks/useSecureQuery';
import gatewayApi from '../../../../services/gatewayApi';
import { useDataSourceStore } from '../../../../store/dataSourceStore';
import { getApiErrorMessage, getSystemCreatedEntityDescription } from '../../../../utils/functions';
import yup from '../../../../utils/validator';

import DataSourceDetailsSidebar from './components/DataSourceDetailsSidebar';
import DataSourceActionsTab from './pages/DataSourceActionsTab/DataSourceActionsTab';
import DataSourceConnectionsTab from './pages/DataSourceConnectionsTab/DataSourceConnectionsTab';

const DataSourceDetails = () => {
  const { setDataSourceData } = useDataSourceStore();
  const params = useParams();
  const navigate = useNavigate();

  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedTabId, setSelectedTabId] = useState(params?.tab || 'connections');

  const { mutateAsync: updateDataSource } = useMutation(
    async (formData) => {
      const { name, label, description, owner, contact_ids, links } = formData;
      const entityRes = await gatewayApi.put(`/data_source/${params?.id}`, { entity: { name, label, description } });
      const entityInfoRes = await gatewayApi.put(`/data_source/${params?.id}/info`, { owner, contact_ids, links });
      return { entity: entityRes.data, entity_info: entityInfoRes.data };
    },
    {
      onSuccess: ({ entity, entity_info }) => {
        setDataSourceData({ dataSource: { entity, entity_info } });
        toast.success('Data source saved');
      },
      onError: (err) => {
        toast.error(getApiErrorMessage(err?.response?.data));
      },
      onSettled: () => {
        setIsEditMode(false);
      },
    },
  );

  const formik = useFormik({
    initialValues: {
      identifier: '',
      name: '',
      description: '',
      label: '',
      owner: '',
      contact_ids: [],
      links: [],
    },
    validationSchema: yup.object({
      name: yup.string().required().max(250).validEntityName(),
      label: yup.string().max(3).required().validEntityLabel(),
      description: yup.string().max(1000),
      owner: yup.string().max(255),
      contact_ids: yup.array(),
    }),
    onSubmit: async (data) => {
      try {
        await updateDataSource(data);
      } catch (err) {
        toast.error(getApiErrorMessage(err?.response?.data));
      }
    },
  });

  const { isFetching: isDataSourceLoading } = useSecureQuery(
    ['dataSource', params?.id],
    async () => {
      const dataSourceRes = await gatewayApi.get(`/data_source/${params?.id}`);
      const dataSourceData = { ...dataSourceRes.data };
      if (dataSourceData.entity?.is_system) {
        dataSourceData.entity.description = getSystemCreatedEntityDescription(dataSourceData.entity);
      }

      return dataSourceData;
    },
    {
      onSuccess: (dataSourceData) => {
        setDataSourceData({ dataSource: dataSourceData });
        const { name, description, label } = dataSourceData.entity;

        formik.setValues({
          name,
          description,
          label,
          owner: dataSourceData?.entity_info?.owner || '',
          contact_ids: dataSourceData?.entity_info?.contact_ids || [],
          links: dataSourceData?.entity_info?.links || [],
        });
      },
      onError: (err) => {
        toast.error(getApiErrorMessage(err?.response?.data));
      },
    },
  );

  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="center" px={2} py={1}>
        <Text large fontWeight="700" disableGutter>
          Data Source
        </Text>

        <Button icon={<Icon name="close" />} onClick={() => navigate('/data-landscape')}>
          Close
        </Button>
      </Stack>

      <Divider />

      <Stack direction="row" flex="1" gap={1}>
        {isDataSourceLoading ? (
          <Stack p={2} direction="row" justifyContent="center" width="100%">
            <Spinner />
          </Stack>
        ) : (
          <>
            <DataSourceDetailsSidebar
              formik={formik}
              isEditMode={isEditMode}
              setIsEditMode={setIsEditMode}
              isLoading={isDataSourceLoading}
            />

            <Tabs
              id="tabs"
              className="entity-detail-page-tabs"
              renderActiveTabPanelOnly
              selectedTabId={selectedTabId}
              onChange={(id) => {
                setSelectedTabId(id);
                navigate(`/data-source/details/${params?.id}/${id}`, {
                  replace: true,
                });
              }}
            >
              <Tab id="connections" title="Connections" panel={<DataSourceConnectionsTab />} />
              <Tab id="actions" title="Actions" panel={<DataSourceActionsTab />} />
            </Tabs>
          </>
        )}
      </Stack>
    </>
  );
};

export default DataSourceDetails;
