import { AnchorButton, Button } from '@blueprintjs/core';
import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Card2 from '../../../../../../components/Card2';
import Dialog from '../../../../../../components/Dialog';
import Stack from '../../../../../../components/Stack';
import Text from '../../../../../../components/Text';
import { toast } from '../../../../../../components/Toaster/Toaster';
import Tooltip from '../../../../../../components/Tooltip';
import gatewayApi from '../../../../../../services/gatewayApi';
import queryClient from '../../../../../../services/queryClient';
import { useDataSourceStore } from '../../../../../../store/dataSourceStore';
import { getApiErrorMessage } from '../../../../../../utils/functions';

const DataSourceActionsTab = () => {
  const navigate = useNavigate();
  const { dataSource } = useDataSourceStore();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const isSystemCreated = dataSource?.entity?.is_system;

  const { isLoading: isDeleteDataSourceLoading, mutateAsync: deleteDataSource } = useMutation(
    async () => {
      return gatewayApi.delete(`/data_source/${dataSource?.entity?.identifier}`);
    },
    {
      onSuccess: () => {
        navigate('/data-landscape?type=data_source');
      },
      onError: (err) => {
        toast.error(getApiErrorMessage(err?.response?.data));
      },
    },
  );

  const isDataSourceLinked =
    queryClient.getQueryData(['entityLinks', dataSource?.entity?.identifier])?.children?.length > 0;

  return (
    <>
      <Card2 title="Remove Data Source">
        <Stack gap={2}>
          <Text disableGutter>
            Removing <strong>{dataSource?.entity?.name}</strong> will remove it from your data landscape and all
            information within it.
          </Text>

          <Tooltip
            condition={isSystemCreated || isDataSourceLinked}
            content={
              isSystemCreated
                ? "Can't delete system created entity."
                : "Can't delete Data Source that has connected Data Units"
            }
          >
            <AnchorButton onClick={() => setIsDeleteDialogOpen(true)} disabled={isSystemCreated || isDataSourceLinked}>
              Remove Data Source
            </AnchorButton>
          </Tooltip>
        </Stack>
      </Card2>

      <Dialog
        title="Remove?"
        icon={null}
        isOpen={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
        actions={
          <>
            <Button onClick={() => setIsDeleteDialogOpen(false)}>Cancel</Button>
            <Button intent="danger" onClick={deleteDataSource} loading={isDeleteDataSourceLoading}>
              Remove
            </Button>
          </>
        }
      >
        <Text>
          Are you sure you want to remove <strong>{dataSource?.entity?.name}</strong>?
        </Text>
        <Text disableGutter>All connections & data will be removed from data landscape.</Text>
      </Dialog>
    </>
  );
};

export default DataSourceActionsTab;
