import { create } from 'zustand';

export const initialDataUnitStore = {
  dataUnit: null,
};

export const useDataUnitStore = create(
  (set, get) => ({
    ...initialDataUnitStore,
    setDataUnitData: (newData) => {
      set(newData);
    },
    setDataUnitConfiguration: (configurationData) => {
      set({ dataUnit: { ...get().dataUnit, configuration: configurationData } });
    },
  }),
  {
    name: 'data_unit_store',
  },
);
