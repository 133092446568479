import { Route, Routes } from 'react-router-dom';

import SelectCore from '../../components/Topbar/components/SelectCore';
import { useTopbar } from '../../components/Topbar/hooks/useTopbar';

import DataUnitDetails from './pages/DataUnitDetails/DataUnitDetails';

const DataUnitFeature = () => {
  useTopbar(<SelectCore />);
  return (
    <Routes>
      <Route path="/details/:id/:tab?" element={<DataUnitDetails />} />
    </Routes>
  );
};

export default DataUnitFeature;
