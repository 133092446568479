import { Button, InputGroup } from '@blueprintjs/core';
import PropTypes from 'prop-types';
import { useCallback } from 'react';

import { getNestedPropertyValue } from '../../utils/functions';
import Box from '../Box';
import Icon from '../Icon';
import Stack from '../Stack';

import InputHelperLabel from './InputHelperLabel';
import InputLabel from './InputLabel';
import ErrorIconTooltip from './ErrorIconTooltip';

const TextInput = ({
  id,
  formik,
  label,
  asyncControl,
  className,
  disabled,
  fill,
  inputRef,
  intent,
  large,
  leftElement,
  leftIcon,
  placeholder,
  readOnly,
  rightElement,
  round,
  small,
  type,
  onChange,
  helperText,
  formGroupProps,
  width,
  containerProps,
  labelStyle,
  errorTextStyle,
  tooltipContent,
  tooltipProps,
  clearable,
  ...rest
}) => {
  const getPropValue = useCallback((path, object) => getNestedPropertyValue(path, object), []);
  const getIsFieldTouched = useCallback(() => {
    const rootKey = id.split('.')[0];
    return getPropValue(rootKey, formik.touched);
  }, [formik.touched, getPropValue, id]);
  const isError = getIsFieldTouched(id, formik.touched) && Boolean(getPropValue(id, formik.errors));

  return (
    <Box position="relative" width={width} pb={0.5} {...containerProps}>
      <InputLabel
        id={id}
        label={label}
        labelStyle={labelStyle}
        tooltipContent={tooltipContent}
        tooltipProps={tooltipProps}
      />

      <InputGroup
        id={id}
        name={id}
        asyncControl={asyncControl}
        className={className}
        disabled={disabled}
        fill={fill}
        inputRef={inputRef}
        intent={intent || (isError ? 'danger' : 'none')}
        large={large}
        leftElement={leftElement}
        leftIcon={leftIcon && <Icon style={{ position: 'absolute', zIndex: 1, margin: '7px' }} name={leftIcon} />}
        onChange={
          onChange ||
          ((e) => {
            formik.setFieldValue(id, type === 'number' ? +e.target.value : e.target.value);
          })
        }
        onBlur={() => formik.setFieldTouched(id, true)}
        placeholder={placeholder}
        readOnly={readOnly}
        rightElement={
          <Stack
            direction="row"
            alignItems="center"
            style={{ height: '100%', position: 'relative' }}
            className="input-group-right-element"
          >
            {isError && <ErrorIconTooltip>{getNestedPropertyValue(id, formik.errors)}</ErrorIconTooltip>}
            {rightElement}

            {getPropValue(id, formik.values) && clearable && !disabled && (
              <Button
                icon={<Icon name="close" />}
                minimal
                onClick={() => {
                  formik.setFieldValue(id, '');
                  formik.setFieldTouched(id, false);
                }}
              />
            )}
          </Stack>
        }
        round={round}
        small={small}
        type={type}
        value={getPropValue(id, formik.values)}
        {...rest}
      />

      <InputHelperLabel
        id={id}
        isError={isError}
        errorTextStyle={errorTextStyle}
        formikErrors={formik?.errors}
        helperText={helperText}
      />
    </Box>
  );
};

TextInput.propTypes = {
  id: PropTypes.string.isRequired,
  formik: PropTypes.object.isRequired,
  label: PropTypes.string,
  asyncControl: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  fill: PropTypes.bool,
  inputRef: PropTypes.object,
  intent: PropTypes.oneOf(['none', 'primary', 'success', 'warning', 'danger']),
  large: PropTypes.bool,
  leftElement: PropTypes.node,
  leftIcon: PropTypes.string,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  rightElement: PropTypes.node,
  round: PropTypes.bool,
  small: PropTypes.bool,
  type: PropTypes.string,
  onChange: PropTypes.func,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  formGroupProps: PropTypes.object,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  containerProps: PropTypes.object,
  labelStyle: PropTypes.object,
  errorTextStyle: PropTypes.object,
  tooltipContent: PropTypes.node,
  tooltipProps: PropTypes.object,
  clearable: PropTypes.bool,
};

TextInput.defaultProps = {
  asyncControl: false,
  label: null,
  className: '',
  disabled: false,
  fill: false,
  inputRef: null,
  intent: null,
  large: false,
  leftElement: null,
  rightElement: null,
  leftIcon: null,
  placeholder: '',
  readOnly: false,
  round: false,
  small: false,
  type: 'text',
  onChange: null,
  helperText: null,
  formGroupProps: {},
  width: '100%',
  containerProps: {},
  labelStyle: {},
  errorTextStyle: {},
  tooltipContent: null,
  tooltipProps: {},
  clearable: true,
};

export default TextInput;
