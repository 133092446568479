import { Button, Colors, Divider, Menu, MenuItem, Popover } from '@blueprintjs/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import img from '../../assets/images/primary_logo.png';
import { TOPBAR_HEIGHT } from '../../constants';
import { useUserStore } from '../../store/userStore';
import { useRegistryStore } from '../../store/registry';
import Text from '../Text';
import Box from '../Box';
import Icon from '../Icon';
import Stack from '../Stack';
import './Sidebar.scss';
import Select from '../form/Select';

import SidebarMenuItem from './SidebarMenuItem';

const getTopNavItems = (selectedCore, coreDomain) => [
  {
    title: 'Data Mesh',
    iconName: 'chart-network',
    path: '/mesh-landscape',
    matchExactPath: false,
  },
  ...(selectedCore
    ? [
        {
          title: 'Data Landscape',
          iconName: 'diagram-project',
          path: '/data-landscape',
          matchExactPath: false,
        },
      ]
    : []),
  {
    title: 'Data Catalog',
    iconName: 'list',
    path: '/published-data-products',
    matchExactPath: false,
  },
  {
    title: 'Subscriptions',
    iconName: 'bell',
    path: '/subscriptions',
    matchExactPath: false,
  },
  ...(selectedCore
    ? [
        {
          title: 'Data Explorer',
          iconName: 'chart-simple',
          path: encodeURI(`https://superset.${selectedCore.name}.neosdata.${coreDomain}`),
          matchExactPath: true,
          isExternalLink: true,
        },
      ]
    : []),
];

const THEMES = [
  {
    name: 'System theme',
    value: 'system',
  },
  {
    name: 'Dark theme',
    value: 'dark',
  },
  {
    name: 'Light theme',
    value: 'light',
  },
];

const Sidebar = ({ expanded, setExpanded }) => {
  const navigate = useNavigate();
  const { handleLogout, keycloak, theme, setTheme, setUserAccount, userAccount, availableUserAccounts } =
    useUserStore();
  const { selectedCore, initializeRegistry } = useRegistryStore();

  const coreDomain = useMemo(() => {
    const match = selectedCore.host.match(/\.neosdata\.([^/]+)/);
    return match ? match[1] : 'net';
  }, [selectedCore]);

  const accountsFormik = useFormik({
    initialValues: {
      selectedAccount: userAccount.identifier,
    },
  });

  const handleAccountSelect = async (item) => {
    accountsFormik.setFieldValue('selectedAccount', item.value);
    const accountData = availableUserAccounts.find((account) => account.identifier === item.value);
    setUserAccount(accountData);
    navigate('/mesh-landscape');
    await initializeRegistry();
  };

  return (
    <Box
      containerProps={{
        className: classNames('sidebar', {
          'sidebar--is-open': expanded,
        }),
      }}
    >
      <Stack direction="column" justifyContent="space-between" height="100%">
        <Box width="100%">
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            p={2}
            gap={1}
            height={`${TOPBAR_HEIGHT}px`}
            className="sidebar__topbar"
          >
            <Stack direction="row" alignItems="center" gap={1}>
              <img width="30px" src={img} alt="Sidebar logo" />
              {expanded && (
                <Text style={{ fontSize: '32px' }} disableGutter color={Colors.WHITE} fontWeight="700">
                  NEOS
                </Text>
              )}
            </Stack>
            {expanded && (
              <Button
                minimal
                icon={
                  <Box backgroundColor={Colors.DARK_GRAY5} p={1} borderRadius="3px">
                    <Icon color={Colors.LIGHT_GRAY2} name="chevron-left" size="sm" fixedWidth />
                  </Box>
                }
                onClick={() => setExpanded(!expanded)}
                style={{ padding: 0 }}
                alignText="center"
              />
            )}
          </Stack>
          {!expanded && (
            <>
              {/* this stack is here to center button while the sidebar is collapsing */}
              <Stack direction="column" alignItems="center" justifyContent="center" m={1}>
                <Button
                  minimal
                  small
                  style={{
                    backgroundColor: Colors.DARK_GRAY5,
                    padding: '16px 0',
                    width: '100%',
                  }}
                  icon={<Icon color={Colors.LIGHT_GRAY2} name="chevron-right" size="sm" />}
                  onClick={() => setExpanded(!expanded)}
                  alignText="center"
                />
              </Stack>
            </>
          )}
          <Box
            className="bp5-dark"
            {...(!expanded
              ? {
                  my: 2,
                  mx: 1,
                }
              : {
                  m: 2,
                })}
          >
            <Select
              showEmptyOption={false}
              id="selectedAccount"
              formik={accountsFormik}
              items={availableUserAccounts.map((account) => ({
                value: account.identifier,
                label: account.name,
              }))}
              onItemSelect={handleAccountSelect}
              containerProps={{ width: '100%' }}
              fill
              leftIconName="camera-web"
              popoverContentProps={{
                style: {
                  width: '213px',
                },
              }}
            />
          </Box>
          <Stack direction="column" alignItems="center" m={2} gap={2}>
            {getTopNavItems(selectedCore, coreDomain).map((item) => (
              <SidebarMenuItem
                key={item.title}
                expanded={expanded}
                iconName={item.iconName}
                title={item.title}
                path={item.path}
                matchExactPath={item.matchExactPath}
                highlightIcon={item.highlightIcon}
                isExternalLink={item.isExternalLink}
              />
            ))}
          </Stack>
          <Stack direction="column" alignItems="center" m={2} gap={0.5}>
            {/* Next group of navigation items go here */}
          </Stack>
        </Box>

        <Box width="100%">
          <Divider />
          <Stack direction="column" m={2} alignItems="center" gap={0.5}>
            <Popover
              minimal
              position="right-bottom"
              content={
                <Box px={1}>
                  <Box>
                    <Text
                      disableGutter
                      color={Colors.GRAY3}
                      style={{
                        padding: '4px 8px',
                      }}
                    >
                      Select theme
                    </Text>
                    <Divider />
                    <Stack direction="column" gap={0.5} my={0.5}>
                      {THEMES.map(({ name, value }) => (
                        <Button
                          key={value}
                          className={classNames('theme-button', {
                            'theme-button--selected': theme === value,
                          })}
                          minimal
                          onClick={() => setTheme(value)}
                          {...(theme === value && {
                            rightIcon: <Icon name="check" />,
                          })}
                        >
                          {name}
                        </Button>
                      ))}
                    </Stack>
                  </Box>
                  <Divider />
                  <Menu>
                    <MenuItem
                      onClick={handleLogout}
                      icon={<Icon name="right-from-bracket" />}
                      text={<Text disableGutter>Logout</Text>}
                    />
                  </Menu>
                </Box>
              }
              className="popover"
            >
              <SidebarMenuItem
                title={keycloak?.tokenParsed?.preferred_username}
                iconName="user"
                expanded={expanded}
                rightIcon="caret-right"
                noLeftIconBackground
              />
            </Popover>
          </Stack>

          <Divider />
        </Box>
      </Stack>
    </Box>
  );
};

Sidebar.propTypes = {
  expanded: PropTypes.bool.isRequired,
  setExpanded: PropTypes.func.isRequired,
};

export default Sidebar;
