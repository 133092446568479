import { Intent, OverlayToaster, Position } from '@blueprintjs/core';

import Text from '../Text';

import styles from './Toaster.module.css';

const defaultToasterOptions = {
  position: Position.TOP,
  maxToasts: 3,
  usePortal: true,
  canEscapeKeyClear: true,
  autoFocus: false,
};

const defaultToastOptions = {
  key: '',
  icon: undefined,
  isCloseButtonShown: true,
  onDismiss: () => {},
  timeout: 5000,
  action: null /* { text: string, onClick: func, icon: string, intent: Intent } */,
};

const messageWrapper = (message) => {
  if (typeof message === 'string') {
    return <Text tagName="div">{message}</Text>;
  }
  return message;
};

const toaster = OverlayToaster.create({
  className: styles['position-fixed'],
  ...defaultToasterOptions,
});

export const toast = {
  success: (message, options = defaultToastOptions) => {
    toaster.show({ message: messageWrapper(message), ...options, icon: 'tick', intent: Intent.SUCCESS }, options.key);
  },
  error: (message, options = defaultToastOptions) => {
    toaster.show({ message: messageWrapper(message), ...options, intent: Intent.DANGER });
  },
  primary: (message, options = defaultToastOptions) => {
    toaster.show({ message: messageWrapper(message), ...options, intent: Intent.PRIMARY });
  },
  warning: (message, options = defaultToastOptions) => {
    toaster.show({ message: messageWrapper(message), ...options, intent: Intent.WARNING });
  },
  default: (message, options = defaultToastOptions) => {
    toaster.show({ message: messageWrapper(message), ...options });
  },
  dismiss: (key) => toaster.dismiss(key),
};
