import { Radio, Card, Divider } from '@blueprintjs/core';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import { DATE_TIME_FORMAT } from '../../../constants';
import EntityShape from '../../EntityShape';
import Stack from '../../Stack';
import Text from '../../Text';
import EntityStatus from '../../EntityStatus';
import { EllipseText } from '../../EllipseText';

import './EntityAddLinkItem.scss';

const EntityAddLinkItem = ({
  formId,
  formik,
  identifier,
  entityType,
  name,
  description,
  owner,
  label,
  created_at,
  state,
}) => {
  const handleCheck = () => {
    if (formik.values[formId] === identifier) {
      formik.setFieldValue(formId, '');
      return;
    }

    formik.setFieldValue(formId, identifier);
  };

  return (
    <Stack direction="row" alignItems="center" gap={1} px={0.5} style={{ flexBasis: '49%' }}>
      <Radio id={formId} checked={formik.values[formId] === identifier} readOnly onClick={handleCheck} />
      <Card interactive className="entity-link-card" onClick={handleCheck}>
        <Stack direction="row" alignItems="stretch" gap={2} style={{ userSelect: 'none', height: '100%' }}>
          <Stack direction="column" gap={1} style={{ flexBasis: '75%' }}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" style={{ width: '100%' }}>
              <Stack direction="row" justifyContent="space-between" alignItems="center" gap={0.5}>
                <EntityShape type={entityType} data={{ label, state }} size="s" />
                <EllipseText
                  value={name}
                  limit={33}
                  textStyle={{
                    display: 'inline-block',
                    maxWidth: '230px',
                  }}
                />
              </Stack>
              <EllipseText
                value={owner || ''}
                limit={30}
                textStyle={{
                  fontSize: '10px',
                  wordBreak: 'break-word',
                  maxWidth: '150px',
                }}
                textProps={{
                  className: 'entity-link-card--text-owner',
                }}
              />
            </Stack>
            <Text small disableGutter className="entity-link-card--text-description" clampLines={2}>
              {description || <i>No description provided</i>}
            </Text>
          </Stack>
          <Divider className="entity-link-card--divider" />
          <Stack direction="column" alignItems="center" justifyContent="space-between" style={{ flexBasis: '25%' }}>
            <Text small disableGutter className="entity-link-card--text-createdAt">
              Created at: <br />
              <b>{dayjs(created_at).format(DATE_TIME_FORMAT)}</b>
            </Text>
            {state && <EntityStatus entityState={state} minimal entityType={entityType} />}
          </Stack>
        </Stack>
      </Card>
    </Stack>
  );
};

EntityAddLinkItem.propTypes = {
  formId: PropTypes.string.isRequired,
  formik: PropTypes.object.isRequired,
  identifier: PropTypes.string.isRequired,
  entityType: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  owner: PropTypes.string,
  label: PropTypes.string.isRequired,
  created_at: PropTypes.string.isRequired,
  state: PropTypes.object.isRequired,
};

export default EntityAddLinkItem;
