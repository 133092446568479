import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { useCallback, useMemo } from 'react';

import { useRegistryStore } from '../../../store/registry';
import Stack from '../../Stack';
import Text from '../../Text';
import Select from '../../form/Select';

const SelectCore = () => {
  const navigate = useNavigate();

  const { cores, setCore, selectedCore } = useRegistryStore();

  const formik = useFormik({
    initialValues: {
      selectedCore: selectedCore?.urn,
    },
  });

  const coresOptions = useMemo(() => {
    return cores?.filter((core) => core.host).map((core) => ({ label: core.name, value: core.urn }));
  }, [cores]);

  const handleCoreChange = useCallback(
    (newSelectedCore) => {
      if (newSelectedCore.value === selectedCore?.urn) {
        return;
      }

      formik.setFieldValue('selectedCore', newSelectedCore.value);
      setCore(cores.find((core) => core.urn === newSelectedCore.value));
      navigate('/data-landscape');
    },
    [cores, formik, navigate, selectedCore?.urn, setCore],
  );

  return (
    <Stack direction="row" gap={1} alignItems="center">
      <Text disableGutter>Cores:</Text>
      <Select
        showEmptyOption={false}
        id="selectedCore"
        formik={formik}
        items={coresOptions}
        onItemSelect={handleCoreChange}
        containerProps={{ width: '150px' }}
        leftIconName="camera-web"
      />
    </Stack>
  );
};

export default SelectCore;
