import { Button, Colors, MenuItem } from '@blueprintjs/core';
import { Select as BlueprintSelect } from '@blueprintjs/select';
import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';
import reactRequiredIf from 'react-required-if';

import { getNestedPropertyValue } from '../../utils/functions';
import Box from '../Box';
import Icon from '../Icon';
import Text from '../Text';
import Stack from '../Stack';

import InputHelperLabel from './InputHelperLabel';
import InputLabel from './InputLabel';
import ErrorIconTooltip from './ErrorIconTooltip';

const defaultFilterItem = (query, item, _index, exactMatch) => {
  const normalizedSearchBy = item.label.toLowerCase();
  const normalizedQuery = query.toLowerCase();

  if (exactMatch) {
    return normalizedSearchBy === normalizedQuery;
  } else {
    return item.label.indexOf(normalizedQuery) >= 0;
  }
};

const defaultRenderItem = (item, { handleClick, handleFocus, modifiers }) => {
  if (!modifiers.matchesPredicate) {
    return null;
  }

  return (
    <MenuItem
      active={modifiers.active}
      disabled={modifiers.disabled}
      key={item.value}
      onClick={handleClick}
      onFocus={handleFocus}
      roleStructure="listoption"
      text={<Text disableGutter>{item.label}</Text>}
      {...(item?.extraLabel && {
        label: <Text disableGutter>{item.extraLabel}</Text>,
      })}
    />
  );
};

const defaultRenderCreateItemOption = (query, active, handleClick) => {
  return (
    <MenuItem
      icon="add"
      text={`Create "${query}"`}
      roleStructure="listoption"
      active={active}
      onClick={handleClick}
      shouldDismissPopover={false}
    />
  );
};

const Select = ({
  id,
  formik,
  label,
  items,
  noResults,
  filterItem,
  disabled,
  renderItem,
  filterable,
  createItem,
  renderCreateItemOption,
  onItemSelect,
  popoverTriggerElement,
  allowCreate,
  fill,
  labelStyle,
  containerProps,
  showEmptyOption,
  leftIconName,
  buttonProps,
  buttonStyles,
  errorTextStyle,
  tooltipContent,
  tooltipProps,
  helperText,
  isLoading,
  ...rest
}) => {
  const getPropValue = useCallback((path, object) => getNestedPropertyValue(path, object), []);
  const isError = getPropValue(id, formik.touched) && Boolean(getPropValue(id, formik.errors));

  const itemsMemo = useMemo(() => {
    return [{ label: '-- None -- ', value: '' }, ...items];
  }, [items]);

  const itemLabelData = useMemo(() => {
    const item = items.find((i) => i.value === getPropValue(id, formik.values));
    return {
      label: item?.label || 'Please select',
      extraLabel: item?.extraLabel,
    };
  }, [formik?.values, getPropValue, id, items]);

  return (
    <Box
      position="relative"
      width="100%"
      className={isLoading ? 'bp5-skeleton' : undefined}
      pb={0.5}
      {...containerProps}
    >
      <InputLabel
        id={id}
        label={label}
        labelStyle={labelStyle}
        tooltipContent={tooltipContent}
        tooltipProps={tooltipProps}
      />

      <BlueprintSelect
        items={showEmptyOption ? itemsMemo : items}
        itemsEqual={(a, b) => {
          const valA = typeof a === 'string' ? a : a.value;
          const valB = typeof b === 'string' ? b : b.value;
          return valA === valB;
        }}
        activeItem={getPropValue(id, formik.values)}
        itemPredicate={filterItem}
        itemRenderer={renderItem}
        createNewItemFromQuery={allowCreate ? createItem : undefined}
        createNewItemRenderer={allowCreate ? renderCreateItemOption : null}
        filterable={filterable}
        noResults={noResults}
        onItemSelect={onItemSelect || (async (item) => formik.setFieldValue(id, item.value))}
        disabled={disabled}
        {...rest}
      >
        {popoverTriggerElement || (
          <Button
            fill={fill}
            alignText="left"
            id={id}
            disabled={disabled}
            text={
              <Text disableGutter style={{ whiteSpace: 'nowrap' }}>
                {itemLabelData.label}{' '}
                {itemLabelData.extraLabel && (
                  <Text disableGutter color={Colors.GRAY3} tagName="span">
                    {itemLabelData.extraLabel}
                  </Text>
                )}
              </Text>
            }
            {...(leftIconName && { icon: <Icon name={leftIconName} /> })}
            rightIcon={
              <Stack direction="row" alignItems="center" style={{ height: '100%' }} gap={1}>
                {isError && <ErrorIconTooltip>{getNestedPropertyValue(id, formik.errors)}</ErrorIconTooltip>}
                <Icon name="caret-down" />
              </Stack>
            }
            style={{ ...buttonStyles, ...(isError ? { outline: `1px solid ${Colors.RED3}` } : {}) }}
            {...buttonProps}
          />
        )}
      </BlueprintSelect>

      <InputHelperLabel
        id={id}
        isError={isError}
        errorTextStyle={errorTextStyle}
        formikErrors={formik?.errors}
        helperText={helperText}
      />
    </Box>
  );
};

Select.propTypes = {
  id: PropTypes.string.isRequired,
  formik: PropTypes.object.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      extraLabel: PropTypes.string,
    }),
  ).isRequired,
  filterItem: PropTypes.func,
  renderItem: PropTypes.func,
  filterable: PropTypes.bool,
  disabled: PropTypes.bool,
  noResults: PropTypes.node,
  createItem: reactRequiredIf(PropTypes.func, (props) => props.allowCreate),
  renderCreateItemOption: PropTypes.func,
  label: PropTypes.string,
  onItemSelect: PropTypes.func,
  popoverTriggerElement: PropTypes.node,
  allowCreate: PropTypes.bool,
  fill: PropTypes.bool,
  labelStyle: PropTypes.object,
  containerProps: PropTypes.object,
  showEmptyOption: PropTypes.bool,
  leftIconName: PropTypes.string,
  buttonProps: PropTypes.object,
  errorTextStyle: PropTypes.object,
  tooltipContent: PropTypes.node,
  tooltipProps: PropTypes.object,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  isLoading: PropTypes.bool,
  buttonStyles: PropTypes.object,
};

Select.defaultProps = {
  filterable: false,
  disabled: false,
  noResults: <MenuItem disabled text="No results." />,
  createItem: null,
  onItemSelect: null,
  popoverTriggerElement: null,
  allowCreate: false,
  filterItem: defaultFilterItem,
  renderItem: defaultRenderItem,
  renderCreateItemOption: defaultRenderCreateItemOption,
  fill: false,
  labelStyle: {},
  containerProps: {},
  buttonProps: {},
  showEmptyOption: true,
  leftIconName: '',
  label: '',
  errorTextStyle: {},
  tooltipContent: null,
  tooltipProps: {},
  helperText: null,
  isLoading: false,
  buttonStyles: {},
};

export default Select;
