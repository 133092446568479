import PropTypes from 'prop-types';

import { spacingUnit } from '../constants';

const Box = ({
  m,
  mt,
  mr,
  mb,
  ml,
  my,
  mx,
  p,
  pt,
  pr,
  pb,
  pl,
  py,
  px,
  children,
  containerProps,
  className,
  style,
  ...rest
}) => {
  const marginsProps = {
    ...(m && { margin: m * spacingUnit }),
    ...(mt && { marginTop: mt * spacingUnit }),
    ...(mr && { marginRight: mr * spacingUnit }),
    ...(mb && { marginBottom: mb * spacingUnit }),
    ...(ml && { marginLeft: ml * spacingUnit }),
    ...(my && { marginTop: my * spacingUnit, marginBottom: my * spacingUnit }),
    ...(mx && { marginLeft: mx * spacingUnit, marginRight: mx * spacingUnit }),
  };

  const paddingProps = {
    ...(p && { padding: p * spacingUnit }),
    ...(pt && { paddingTop: pt * spacingUnit }),
    ...(pr && { paddingRight: pr * spacingUnit }),
    ...(pb && { paddingBottom: pb * spacingUnit }),
    ...(pl && { paddingLeft: pl * spacingUnit }),
    ...(py && { paddingTop: py * spacingUnit, paddingBottom: py * spacingUnit }),
    ...(px && { paddingLeft: px * spacingUnit, paddingRight: px * spacingUnit }),
  };

  return (
    <div
      style={{
        ...marginsProps,
        ...paddingProps,
        ...rest,
        ...style,
      }}
      className={className}
      {...containerProps}
    >
      {children}
    </div>
  );
};

Box.propTypes = {
  children: PropTypes.node,
  m: PropTypes.number,
  mt: PropTypes.number,
  mr: PropTypes.number,
  mb: PropTypes.number,
  ml: PropTypes.number,
  p: PropTypes.number,
  pt: PropTypes.number,
  pr: PropTypes.number,
  pb: PropTypes.number,
  pl: PropTypes.number,
  my: PropTypes.number,
  mx: PropTypes.number,
  py: PropTypes.number,
  px: PropTypes.number,
  style: PropTypes.object,
  containerProps: PropTypes.object,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

Box.defaultProps = {
  m: undefined,
  mt: undefined,
  mr: undefined,
  mb: undefined,
  ml: undefined,
  p: undefined,
  pt: undefined,
  pr: undefined,
  pb: undefined,
  pl: undefined,
  my: undefined,
  mx: undefined,
  py: undefined,
  px: undefined,
  style: {},
  containerProps: {},
  className: undefined,
};

export default Box;
