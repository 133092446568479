import PropTypes from 'prop-types';

import { getNestedPropertyValue } from '../../utils/functions';
import Box from '../Box';
import Text from '../Text';

import ErrorText from './ErrorText';

const InputHelperLabel = ({ id, isError, errorTextStyle, formikErrors, helperText }) => {
  if (isError) {
    return <ErrorText textStyles={errorTextStyle}>{getNestedPropertyValue(id, formikErrors)}</ErrorText>;
  }

  return (
    helperText && (
      <Box mt={0.5} position="absolute">
        {typeof helperText === 'string' ? (
          <Text style={{ fontStyle: 'italic' }} disableGutter>
            {helperText}
          </Text>
        ) : (
          helperText
        )}
      </Box>
    )
  );
};

InputHelperLabel.propTypes = {
  id: PropTypes.string.isRequired,
  isError: PropTypes.bool,
  errorTextStyle: PropTypes.object,
  formikErrors: PropTypes.object,
  helperText: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

InputHelperLabel.defaultProps = {
  isError: false,
  errorTextStyle: {},
  formikErrors: {},
  helperText: null,
};

export default InputHelperLabel;
