import { Checkbox as BlueprintCheckbox, Button, Divider } from '@blueprintjs/core';
import { useMutation } from '@tanstack/react-query';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';

import Box from '../../../../../../../components/Box';
import Stack from '../../../../../../../components/Stack';
import Text from '../../../../../../../components/Text';
import { toast } from '../../../../../../../components/Toaster/Toaster';
import Checkbox from '../../../../../../../components/form/Checkbox';
import Select from '../../../../../../../components/form/Select';
import TextArea from '../../../../../../../components/form/TextArea';
import TextInput from '../../../../../../../components/form/TextInput';
import gatewayApi from '../../../../../../../services/gatewayApi';
import queryClient from '../../../../../../../services/queryClient';
import { useDataUnitStore } from '../../../../../../../store/dataUnitStore';
import { getApiErrorMessage } from '../../../../../../../utils/functions';
import yup from '../../../../../../../utils/validator';

const DATA_UNIT_TYPES = [
  {
    label: 'Table',
    value: 'table',
  },
  {
    label: 'Query',
    value: 'query',
  },
];

const DATA_UNIT_TYPE_INITIAL_VALUES = {
  table: {
    table: '',
  },
  query: {
    query: '',
  },
  csv: {
    has_header: false,
    delimiter: null,
    quote_char: null,
    escape_char: null,
    path: null,
  },
  data_product: {
    table: '',
    engine: 'postgres',
  },
  parquet: {},
};

const DATA_SOURCE_CONNECTION_TYPE_TO_TITLE_MAP = {
  s3: 'Insert the location of the CSV file you want to connect to Data Unit',
  database: 'Connect with table name or query',
  core: 'Enter the name of your data product',
  connector: 'Connect with parquet file',
};

const CSV_DELIMITERS = [
  {
    label: 'Comma',
    value: ',',
  },
  {
    label: 'Tab',
    value: '\t',
  },
  {
    label: 'Semicolon',
    value: ';',
  },
  {
    label: 'Pipe',
    value: '|',
  },
];

const CSV_QUOTE_CHARACTERS = [
  {
    label: 'Quote',
    value: "'",
  },
  {
    label: 'Double quote',
    // eslint-disable-next-line no-useless-escape, prettier/prettier
    value: '\"',
  },
];

const CSV_ESCAPE_CHARACTERS = [
  {
    label: 'Quote',
    value: "'",
  },
  {
    label: 'Double quote',
    // eslint-disable-next-line no-useless-escape, prettier/prettier
    value: '\"',
  },
  {
    label: 'Backslash',
    value: '\\',
  },
];

const DataProductConfiguration = ({ formik, setValidationSchema }) => {
  useEffect(() => {
    setValidationSchema((prevSchema) => ({
      ...prevSchema,
      table: yup.string().trim().required(),
      engine: yup.string().trim().required(),
    }));

    return () => {
      setValidationSchema((currentSchema) => {
        const { table, engine, ...rest } = currentSchema;
        return rest;
      });
    };
  }, [setValidationSchema]);

  return (
    <Stack direction="column" gap={1}>
      <Select
        fill
        id="engine"
        formik={formik}
        label="Engine"
        items={[
          { label: 'Postgres', value: 'postgres' },
          { label: 'Iceberg', value: 'iceberg' },
        ]}
        showEmptyOption={false}
      />
      <TextInput id="table" formik={formik} label="Data product name" />
    </Stack>
  );
};

DataProductConfiguration.propTypes = {
  formik: PropTypes.object.isRequired,
  setValidationSchema: PropTypes.func.isRequired,
};

const CsvConfiguration = ({ formik, setValidationSchema }) => {
  const { dataUnit } = useDataUnitStore();
  const [showAdvancedOptions, setShowAdvancedOptions] = useState(
    !!(
      dataUnit.configuration?.has_header ||
      dataUnit.configuration?.delimiter ||
      dataUnit.configuration?.quote_char ||
      dataUnit.configuration?.escape_char
    ),
  );

  useEffect(() => {
    setValidationSchema((prevSchema) => ({
      ...prevSchema,
      path: yup.string().trim().nullable().required(),
    }));

    return () => {
      setValidationSchema((currentSchema) => {
        const { path, ...rest } = currentSchema;
        return rest;
      });
    };
  }, [setValidationSchema]);

  return (
    <Stack direction="column" gap={2}>
      <TextInput id="path" formik={formik} label="CSV Location (URL or similar)" />
      <BlueprintCheckbox
        label="Advanced options"
        checked={showAdvancedOptions}
        onChange={(e) => {
          const has_header_value = e.target.checked ? dataUnit?.configuration?.has_header : null;
          const delimiter_value = e.target.checked ? dataUnit?.configuration?.delimiter : null;
          const quote_char_value = e.target.checked ? dataUnit?.configuration?.quote_char : null;
          const escape_char_value = e.target.checked ? dataUnit?.configuration?.escape_char : null;

          formik.setFieldValue('has_header', has_header_value);
          formik.setFieldValue('delimiter', delimiter_value);
          formik.setFieldValue('quote_char', quote_char_value);
          formik.setFieldValue('escape_char', escape_char_value);

          setShowAdvancedOptions(e.target.checked);
        }}
      />

      {showAdvancedOptions && (
        <>
          <Stack direction="row" gap={2} width="100%">
            <Checkbox
              formik={formik}
              id="has_header"
              label="My csv has header"
              containerProps={{ style: { alignSelf: 'end', width: '100%' } }}
            />
            <Select
              formik={formik}
              id="delimiter"
              label="Delimiter"
              items={CSV_DELIMITERS}
              fill
              onItemSelect={(e) => {
                formik.setFieldValue('delimiter', e.value || null);
              }}
            />
          </Stack>

          <Stack direction="row" gap={2} width="100%">
            <Select
              formik={formik}
              id="quote_char"
              label="Quote character"
              items={CSV_QUOTE_CHARACTERS}
              fill
              onItemSelect={(e) => {
                formik.setFieldValue('quote_char', e.value || null);
              }}
            />

            <Select
              formik={formik}
              id="escape_char"
              label="Escape character"
              items={CSV_ESCAPE_CHARACTERS}
              fill
              onItemSelect={(e) => {
                formik.setFieldValue('escape_char', e.value || null);
              }}
            />
          </Stack>
        </>
      )}
    </Stack>
  );
};

CsvConfiguration.propTypes = {
  formik: PropTypes.object.isRequired,
  setValidationSchema: PropTypes.func.isRequired,
};

const TableConfiguration = ({ formik, setValidationSchema }) => {
  useEffect(() => {
    setValidationSchema((prevSchema) => ({
      ...prevSchema,
      table: yup.string().trim().required(),
    }));

    return () => {
      setValidationSchema((currentSchema) => {
        const { table, ...rest } = currentSchema;
        return rest;
      });
    };
  }, [setValidationSchema]);

  return (
    <>
      <Text small fontWeight="700" disableGutter>
        Enter name
      </Text>
      <Text small>Define a specific table for the source of your data.</Text>
      <TextInput id="table" formik={formik} />
    </>
  );
};

TableConfiguration.propTypes = {
  formik: PropTypes.object.isRequired,
  setValidationSchema: PropTypes.func.isRequired,
};

const QueryConfiguration = ({ formik, setValidationSchema }) => {
  useEffect(() => {
    setValidationSchema((prevSchema) => ({
      ...prevSchema,
      query: yup.string().trim().required(),
    }));

    return () => {
      setValidationSchema((currentSchema) => {
        const { query, ...rest } = currentSchema;
        return rest;
      });
    };
  }, [setValidationSchema]);

  return (
    <>
      <Text small fontWeight="700" disableGutter>
        Enter query
      </Text>
      <Text small>Define a specific query for the source of your data.</Text>
      <TextArea
        fill
        id="query"
        formik={formik}
        style={{ minHeight: '80px', minWidth: '100%' }}
        placeholder="Add your query for tables here"
      />
    </>
  );
};

QueryConfiguration.propTypes = {
  formik: PropTypes.object.isRequired,
  setValidationSchema: PropTypes.func.isRequired,
};

const DataUnitAddEditConfiguration = ({ onClose, dataSourceConnectionType, dataUnitConfigurationType }) => {
  const { dataUnit, setDataUnitConfiguration } = useDataUnitStore();
  const [validationSchema, setValidationSchema] = useState({});
  const initialDataUnitType = useMemo(() => {
    switch (dataSourceConnectionType) {
      case 'database':
        // Database can be table || query but we are setting table as initial value
        return dataUnitConfigurationType || 'table';
      case 'core':
        return 'data_product';
      case 's3':
        return 'csv';
      case 'connector':
        return 'parquet';
    }
  }, [dataSourceConnectionType, dataUnitConfigurationType]);

  const { mutateAsync: updateConfiguration, isLoading: isUpdateConfigurationLoading } = useMutation(
    async (formData) => {
      const formKeysForType = Object.keys(DATA_UNIT_TYPE_INITIAL_VALUES[formData.data_unit_type]);
      const config = formKeysForType.reduce((acc, curr) => {
        return {
          ...acc,
          [curr]: formData[curr],
        };
      }, {});
      const result = await gatewayApi.put(`/data_unit/${dataUnit?.entity?.identifier}/config`, {
        configuration: {
          data_unit_type: formData.data_unit_type,
          ...config,
        },
      });
      return result.data;
    },
    {
      onSuccess: ({ configuration }) => {
        setDataUnitConfiguration(configuration);
        queryClient.invalidateQueries(['data_unit', dataUnit?.entity?.identifier]);
        queryClient.invalidateQueries(['schema', dataUnit?.entity?.identifier]);
        onClose();
      },
      onError: (err) => {
        toast.error(getApiErrorMessage(err?.response?.data));
      },
    },
  );

  const renderForm = useCallback((formik) => {
    switch (formik?.values?.data_unit_type) {
      case 'table':
        return <TableConfiguration formik={formik} setValidationSchema={setValidationSchema} />;
      case 'query':
        return <QueryConfiguration formik={formik} setValidationSchema={setValidationSchema} />;
      case 'csv':
        return <CsvConfiguration formik={formik} setValidationSchema={setValidationSchema} />;
      case 'data_product':
        return <DataProductConfiguration formik={formik} setValidationSchema={setValidationSchema} />;
    }
  }, []);

  return (
    <Box width="390px">
      <Box px={2} py={1}>
        <Text small disableGutter>
          {DATA_SOURCE_CONNECTION_TYPE_TO_TITLE_MAP[dataSourceConnectionType]}
        </Text>
      </Box>

      <Divider />

      <Box p={2}>
        <Formik
          initialValues={{
            ...DATA_UNIT_TYPE_INITIAL_VALUES[initialDataUnitType],
            ...(dataUnit?.configuration || {}),
            data_unit_type: initialDataUnitType,
          }}
          validationSchema={yup.object({
            data_unit_type: yup.string().required(),
            ...validationSchema,
          })}
          onSubmit={async (formData) => {
            await updateConfiguration(formData);
          }}
        >
          {(formik) => {
            return (
              <form onSubmit={formik.handleSubmit}>
                {dataSourceConnectionType === 'database' && (
                  <Select
                    fill
                    id="data_unit_type"
                    formik={formik}
                    label="Connect with..."
                    items={DATA_UNIT_TYPES}
                    showEmptyOption={false}
                    onItemSelect={(item) => {
                      formik.setValues({
                        data_unit_type: item.value,
                        ...(dataUnit?.configuration?.data_unit_type === item.value
                          ? dataUnit.configuration
                          : DATA_UNIT_TYPE_INITIAL_VALUES[item.value]),
                      });
                    }}
                  />
                )}

                {renderForm(formik)}

                <Stack direction="row" justifyContent="flex-end" gap={1}>
                  <Button onClick={onClose} type="button">
                    Cancel
                  </Button>

                  <Button intent="primary" type="submit" loading={isUpdateConfigurationLoading}>
                    Add
                  </Button>
                </Stack>
              </form>
            );
          }}
        </Formik>
      </Box>
    </Box>
  );
};

DataUnitAddEditConfiguration.propTypes = {
  onClose: PropTypes.func.isRequired,
  dataSourceConnectionType: PropTypes.string.isRequired,
  dataUnitConfigurationType: PropTypes.string.isRequired,
};

export default DataUnitAddEditConfiguration;
