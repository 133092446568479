import { createContext, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import Topbar from '../Topbar';

export const TopbarContext = createContext();

const DEFAULT_LEFT_ELEMENT = <div />;

export const TopbarProvider = ({ children }) => {
  const [leftElement, setLeftElement] = useState(DEFAULT_LEFT_ELEMENT);
  const [rightElement, setRightElement] = useState(null);

  const ctx = useMemo(() => ({ setLeftElement, setRightElement }), []);

  return (
    <TopbarContext.Provider value={ctx}>
      <Topbar leftElement={leftElement} rightElement={rightElement} />
      {children}
    </TopbarContext.Provider>
  );
};

TopbarProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
