import { Button, Divider } from '@blueprintjs/core';
import { useMutation } from '@tanstack/react-query';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';

import Box from '../../../../../../../components/Box';
import Stack from '../../../../../../../components/Stack';
import Text from '../../../../../../../components/Text';
import { toast } from '../../../../../../../components/Toaster/Toaster';
import RadioGroup from '../../../../../../../components/form/RadioGroup';
import gatewayApi from '../../../../../../../services/gatewayApi';
import queryClient from '../../../../../../../services/queryClient';
import { useDataSourceStore } from '../../../../../../../store/dataSourceStore';
import { getApiErrorMessage, sanitizeString } from '../../../../../../../utils/functions';
import yup from '../../../../../../../utils/validator';

import DataSourceCoreConnection from './DataSourceCoreConnection';
import DataSourceDatabaseConnection from './DataSourceDatabaseConnection';
import DataSourceS3Connection from './DataSourceS3Connection';

const connectionTypes = [
  {
    label: 'External Database',
    value: 'database',
  },
  {
    label: 'Connect to another Core',
    value: 'core',
  },
  {
    label: 'S3 - Simple Storage Service',
    value: 's3',
  },
  {
    label: 'Connector',
    value: 'connector',
  },
];

const connectionTypeToInitialValuesMap = {
  database: {
    user: {
      env_key: '',
    },
    password: {
      env_key: '',
    },
    database: '',
    engine: 'postgresql',
    host: '',
    port: '',
    schema: '',
  },
  s3: {
    url: '',
    access_key: {
      env_key: '',
    },
    access_secret: {
      env_key: '',
    },
  },
  connector: {},
};

const CONNECTION_DETAILS_MAP = {
  database: {
    userSecretLabel: 'DATABASE_USER',
    passwordSecretLabel: 'DATABASE_PASSWORD',
    userKey: 'user',
    passwordKey: 'password',
  },
  s3: {
    userSecretLabel: 'S3_ACCESS',
    passwordSecretLabel: 'S3_SECRET',
    userKey: 'access_key',
    passwordKey: 'access_secret',
  },
  connector: {},
};

const DataSourceConnection = ({ onClose }) => {
  const { dataSource, setDataSourceData } = useDataSourceStore();
  const [validationSchema, setValidationSchema] = useState({
    connection_type: yup.string().required(),
  });

  const { mutateAsync: addConnection, isLoading: isAddConnectionLoading } = useMutation(
    async (request) => {
      const connectionData = CONNECTION_DETAILS_MAP[request.connection_type];

      const result = await gatewayApi.put(`/data_source/${dataSource?.entity?.identifier}/connection`, {
        connection: {
          ...request,
          [connectionData.userKey]: { env_key: connectionData.userSecretLabel },
          [connectionData.passwordKey]: { env_key: connectionData.passwordSecretLabel },
        },
      });
      if (request.connection_type === 'connector') {
        return result.data;
      }
      if (dataSource?.secret_identifier) {
        // Check if secret is system created, we can't update those
        const secretRes = await gatewayApi.get(`/secret/${dataSource?.secret_identifier}`);
        const isSecretSystem = secretRes.data.is_system;

        if (!isSecretSystem) {
          await gatewayApi.put(`/secret/${dataSource?.secret_identifier}`, {
            name: `datasource-${sanitizeString(dataSource?.entity?.name)}`,
            data: {
              [connectionData.userSecretLabel]: request[connectionData.userKey].env_key,
              [connectionData.passwordSecretLabel]: request[connectionData.passwordKey].env_key,
            },
          });
        }
      } else {
        const secretRes = await gatewayApi.post(`/data_source/${dataSource?.entity?.identifier}/secret`, {
          [connectionData.userSecretLabel]: request[connectionData.userKey].env_key,
          [connectionData.passwordSecretLabel]: request[connectionData.passwordKey].env_key,
        });
        result.data.secret_identifier = secretRes.data.identifier;
      }

      return result.data;
    },
    {
      onSuccess: (data) => {
        setDataSourceData({ dataSource: { ...dataSource, ...data } });
        queryClient.invalidateQueries(['data_source', dataSource?.entity?.identifier]);
        toast.success(`Data Source ${dataSource?.entity?.name} configured successfully.`);
        onClose();
      },
      onError: (err) => {
        toast.error(getApiErrorMessage(err?.response?.data));
      },
    },
  );

  const initialFormValues = useMemo(
    () => ({
      ...(dataSource?.connection
        ? {
            ...dataSource.connection,
            ...(dataSource.connection.connection_type === 's3'
              ? {
                  access_key: { env_key: '' },
                  access_secret: { env_key: '' },
                }
              : {
                  user: { env_key: '' },
                  password: { env_key: '' },
                }),
          }
        : { connection_type: 'database', ...connectionTypeToInitialValuesMap.database }),
    }),
    [dataSource?.connection],
  );

  return (
    <Formik
      initialValues={initialFormValues}
      validationSchema={yup.object(validationSchema)}
      onSubmit={async (formData) => {
        await addConnection(formData);
      }}
    >
      {(formik) => {
        return (
          <Box width="100%">
            <Box px={2}>
              <Text fontWeight="700">Type of connection</Text>
              <RadioGroup
                id="connection_type"
                formik={formik}
                optionsProps={connectionTypes}
                inline
                containerProps={{ style: { paddingBottom: 0 } }}
                onChange={(event) => {
                  formik.setValues({
                    connection_type: event.target.value,
                    ...(dataSource?.connection?.connection_type === event.target.value
                      ? initialFormValues
                      : connectionTypeToInitialValuesMap[event.target.value]),
                  });

                  Object.keys(formik.touched).forEach((field) => formik.setFieldTouched(field, false));
                }}
              />
            </Box>

            <Divider />

            <Box px={2} pt={2}>
              {formik.values.connection_type === 'database' && (
                <DataSourceDatabaseConnection formik={formik} setValidationSchema={setValidationSchema} />
              )}

              {formik.values.connection_type === 'core' && (
                <DataSourceCoreConnection formik={formik} setValidationSchema={setValidationSchema} />
              )}

              {formik.values.connection_type === 's3' && (
                <DataSourceS3Connection formik={formik} setValidationSchema={setValidationSchema} />
              )}
            </Box>

            {formik.values.connection_type !== 'connector' && <Divider />}

            <Stack
              direction="row"
              gap={1}
              width="100%"
              justifyContent="flex-end"
              px={2}
              style={{ margin: '8px 16px 8px 0' }}
            >
              <Button onClick={onClose} disabled={isAddConnectionLoading}>
                Cancel
              </Button>

              <Button
                intent="success"
                onClick={(e) => {
                  formik.handleSubmit(e);
                  Object.keys(formik.values).forEach((field) => formik.setFieldTouched(field, true));
                }}
                loading={isAddConnectionLoading}
                disabled={formik.values.connection_type === 'core'}
              >
                Authorize
              </Button>
            </Stack>
          </Box>
        );
      }}
    </Formik>
  );
};

DataSourceConnection.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default DataSourceConnection;
