import axios from 'axios';

import { IAM_API_BASE_URL } from '../constants';

const instance = axios.create({
  baseURL: IAM_API_BASE_URL,
  headers: { 'x-account': 'root', 'x-partition': 'ksa' },
});

export default instance;
