import { Text as BlueprintText } from '@blueprintjs/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const tagNames = ['div', 'span', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'code', 'blockquote'];

const Text = ({
  textRef,
  tagName,
  children,
  monospace,
  running,
  small,
  large,
  muted,
  disabled,
  ellipsis,
  multilineCodeBlock,
  disableGutter,
  fontWeight,
  color,
  style,
  className,
  clampLines,
}) => {
  return (
    <BlueprintText
      ref={textRef}
      tagName={tagName}
      className={classNames(className, {
        'bp5-ui-text': true,
        'bp5-heading': tagName.startsWith('h'),
        'bp5-monospace-text': monospace,
        'bp5-running-text': running,
        'bp5-text-small': small,
        'bp5-text-large': large,
        'bp5-text-muted': muted,
        'bp5-text-disabled': disabled,
        'bp5-text-overflow-ellipsis': ellipsis,
        'bp5-code': tagName === 'code' && !multilineCodeBlock,
        'bp5-code-block': multilineCodeBlock,
        'bp5-blockquote': tagName === 'blockquote',
      })}
      style={{
        marginBottom: disableGutter ? 0 : false,
        fontWeight,
        ...(color && { color }),
        ...(clampLines && {
          overflow: 'hidden',
          display: '-webkit-box',
          wordBreak: 'break-word',
          '-webkit-box-orient': 'vertical',
          '-webkit-line-clamp': `${clampLines}`,
        }),
        ...style,
      }}
    >
      {children}
    </BlueprintText>
  );
};

Text.propTypes = {
  children: PropTypes.node.isRequired,
  tagName: PropTypes.oneOf(tagNames),
  monospace: PropTypes.bool,
  running: PropTypes.bool,
  small: PropTypes.bool,
  large: PropTypes.bool,
  muted: PropTypes.bool,
  disabled: PropTypes.bool,
  ellipsis: PropTypes.bool,
  multilineCodeBlock: PropTypes.bool,
  disableGutter: PropTypes.bool,
  style: PropTypes.object,
  fontWeight: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
  clampLines: PropTypes.number,
  textRef: PropTypes.object,
};

Text.defaultProps = {
  tagName: 'p',
  monospace: false,
  running: false,
  small: false,
  large: false,
  muted: false,
  disabled: false,
  ellipsis: false,
  multilineCodeBlock: false,
  disableGutter: false,
  fontWeight: '400',
  style: {},
  color: null,
  className: '',
  clampLines: null,
  textRef: null,
};

export default Text;
