import { Colors, Divider, Tag, Popover, PopoverInteractionKind } from '@blueprintjs/core';
import { useQuery } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';

import gatewayApi from '../services/gatewayApi';

import Box from './Box';
import Icon from './Icon';
import Spinner from './Spinner';
import Stack from './Stack';
import Text from './Text';

const DATA_UNIT_TYPE_FIELD_LABELS = {
  table: {
    data_unit_type: 'Data unit type',
    table: 'Table',
  },
  query: {
    data_unit_type: 'Data unit type',
    query: 'Query',
  },
  csv: {
    data_unit_type: 'Data unit type',
    path: 'Path',
    has_header: 'Has header',
    delimiter: 'Delimiter',
    quote_char: 'Quote char',
    escape_char: 'Escape char',
  },
  data_product: {
    data_unit_type: 'Data unit type',
    engine: 'Engine',
    table: 'Table',
  },
  parquet: {
    data_unit_type: 'Data unit type',
    mode: 'Mode',
  },
};

const DATA_SOURCE_CONNECTION_TYPE_FIELD_LABELS = {
  s3: {
    connection_type: 'Connection type',
    access_key: 'Username (access key)',
    access_secret: 'Password (secret)',
    url: 'URL',
  },
  database: {
    connection_type: 'Connection type',
    database: 'Database name',
    engine: 'Database type',
    host: 'Host',
    port: 'Port',
    user: 'Username (access key)',
    password: 'Password (secret)',
    schema: 'Schema',
  },
  core: {
    connection_type: 'Connection type',
    host: 'Core Name or ID',
    partition: 'Partition',
    account: 'Account',
    identifier: 'ID',
    access_key_id: 'Access key ID',
    secret_access_key: 'Secret access key',
  },
  connector: {
    connection_type: 'Connection type',
  },
};

const ENTITY_TYPE_TO_METADATA_MAP = {
  data_system: {
    healthy: 'Connected',
    unhealthy: 'Draft',
    type: 'data_system',
  },
  data_source: {
    healthy: 'Active',
    unhealthy: 'Draft',
    type: 'data_source',
  },
  data_unit: {
    healthy: 'Connected',
    unhealthy: 'Draft',
    type: 'data_unit',
  },
  data_product: {
    healthy: 'Connected',
    unhealthy: 'Draft',
    type: 'data_product',
  },

  application: {
    healthy: 'Connected',
    unhealthy: 'Draft',
    type: 'output',
  },
};

const getConnectionData = (entityType, entityData) => {
  let result;

  if (entityType === 'data_source') {
    result = entityData?.connection;
  } else if (entityType === 'data_unit') {
    result = entityData?.configuration;
  }

  return result || {};
};

const getFieldLabels = (entityType, entityData) => {
  switch (entityType) {
    case 'data_source':
      return DATA_SOURCE_CONNECTION_TYPE_FIELD_LABELS[entityData?.connection?.connection_type];
    case 'data_unit':
      return DATA_UNIT_TYPE_FIELD_LABELS[entityData?.configuration?.data_unit_type];
  }
};

const getPopoverContent = (entityType, entityData, entityState) => {
  const connectionData = getConnectionData(entityType, entityData);
  const connectionFieldToLabelMap = getFieldLabels(entityType, entityData);

  return entityState?.healthy ? (
    <Stack direction="column" gap={1} p={1} width="314px">
      <Text disableGutter fontWeight="700">
        Connection details
      </Text>

      <Divider />

      {Object.entries(connectionFieldToLabelMap).map(([field, label]) => {
        const value =
          typeof connectionData?.[field] === 'object' ? connectionData?.[field]?.env_key : connectionData?.[field];
        return (
          <Stack direction="row" gap={1} key={field} style={{ flex: 1, width: '100%' }}>
            <Text small disableGutter color={Colors.LIGHT_GRAY1} style={{ flexBasis: '25%' }}>
              {label}
            </Text>
            <Text disableGutter style={{ flexBasis: '75%', wordBreak: 'break-word' }}>
              {value ? String(value) : 'N/A'}
            </Text>
          </Stack>
        );
      })}
    </Stack>
  ) : (
    <Box p={1}>
      <Text disableGutter>{entityState?.state}</Text>
    </Box>
  );
};

const EntityStatus = ({ entityId, entityType, entityState, minimal, popoverContent }) => {
  const [isOpen, setIsOpen] = useState(false);

  const healthyStatus = useMemo(() => ENTITY_TYPE_TO_METADATA_MAP[entityType].healthy, [entityType]);
  const unHealthyStatus = useMemo(() => ENTITY_TYPE_TO_METADATA_MAP[entityType].unhealthy, [entityType]);

  const isEntityHealthy = useMemo(() => {
    return entityState?.healthy;
  }, [entityState?.healthy]);

  // TODO: Remove once BE returns config/connection data in linked entities list
  const { data: entityData, isLoading: isEntityLoading } = useQuery(
    [entityType, entityId],
    async () => {
      const result = await gatewayApi.get(`/${ENTITY_TYPE_TO_METADATA_MAP[entityType].type}/${entityId}`);
      return result.data;
    },
    {
      enabled: entityType && Boolean(entityId) && isEntityHealthy && isOpen,
    },
  );

  const defaulPopoverContent = useMemo(() => {
    if (isEntityHealthy) {
      return isEntityLoading ? (
        <Stack p={1} width="200px" height="200px" alignItems="center" justifyContent="center">
          <Spinner />
        </Stack>
      ) : (
        getPopoverContent(entityType, entityData, entityState)
      );
    }

    return (
      <Box p={1}>
        <Text disableGutter>{entityState?.state}</Text>
      </Box>
    );
  }, [isEntityHealthy, entityState, isEntityLoading, entityType, entityData]);

  return (
    <Stack direction="row" gap={1} alignItems="center">
      <>
        <Tag intent={isEntityHealthy ? 'success' : 'none'}>{isEntityHealthy ? healthyStatus : unHealthyStatus}</Tag>

        {!minimal && isEntityHealthy && (
          <Popover
            isOpen={isOpen}
            interactionKind={PopoverInteractionKind.HOVER}
            onInteraction={(value) => setIsOpen(value)}
            position="top"
            content={popoverContent || defaulPopoverContent}
          >
            <Box>
              <Icon name="circle-info" />
            </Box>
          </Popover>
        )}
      </>
    </Stack>
  );
};

EntityStatus.propTypes = {
  entityId: PropTypes.string,
  entityType: PropTypes.string.isRequired,
  entityState: PropTypes.object.isRequired,
  minimal: PropTypes.bool,
  popoverContent: PropTypes.node,
};

EntityStatus.defaultProps = {
  entityId: undefined,
  minimal: false,
  popoverContent: null,
};

export default EntityStatus;
