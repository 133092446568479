import { Button, Colors, SpinnerSize } from '@blueprintjs/core';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { useCallback, useLayoutEffect, useRef, useState } from 'react';

import gatewayApi from '../../services/gatewayApi';
import queryClient from '../../services/queryClient';
import { useUserStore } from '../../store/userStore';
import Box from '../Box';
import Spinner from '../Spinner';
import Stack from '../Stack';
import Text from '../Text';
import { toast } from '../Toaster/Toaster';
import TextInput from '../form/TextInput';
import yup from '../../utils/validator';
import { getApiErrorMessage } from '../../utils/functions';

import EntityNote from './components/EntityNote';

const EntityNotes = ({ entityType, entityId }) => {
  const notesContainerRef = useRef();
  const { keycloak } = useUserStore();
  const [notesHeight, setNotesHeight] = useState(0);

  useLayoutEffect(() => {
    if (!notesContainerRef.current) {
      return;
    }
    setNotesHeight(window.innerHeight - notesContainerRef.current.offsetTop);
  }, []);

  const { mutateAsync: addNote, isLoading: isAddNoteLoading } = useMutation(
    async ({ note, owner, resetFormCallback }) => {
      await gatewayApi.post(`/${entityType}/${entityId}/journal`, {
        note,
        owner,
      });

      return { resetFormCallback };
    },
    {
      onSuccess: ({ resetFormCallback }) => {
        resetFormCallback();
        queryClient.invalidateQueries(['notes', entityId]);
      },
      onError: (err) => {
        toast.error(getApiErrorMessage(err?.response?.data));
      },
    },
  );

  const { data: notesData, isFetching: isNotesLoading } = useQuery(
    ['notes', entityId],
    async () => {
      const notesRes = await gatewayApi.get(`/${entityType}/${entityId}/journal`, {
        params: {
          per_page: 100, // NOTE: Hardcoded max value until we have infinite scrolling or pagination
        },
      });

      const { notes } = notesRes.data.data;

      return { ...notesRes.data, notes: notes.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)) };
    },
    {
      onError: (err) => {
        toast.error(getApiErrorMessage(err?.response?.data));
      },
      enabled: !!entityId,
    },
  );

  const formik = useFormik({
    initialValues: {
      note: '',
    },
    validationSchema: yup.object({
      note: yup.string().trim().required(),
    }),
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: ({ note }) => {
      addNote({
        note,
        owner: keycloak?.tokenParsed?.preferred_username,
        resetFormCallback: formik.resetForm,
      });
    },
  });

  const renderNotes = useCallback(() => {
    if (notesData?.data?.notes?.length) {
      return notesData?.data?.notes.map((note) => <EntityNote key={note.identifier} entityId={entityId} note={note} />);
    }

    return (
      <Text style={{ fontStyle: 'italic' }} color={Colors.GRAY4}>
        No notes available
      </Text>
    );
  }, [entityId, notesData?.data?.notes]);

  return (
    <Box px={2} pt={2}>
      <form onSubmit={formik.handleSubmit}>
        <TextInput
          id="note"
          formik={formik}
          label="Notes"
          placeholder="Write note"
          rightElement={
            <Button loading={isAddNoteLoading} type="submit">
              Add
            </Button>
          }
          clearable={false}
        />
      </form>

      <Stack
        direction="column"
        gap={2}
        alignItems="center"
        overflowY="auto"
        minHeight="400px"
        height={`${notesHeight}px`}
        containerProps={{ ref: notesContainerRef }}
      >
        {isNotesLoading ? <Spinner size={SpinnerSize.SMALL} /> : renderNotes()}
      </Stack>
    </Box>
  );
};

EntityNotes.propTypes = {
  entityType: PropTypes.string.isRequired,
  entityId: PropTypes.string.isRequired,
};

export default EntityNotes;
