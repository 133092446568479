import { Button, Colors } from '@blueprintjs/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import Box from '../Box';
import Icon from '../Icon';
import Text from '../Text';
import './Sidebar.scss';
import Stack from '../Stack';

const SidebarMenuItem = ({
  expanded,
  iconName,
  onClick,
  title,
  path,
  matchExactPath,
  isExternalLink,
  noLeftIconBackground,
  rightIcon,
}) => {
  const navigate = useNavigate();

  const isActive = useMemo(() => {
    return matchExactPath ? window.location.pathname === path : window.location.pathname.startsWith(path);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname, matchExactPath, path]);

  return (
    <Button
      minimal
      large
      fill={expanded}
      alignText="left"
      rightIcon={
        rightIcon && expanded ? (
          <Stack p={1} backgroundColor={Colors.DARK_GRAY5} borderRadius="3px" justifyContent="center" height="35px">
            <Icon name={rightIcon} size="sm" fixedWidth color="#fff" swapOpacity />
          </Stack>
        ) : null
      }
      icon={
        <Box p={1} borderRadius="3px" {...(noLeftIconBackground ? {} : { backgroundColor: Colors.DARK_GRAY5 })}>
          <Icon color={Colors.LIGHT_GRAY2} name={iconName} size="sm" fixedWidth />
        </Box>
      }
      onClick={() => {
        if (path) {
          if (isExternalLink) {
            window.open(path, '_blank', 'noopener');
            return;
          }
          navigate(path);
          return;
        }
        if (onClick) {
          onClick();
        }
      }}
      className={classNames('button', {
        'button--active': isActive,
        'button--expanded': expanded,
        // [styles['button--highlighted']]: highlightIcon,
      })}
      text={
        expanded ? (
          <Text fontWeight="700" disableGutter color={Colors.LIGHT_GRAY2}>
            {title}
          </Text>
        ) : null
      }
    />
  );
};

SidebarMenuItem.propTypes = {
  expanded: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  path: PropTypes.string,
  iconName: PropTypes.string,
  onClick: PropTypes.func,
  matchExactPath: PropTypes.bool,
  isExternalLink: PropTypes.bool,
  noLeftIconBackground: PropTypes.bool,
  rightIcon: PropTypes.string,
};

SidebarMenuItem.defaultProps = {
  path: undefined,
  iconName: null,
  onClick: () => {},
  matchExactPath: false,
  isExternalLink: false,
  noLeftIconBackground: false,
  rightIcon: null,
};

export default SidebarMenuItem;
