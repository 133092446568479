import { Button, Divider, AnchorButton } from '@blueprintjs/core';
import { useQuery } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

import Box from '../../../../../components/Box';
import EntityShape from '../../../../../components/EntityShape';
import Icon from '../../../../../components/Icon';
import Spinner from '../../../../../components/Spinner';
import Stack from '../../../../../components/Stack';
import Text from '../../../../../components/Text';
import MultiSelect from '../../../../../components/form/MultiSelect';
import TextArea from '../../../../../components/form/TextArea';
import TextInput from '../../../../../components/form/TextInput';
import iamApi from '../../../../../services/iamApi';
import { useDataSourceStore } from '../../../../../store/dataSourceStore';
import TextValue from '../../../../../components/TextValue';
import Tooltip from '../../../../../components/Tooltip';
import EntityNotes from '../../../../../components/EntityNotes/EntityNotes';
import { getContactsData } from '../../../../../utils/functions';
import DeepLinkEntityButton from '../../../../../components/DeepLinkEntityButton';

const DataSourceDetailsSidebar = ({ formik, isEditMode, setIsEditMode, isLoading }) => {
  const { dataSource } = useDataSourceStore();
  const isSystemCreated = dataSource?.entity?.is_system;

  const { data: users, isLoading: isUsersLoading } = useQuery(
    ['usersList', isSystemCreated],
    async () => {
      const res = await iamApi.get(`/users?system=${isSystemCreated}`);
      return res.data.users;
    },
    {
      enabled: !!dataSource,
      initialData: [],
    },
  );

  const contactsData = useMemo(() => {
    return getContactsData(dataSource?.entity_info?.contact_ids, users);
  }, [dataSource?.entity_info?.contact_ids, users]);

  return (
    <Box className="entity-detail-sidebar-wrapper" alignSelf="stretch">
      {isLoading ? (
        <Box p={2}>
          <Spinner />
        </Box>
      ) : (
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
          }}
        >
          <Stack direction="row" gap={2} p={2} alignItems="center">
            <EntityShape type="data_source" data={{ ...dataSource?.entity, ...dataSource?.entity_info }} />

            <Stack flex="1">
              <Text disableGutter large style={{ wordBreak: 'break-word' }}>
                {dataSource?.entity?.name}
              </Text>
              <Stack direction="row" alignItems="center" width="100%">
                <Text disableGutter style={{ wordBreak: 'break-word', fontSize: '10px' }}>
                  <strong>ID:</strong> {dataSource?.entity?.identifier || ''}
                </Text>
                <DeepLinkEntityButton entity={dataSource?.entity} />
              </Stack>
            </Stack>
          </Stack>

          <Divider />

          <Box p={2} position="relative">
            {!isEditMode ? (
              <>
                <Box position="absolute" top="0" right="0" padding="inherit">
                  <Tooltip condition={isSystemCreated} content="Can't edit system created entity.">
                    <AnchorButton
                      icon={<Icon name="pen-to-square" />}
                      style={{ alignSelf: 'end' }}
                      onClick={() => setIsEditMode(true)}
                      disabled={isSystemCreated}
                    >
                      Edit
                    </AnchorButton>
                  </Tooltip>
                </Box>

                <Stack gap={2}>
                  <TextValue
                    left="Owner organization"
                    right={dataSource?.entity_info?.owner || '--'}
                    style={{ width: '230px' }}
                  />
                  <TextValue left="Contact(s)" right={contactsData || '--'} isLoading={isUsersLoading} />
                  <TextValue left="Description" right={dataSource?.entity?.description || '--'} />
                </Stack>
              </>
            ) : (
              <>
                <TextInput formik={formik} id="name" label="Name" />
                <TextInput formik={formik} id="label" label="Initials" />
                <TextInput formik={formik} id="owner" label="Owner organization" />

                <MultiSelect
                  fill
                  showClearButton
                  formik={formik}
                  id="contact_ids"
                  label="Contact(s)"
                  items={users.map((user) => ({ label: user?.username, value: user?.identifier }))}
                />

                <TextArea
                  fill
                  formik={formik}
                  id="description"
                  label="Description"
                  style={{ minWidth: '100%', minHeight: '74px' }}
                />
              </>
            )}
          </Box>

          <Divider />

          {isEditMode && (
            <>
              <Stack direction="row" justifyContent="end" py={1} px={2}>
                <>
                  <Button
                    onClick={() => setIsEditMode(false)}
                    style={{ marginRight: '8px' }}
                    disabled={formik.isSubmitting}
                  >
                    Cancel
                  </Button>
                  <Button intent="primary" onClick={formik.handleSubmit} loading={formik.isSubmitting}>
                    Save
                  </Button>
                </>
              </Stack>
              <Divider />
            </>
          )}
          <EntityNotes entityId={dataSource?.entity?.identifier} entityType="data_source" />
        </Box>
      )}
    </Box>
  );
};

DataSourceDetailsSidebar.propTypes = {
  formik: PropTypes.object.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  setIsEditMode: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

export default DataSourceDetailsSidebar;
