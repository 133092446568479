import { Tooltip as BlueprintTooltip } from '@blueprintjs/core';
import PropTypes from 'prop-types';
import { useState } from 'react';

const Tooltip = ({ className, content, children, condition, disabled, intent, placement, ...rest }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <BlueprintTooltip
      className={className}
      content={content}
      disabled={disabled}
      isOpen={isOpen}
      intent={intent}
      placement={placement}
      onInteraction={(nextOpenState) => {
        if (condition) {
          setIsOpen(nextOpenState);
        }
      }}
      onClosing={() => {
        setIsOpen(false);
      }}
      {...rest}
    >
      {children}
    </BlueprintTooltip>
  );
};

Tooltip.propTypes = {
  content: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  condition: PropTypes.bool,
  disabled: PropTypes.bool,
  intent: PropTypes.string,
  className: PropTypes.string,
  placement: PropTypes.array,
};

Tooltip.defaultProps = {
  condition: true,
  disabled: false,
  intent: 'none',
  placement: 'auto',
  className: undefined,
};

export default Tooltip;
