import { Card, Colors, Divider } from '@blueprintjs/core';
import PropTypes from 'prop-types';

import Box from './Box';
import Text from './Text';
import Stack from './Stack';

import './Card2.scss';

const Header = ({ title, subtitle, headerActions }) => {
  if (!title && !headerActions) {
    return null;
  }
  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        style={{
          height: '40px',
          padding: '8px 16px',
        }}
      >
        {typeof title === 'string' ? (
          <Box>
            <Text tagName="h6" disableGutter>
              {title}{' '}
              {subtitle && (
                <span
                  style={{
                    color: Colors.GRAY4,
                    fontSize: '12px',
                  }}
                >
                  {subtitle}
                </span>
              )}
            </Text>
          </Box>
        ) : (
          title
        )}

        <Box>{headerActions}</Box>
      </Stack>
      <Divider />
    </>
  );
};

Header.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  headerActions: PropTypes.node,
  subtitle: PropTypes.string,
};

Header.defaultProps = {
  title: null,
  subtitle: null,
  headerActions: null,
};

const Footer = ({ footer }) => {
  return <Box className="card2-footer">{footer}</Box>;
};

Footer.propTypes = {
  footer: PropTypes.node,
};

Footer.defaultProps = {
  footer: null,
};

const Card2 = ({
  title,
  subtitle,
  headerActions,
  children,
  footer,
  cardStyle,
  bodyStyle,
  elevation,
  interactive,
  onClick,
  ...rest
}) => {
  const hasChildren = children && (Array.isArray(children) ? children.some((v) => !!v) : true);
  return (
    <Card
      className="card2-wrapper"
      style={cardStyle}
      elevation={elevation}
      interactive={interactive}
      onClick={onClick}
      {...rest}
    >
      <Header title={title} subtitle={subtitle} headerActions={headerActions} />
      {hasChildren && (
        <Box
          style={{
            padding: '8px 16px',
            ...bodyStyle,
          }}
        >
          {children}
        </Box>
      )}

      {hasChildren && footer && <Divider />}

      {footer && <Footer footer={footer} />}
    </Card>
  );
};

Card2.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  headerActions: PropTypes.node,
  children: PropTypes.node,
  subtitle: PropTypes.string,
  footer: PropTypes.node,
  cardStyle: PropTypes.object,
  bodyStyle: PropTypes.object,
  elevation: PropTypes.oneOf([0, 1, 2, 3, 4]),
  interactive: PropTypes.bool,
  onClick: PropTypes.func,
};

Card2.defaultProps = {
  title: null,
  children: null,
  subtitle: null,
  headerActions: null,
  footer: null,
  cardStyle: {},
  bodyStyle: {},
  elevation: 0,
  interactive: false,
  onClick: () => {},
};

export default Card2;
