import { useCallback, useMemo, useRef, useState } from 'react';
import { Popover } from '@blueprintjs/core';
import PropTypes from 'prop-types';

import useSingleEffect from '../hooks/useSingleEffect';

const NetworkPopover = ({ network, nodes, popoverContentComponent: PopoverContent, popoverContentProps = {} }) => {
  const [popoverData, setPopoverData] = useState(null);
  const activeEventRef = useRef({
    eventType: '',
    nodeId: '',
  });

  const handleOnHover = useCallback(
    async (event, eventType) => {
      activeEventRef.current.eventType = eventType;
      activeEventRef.current.nodeId = nodes.current.get(event.node).id;

      const timeout = await new Promise((resolve) => {
        setTimeout(() => {
          resolve(true);
        }, 500);
      });

      if (
        activeEventRef.current.eventType === 'hoverNode' &&
        activeEventRef.current.nodeId === nodes.current.get(event.node).id &&
        timeout
      ) {
        setPopoverData({ data: nodes.current.get(event.node), metadata: event });
      }
    },
    [nodes],
  );

  const handleOnClose = useCallback(async (_, eventType) => {
    activeEventRef.current.eventType = eventType;
    activeEventRef.current.nodeId = '';
    setPopoverData(null);
  }, []);

  const domPositions = useMemo(() => {
    if (network.current && network.current.body && popoverData) {
      const node = network.current.body.nodes[popoverData.data.id];
      const dom = network.current.canvasToDOM({
        x: node.x + node.baseSize / 2 - 6, // 6 is acting as a padding
        y: node.y + node.baseSize / 2 - 6, // 6 is acting as a padding
      });
      return {
        top: dom.y,
        left: dom.x,
      };
    }

    return {
      top: 0,
      left: 0,
    };
  }, [popoverData, network]);

  useSingleEffect(() => {
    network.current.on('hoverNode', (event) => {
      handleOnHover(event, 'hoverNode');
    });
    network.current.on('blurNode', (event) => {
      handleOnClose(event, 'blurNode');
    });
    network.current.on('click', (event) => {
      handleOnClose(event, 'click');
    });
    network.current.on('dragStart', (event) => {
      handleOnClose(event, 'dragStart');
    });
  });

  return (
    popoverData && (
      <div style={{ position: 'absolute', ...domPositions }}>
        <Popover
          isOpen
          autoFocus={false}
          interactionKind="hover"
          position="bottom"
          content={<PopoverContent data={popoverData.data} {...popoverContentProps} />}
          usePortal={false}
        />
      </div>
    )
  );
};

NetworkPopover.propTypes = {
  network: PropTypes.object.isRequired,
  nodes: PropTypes.object.isRequired,
  popoverContentComponent: PropTypes.func.isRequired,
  popoverContentProps: PropTypes.object,
};

export default NetworkPopover;
