import { AnchorButton, Button, Divider } from '@blueprintjs/core';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useCallback, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import Box from '../../../../../../components/Box';
import Card2 from '../../../../../../components/Card2';
import DeleteConfirmationAlert from '../../../../../../components/DeleteConfirmationAlert';
import EntityAddTabs from '../../../../../../components/EntityAddTabs/EntityAddTabs';
import EntityShape from '../../../../../../components/EntityShape';
import EntityStatus from '../../../../../../components/EntityStatus';
import Icon from '../../../../../../components/Icon';
import Stack from '../../../../../../components/Stack';
import Table2 from '../../../../../../components/Table2';
import Text from '../../../../../../components/Text';
import { toast } from '../../../../../../components/Toaster/Toaster';
import Tooltip from '../../../../../../components/Tooltip';
import VisNetwork from '../../../../../../components/VisNetwork';
import gatewayApi from '../../../../../../services/gatewayApi';
import queryClient from '../../../../../../services/queryClient';
import { useDataSourceStore } from '../../../../../../store/dataSourceStore';
import { getApiErrorMessage } from '../../../../../../utils/functions';
import { getNetworkWithEdgesDefaultOptions } from '../../../../../../utils/networkHelpers';
import { getNodeNetworkData } from '../../../../../DataLandscapeFeature/constants';

import DataSourceConnection from './components/DataSourceConnection';

const NETWORK_OPTIONS = getNetworkWithEdgesDefaultOptions('m');

const dataSourceHeaderColumns = [
  {
    name: 'Source (this data source)',
    field: 'name',
  },
  {
    name: 'Owner organization',
    field: 'owner',
  },
  {
    name: 'Status',
    field: 'status',
  },
  {
    name: 'Actions',
    field: 'actions',
    basis: '150px',
  },
];

const dataSystemHeaderColumns = [
  {
    name: 'Data System',
    field: 'name',
  },
  {
    name: 'Owner organization',
    field: 'owner',
  },
  {
    name: '',
    field: '',
  },
  {
    name: 'Actions',
    field: 'actions',
    basis: '150px',
  },
];
const dataUnitHeaderColumns = [
  {
    name: 'Data Unit',
    field: 'name',
  },
  {
    name: 'Owner organization',
    field: 'owner',
  },
  {
    name: 'Status',
    field: 'status',
  },
  {
    name: 'Actions',
    field: 'actions',
    basis: '150px',
  },
];

const DataSourceConnectionsTab = () => {
  const navigate = useNavigate();
  const { dataSource } = useDataSourceStore();
  const [network, setNetwork] = useState({});
  const [isAddDataUnit, setIsAddDataUnit] = useState(false);
  const [isAddDataSystem, setIsAddDataSystem] = useState(false);
  const [isConnectDataSource, setIsConnectDataSource] = useState(false);
  const [dataSystemToUnlink, setDataSystemToUnlink] = useState(null);
  const [dataUnitToUnlink, setDataUnitToUnlink] = useState(null);
  const [isShowLandscapeView, setIsShowLandscapeView] = useState(localStorage.getItem('landscapeView') === 'true');

  const handleConnectDataSourceClose = useCallback(() => setIsConnectDataSource(false), []);

  const { mutateAsync: unlinkDataUnit, isLoading: isUnlinkDataUnitLoading } = useMutation(
    async () => {
      await gatewayApi.delete(
        `/link/data_source/${dataSource.entity.identifier}/data_unit/${dataUnitToUnlink.identifier}`,
      );
      await queryClient.invalidateQueries(['dataSourceLinks', dataSource?.entity?.identifier]);
      await queryClient.invalidateQueries(['entitiesToLink']);
    },
    {
      onSuccess: () => {
        setDataUnitToUnlink(null);
      },
      onError: (err) => {
        toast.error(getApiErrorMessage(err?.response?.data));
      },
    },
  );

  const { mutateAsync: unlinkDataSystem, isLoading: isUnlinkDataSystemLoading } = useMutation(
    async () => {
      await gatewayApi.delete(
        `/link/data_system/${dataSystemToUnlink.identifier}/data_source/${dataSource.entity.identifier}`,
      );
      await queryClient.invalidateQueries(['dataSourceLinks', dataSource?.entity?.identifier]);
    },
    {
      onSuccess: () => {
        setDataSystemToUnlink(null);
      },
      onError: (err) => {
        toast.error(getApiErrorMessage(err?.response?.data));
      },
    },
  );

  const { data: dataSourceLinks, isFetching: isDataSourceLinksLoading } = useQuery(
    ['dataSourceLinks', dataSource?.entity?.identifier],
    async () => {
      const linksRes = await gatewayApi.get(`/data_source/${dataSource?.entity?.identifier}/link`);
      const linksResData = linksRes.data;

      if (linksResData.children.length === 0) {
        return linksResData;
      }

      const dataUnitsRequest = await gatewayApi.get(`/data_unit`);
      const dataUnits = dataUnitsRequest.data.entities;

      const children = linksResData.children.map((child) => {
        const dataUnit = dataUnits.find((entity) => entity.identifier === child.identifier);
        return {
          ...child,
          entity_type: dataUnit.configuration?.data_unit_type === 'data_product' ? 'data_product' : 'data_unit',
          subscribed: dataUnit.configuration?.data_unit_type === 'data_product',
        };
      });

      return {
        parents: linksResData.parents,
        children,
      };
    },
    {
      initialData: { parents: [], children: [] },
      enabled: !!dataSource?.entity?.identifier,
    },
  );

  const networkData = useMemo(() => {
    if (dataSource) {
      return getNodeNetworkData(
        { ...dataSource.entity, ...dataSource.entity_info, entity_type: 'data_source' },
        {
          parents: dataSourceLinks?.parents,
          children: dataSourceLinks?.children.map((parent) => ({
            ...parent,
            entity_type: parent.configuration?.data_unit_type === 'data_product' ? 'data_product' : parent.entity_type,
          })),
        },
      );
    }
    return { nodes: [], edges: [] };
  }, [dataSource, dataSourceLinks]);

  const handleNodeClick = useCallback(
    (event) => {
      const clickedNodeId = event.nodes[0];
      const clickedNode = networkData.nodes.find((node) => node.identifier === clickedNodeId);
      if (clickedNode) {
        const nodeType = clickedNode.output_type || clickedNode.entity_type;
        const nodeTypeSlug = nodeType.replaceAll('_', '-');

        if (clickedNode.subscribed) {
          navigate(`/${nodeTypeSlug}/sub/details/${clickedNode.id}`);
        } else {
          navigate(`/${nodeTypeSlug}/details/${clickedNode.id}`);
        }
      }
    },
    [navigate, networkData?.nodes],
  );

  const toggleLandscapeView = useCallback(() => {
    const newLandScapeView = !isShowLandscapeView;
    setIsShowLandscapeView(newLandScapeView);
    localStorage.setItem('landscapeView', newLandScapeView ? 'true' : 'false');
  }, [isShowLandscapeView]);

  return (
    <Box marginBottom="64px">
      <Card2
        title="Connections"
        bodyStyle={{ padding: 0 }}
        headerActions={
          <Button icon={<Icon name={isShowLandscapeView ? 'eye-slash' : 'eye'} />} onClick={toggleLandscapeView}>
            {isShowLandscapeView ? 'Hide landscape view' : 'Show landscape view'}
          </Button>
        }
      >
        {isShowLandscapeView && (
          <Box width="100%" style={{ padding: '1px' }}>
            <VisNetwork
              data={{ nodes: networkData.nodes, edges: networkData.edges }}
              options={NETWORK_OPTIONS}
              style={{ height: '400px', width: '100%' }}
              isLoading={isDataSourceLinksLoading}
              getNetwork={setNetwork}
              events={{
                selectNode: handleNodeClick,
                hoverNode: () => {
                  network.canvas.body.container.style.cursor = 'pointer';
                },
                blurNode: () => {
                  network.canvas.body.container.style.cursor = 'default';
                },
              }}
            />
          </Box>
        )}

        <Table2
          records={dataSourceLinks?.parents?.map((dataSystem) => ({
            name: (
              <Stack direction="row" alignItems="center" gap={1}>
                <EntityShape size="s" type="data_system" data={dataSystem} />
                <Link to={`/data-system/details/${dataSystem.identifier}`}>{dataSystem.name}</Link>
              </Stack>
            ),
            owner: <Text disableGutter>{dataSystem.owner || '-'}</Text>,
            actions: (
              <Button intent="primary" outlined onClick={() => setDataSystemToUnlink(dataSystem)}>
                Remove link
              </Button>
            ),
          }))}
          headerColumns={dataSystemHeaderColumns}
          showEmptyTable
        />

        {!dataSourceLinks.parents.length && (
          <Button
            icon={<Icon name="square-plus" />}
            intent="primary"
            minimal
            onClick={() => setIsAddDataSystem(true)}
            disabled={isAddDataSystem}
            style={{ margin: '8px' }}
          >
            Add data system
          </Button>
        )}

        {isAddDataSystem && (
          <EntityAddTabs
            sourceEntityId={dataSource?.entity?.identifier}
            sourceEntityType="data_source"
            targetLinkType="parent"
            onClose={() => setIsAddDataSystem(false)}
            queriesToInvalidateOnAdd={[['dataSourceLinks', dataSource?.entity?.identifier]]}
          />
        )}

        <Table2
          records={[
            {
              name: (
                <Stack direction="row" alignItems="center" gap={1}>
                  <EntityShape
                    size="s"
                    type="data_source"
                    data={{ ...dataSource?.entity, ...dataSource?.entity_info }}
                  />
                  <Text disableGutter>{dataSource?.entity?.name}</Text>
                </Stack>
              ),
              owner: <Text disableGutter>{dataSource?.entity_info?.owner || '--'}</Text>,
              status: (
                <EntityStatus
                  entityType="data_source"
                  entityId={dataSource?.entity?.identifier}
                  entityState={dataSource?.entity?.state}
                />
              ),
              actions: (
                <Tooltip condition={dataSource?.entity?.is_system} content="Can't edit system created entity.">
                  <AnchorButton
                    intent={dataSource?.connection ? 'primary' : 'success'}
                    onClick={() => setIsConnectDataSource(true)}
                    disabled={dataSource?.entity?.is_system || isConnectDataSource}
                  >
                    {dataSource?.connection ? 'Edit' : 'Authorize'}
                  </AnchorButton>
                </Tooltip>
              ),
            },
          ]}
          headerColumns={dataSourceHeaderColumns}
          showBorderTop
        />

        {isConnectDataSource && <DataSourceConnection onClose={handleConnectDataSourceClose} />}
        <Table2
          records={dataSourceLinks?.children?.map((dataUnit) => ({
            name: (
              <Stack direction="row" alignItems="center" gap={1}>
                <EntityShape
                  size="s"
                  type={
                    dataUnit?.configuration?.data_unit_type === 'data_product' ? 'data_product' : dataUnit.entity_type
                  }
                  data={dataUnit}
                />
                <Link
                  to={
                    dataUnit.subscribed
                      ? `/data-product/sub/details/${dataUnit.identifier}`
                      : `/${dataUnit?.entity_type.replaceAll('_', '-')}/details/${dataUnit.identifier}`
                  }
                >
                  {dataUnit.name}
                </Link>
              </Stack>
            ),
            owner: <Text disableGutter>{dataUnit.owner || '--'}</Text>,
            status: <EntityStatus entityType="data_unit" entityId={dataUnit.identifier} entityState={dataUnit.state} />,
            actions: (
              <Button intent="primary" outlined onClick={() => setDataUnitToUnlink(dataUnit)}>
                Remove link
              </Button>
            ),
          }))}
          headerColumns={dataUnitHeaderColumns}
          showEmptyTable
          showBorderTop
          showRowsDivider
        />

        {dataSourceLinks?.children?.length > 0 && <Divider />}

        <Button
          icon={<Icon name="square-plus" />}
          intent="primary"
          minimal
          onClick={() => setIsAddDataUnit(true)}
          disabled={isAddDataUnit}
          style={{ margin: '8px' }}
        >
          Add data unit
        </Button>

        {isAddDataUnit && (
          <EntityAddTabs
            sourceEntityId={dataSource?.entity?.identifier}
            sourceEntityType="data_source"
            targetLinkType="child"
            canLinkToMultipleParents={false}
            onClose={() => setIsAddDataUnit(false)}
            queriesToInvalidateOnAdd={[['dataSourceLinks', dataSource?.entity?.identifier]]}
          />
        )}
      </Card2>

      <DeleteConfirmationAlert
        isOpen={!!dataSystemToUnlink}
        heading="Are you sure you want to remove linking?"
        description={
          <Text disableGutter>
            Removing the linking between entities may break the data flow.
            <br />
            It does not remove the entity.
          </Text>
        }
        onConfirm={unlinkDataSystem}
        onCancel={() => setDataSystemToUnlink(null)}
        isLoading={isUnlinkDataSystemLoading}
      />

      <DeleteConfirmationAlert
        isOpen={!!dataUnitToUnlink}
        heading="Are you sure you want to remove linking?"
        description={
          <Text disableGutter>
            Removing the linking between entities may break the data flow.
            <br />
            It does not remove the entity.
          </Text>
        }
        onConfirm={unlinkDataUnit}
        onCancel={() => setDataUnitToUnlink(null)}
        isLoading={isUnlinkDataUnitLoading}
      />
    </Box>
  );
};

export default DataSourceConnectionsTab;
