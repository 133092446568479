import PropTypes from 'prop-types';

import Stack from './Stack';
import Text from './Text';
import Spinner from './Spinner';

import './TextValue.scss';

const TextValue = ({ left, right, isLoading, style }) => (
  <Stack
    direction="column"
    gap={1}
    style={{
      width: '100%',
      ...style,
    }}
  >
    <Text className="text-value-label" small disableGutter>
      {left}
    </Text>
    {isLoading ? (
      <Spinner size={14} />
    ) : (
      <Text style={{ flex: '1', wordBreak: 'break-word' }} disableGutter>
        {right}
      </Text>
    )}
  </Stack>
);

TextValue.propTypes = {
  left: PropTypes.string.isRequired,
  right: PropTypes.node.isRequired,
  isLoading: PropTypes.bool,
  style: PropTypes.object,
};

TextValue.defaultProps = {
  isLoading: false,
  style: {},
};

export default TextValue;
