import { Button } from '@blueprintjs/core';
import PropTypes from 'prop-types';

import underConstructionImgUrl from '../../assets/images/under_construction.svg';
import Box from '../Box';
import Stack from '../Stack';
import Text from '../Text';
import './ErrorBoundaryPage.scss';

const ErrorBoundaryPage = ({ resetErrorBoundary }) => {
  return (
    <div className="container">
      <Stack direction="column" height="100vh" alignItems="center" justifyContent="center">
        <img src={underConstructionImgUrl} alt="Something went wrong" />
        <Text tagName="h2">Oops... something went wrong</Text>

        <Box mt={4} mb={4}>
          <Button large onClick={resetErrorBoundary}>
            Refresh NEOS app
          </Button>
        </Box>
      </Stack>
    </div>
  );
};

ErrorBoundaryPage.propTypes = {
  resetErrorBoundary: PropTypes.func.isRequired,
};

export default ErrorBoundaryPage;
