import PropTypes from 'prop-types';

import { spacingUnit } from '../constants';

const Stack = ({
  children,
  containerProps,
  direction,
  alignItems,
  justifyContent,
  gap,
  className,
  m,
  mt,
  mr,
  mb,
  ml,
  mx,
  my,
  p,
  pt,
  pr,
  pb,
  pl,
  px,
  py,
  style,
  ...rest
}) => {
  const marginsProps = {
    ...(m && { margin: m * spacingUnit }),
    ...(mt && { marginTop: mt * spacingUnit }),
    ...(mr && { marginRight: mr * spacingUnit }),
    ...(mb && { marginBottom: mb * spacingUnit }),
    ...(ml && { marginLeft: ml * spacingUnit }),
    ...(mx && { marginLeft: mx * spacingUnit, marginRight: mx * spacingUnit }),
    ...(my && { marginTop: my * spacingUnit, marginBottom: my * spacingUnit }),
  };

  const paddingProps = {
    ...(p && { padding: p * spacingUnit }),
    ...(pt && { paddingTop: pt * spacingUnit }),
    ...(pr && { paddingRight: pr * spacingUnit }),
    ...(pb && { paddingBottom: pb * spacingUnit }),
    ...(pl && { paddingLeft: pl * spacingUnit }),
    ...(px && { paddingLeft: px * spacingUnit, paddingRight: px * spacingUnit }),
    ...(py && { paddingTop: py * spacingUnit, paddingBottom: py * spacingUnit }),
  };

  return (
    <div
      {...containerProps}
      className={className}
      style={{
        display: 'flex',
        flexDirection: direction,
        justifyContent,
        alignItems,
        gap: typeof gap === 'string' ? gap : gap * spacingUnit,
        ...marginsProps,
        ...paddingProps,
        ...style,
        ...rest,
      }}
    >
      {children}
    </div>
  );
};

Stack.propTypes = {
  children: PropTypes.node,
  containerProps: PropTypes.object,
  direction: PropTypes.oneOf(['row', 'column']),
  gap: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  alignItems: PropTypes.string,
  justifyContent: PropTypes.string,
  className: PropTypes.string,
  m: PropTypes.number,
  mt: PropTypes.number,
  mr: PropTypes.number,
  mb: PropTypes.number,
  ml: PropTypes.number,
  p: PropTypes.number,
  pt: PropTypes.number,
  pr: PropTypes.number,
  pb: PropTypes.number,
  pl: PropTypes.number,
  my: PropTypes.number,
  mx: PropTypes.number,
  py: PropTypes.number,
  px: PropTypes.number,
  style: PropTypes.object,
};

Stack.defaultProps = {
  children: null,
  containerProps: {},
  direction: 'column',
  className: '',
  gap: '0px',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  style: {},
};

export default Stack;
