import PropTypes from 'prop-types';
import { useEffect } from 'react';

import Box from '../../../../../../../components/Box';
import Stack from '../../../../../../../components/Stack';
import Select from '../../../../../../../components/form/Select';
import TextInput from '../../../../../../../components/form/TextInput';
import yup from '../../../../../../../utils/validator';

const databaseTypes = [
  {
    label: 'PostgreSQL',
    value: 'postgresql',
  },
  {
    label: 'MySQL',
    value: 'mysql',
  },
];

const DataSourceDatabaseConnection = ({ formik, setValidationSchema }) => {
  useEffect(() => {
    setValidationSchema((currentSchema) => {
      const { connection_type } = currentSchema;

      return {
        connection_type,
        user: yup.object({
          env_key: yup.string().required(),
        }),
        password: yup.object({
          env_key: yup.string().required(),
        }),
        database: yup.string().required(),
        engine: yup.string().required(),
        host: yup.string().required(),
        schema: yup.string().required(),
        port: yup
          .number()
          .typeError('Numbers only.')
          .integer('Integers only.')
          .min(0, 'Min is 0')
          .max(65535, 'Max is 65535')
          .required(),
      };
    });

    return () => {
      setValidationSchema((currentSchema) => {
        const { connection_type } = currentSchema;
        return { connection_type };
      });
    };
  }, [setValidationSchema]);

  return (
    <Stack gap={2} width="100%">
      <Stack direction="row" width="100%" gap={1}>
        <TextInput id="database" label="Database name" formik={formik} />
        <Select fill id="engine" label="Database type" items={databaseTypes} formik={formik} showEmptyOption={false} />

        <TextInput id="host" label="Host" formik={formik} />
        <TextInput
          id="port"
          label="Port"
          formik={formik}
          containerProps={{ style: { minWidth: '100px', maxWidth: '100px' } }}
        />
      </Stack>

      <Stack direction="row" width="100%" gap={1}>
        <TextInput id="user.env_key" label="Username" formik={formik} />
        <TextInput id="password.env_key" label="Password" formik={formik} />
        <TextInput id="schema" label="Schema" formik={formik} />
        <Box minWidth="100px" />
      </Stack>
    </Stack>
  );
};

DataSourceDatabaseConnection.propTypes = {
  formik: PropTypes.object.isRequired,
  setValidationSchema: PropTypes.func.isRequired,
};

export default DataSourceDatabaseConnection;
