/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import 'normalize.css/normalize.css';
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import '@blueprintjs/select/lib/css/blueprint-select.css';
import '@blueprintjs/table/lib/css/table.css';

import 'react-day-picker/dist/style.css';

import App from './App';
import queryClient from './services/queryClient';
import './services/interceptor';
import './index.scss';
import './assets/fontawesome/css/fontawesome.css';
import './assets/fontawesome/css/duotone.css';
import ErrorBoundary from './components/ErrorBoundary';
import ErrorBoundaryPage from './components/ErrorPages/ErrorBoundaryPage';

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <ErrorBoundary
          FallbackComponent={ErrorBoundaryPage}
          onError={(err) => {
            // eslint-disable-next-line no-console
            console.error(err);
          }}
        >
          <App />
        </ErrorBoundary>
      </BrowserRouter>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </React.StrictMode>,
);
