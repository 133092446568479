import { Button, AnchorButton } from '@blueprintjs/core';
import { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';

import Dialog from '../../../../../../components/Dialog';
import Stack from '../../../../../../components/Stack';
import gatewayApi from '../../../../../../services/gatewayApi';
import Text from '../../../../../../components/Text';
import { useDataUnitStore } from '../../../../../../store/dataUnitStore';
import queryClient from '../../../../../../services/queryClient';
import Tooltip from '../../../../../../components/Tooltip';
import Card2 from '../../../../../../components/Card2';

const DataUnitActionsTab = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { dataUnit } = useDataUnitStore();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const isSystemCreated = dataUnit?.entity?.is_system;

  const { isLoading: isDeleteDataUnitLoading, mutateAsync: deleteDataUnit } = useMutation(
    async () => {
      return gatewayApi.delete(`/data_unit/${params.id}`);
    },
    {
      onSuccess: () => {
        navigate('/data-landscape?type=data_unit');
      },
    },
  );

  const isDataUnitLinked =
    queryClient.getQueryData(['entityLinks', dataUnit?.entity?.identifier])?.children?.length > 0;

  return (
    <>
      <Card2 title="Remove Data Unit">
        <Stack gap={2}>
          <Text disableGutter>
            Removing <strong>{dataUnit?.entity?.name}</strong> will remove it from your data landscape and all
            information within it.
          </Text>

          <Tooltip
            condition={isSystemCreated || isDataUnitLinked}
            content={
              isSystemCreated
                ? "Can't delete system created entity."
                : "Can't delete Data Unit that has connected Data Products"
            }
          >
            <AnchorButton onClick={() => setIsDeleteDialogOpen(true)} disabled={isSystemCreated || isDataUnitLinked}>
              Remove Data Unit
            </AnchorButton>
          </Tooltip>
        </Stack>
      </Card2>

      <Dialog
        title="Remove?"
        icon={null}
        isOpen={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
        actions={
          <>
            <Button onClick={() => setIsDeleteDialogOpen(false)}>Cancel</Button>
            <Button intent="danger" onClick={deleteDataUnit} loading={isDeleteDataUnitLoading}>
              Remove
            </Button>
          </>
        }
      >
        <Text>
          Are you sure you want to remove <strong>{dataUnit?.entity?.name}</strong>?
        </Text>
        <Text disableGutter>All connections & data will be removed from data landscape.</Text>
      </Dialog>
    </>
  );
};

export default DataUnitActionsTab;
