import { Button, Colors, Divider } from '@blueprintjs/core';
import { useQuery } from '@tanstack/react-query';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';

import gatewayApi from '../../../services/gatewayApi';
import queryClient from '../../../services/queryClient';
import yup from '../../../utils/validator';
import Box from '../../Box';
import Spinner from '../../Spinner';
import Stack from '../../Stack';
import Text from '../../Text';
import { toast } from '../../Toaster/Toaster';
import ErrorText from '../../form/ErrorText';
import { getApiErrorMessage } from '../../../utils/functions';
import {
  ENTITIES_METADATA_MAP,
  ENTITIES_RELATION_METADATA_MAP,
  ENTITY_TYPE_TO_API_ENTITY_TYPE_MAP,
} from '../constants';
import { getLinkingApiUrl } from '../utils';

import EntityAddLinkItem from './EntityAddLinkItem';

const EntityAddLink = ({
  sourceEntityId,
  sourceEntityType,
  targetLinkType,
  canLinkToMultipleParents,
  onClose,
  queriesToInvalidateOnAdd,
  filterValues,
}) => {
  const { search, listEntityType } = filterValues;
  const [linkableEntities, setLinkableEntities] = useState([]);

  const targetEntityTypes = ENTITIES_RELATION_METADATA_MAP[sourceEntityType][targetLinkType];
  const targetEntityAllListQueryName = targetEntityTypes
    .map((type) => ENTITIES_METADATA_MAP[type].listQueryName)
    .join('');

  const formik = useFormik({
    initialValues: {
      targetEntityId: '',
    },
    validationSchema: yup.object({
      targetEntityId: yup.string().required(),
    }),
    onSubmit: async ({ targetEntityId }) => {
      const targetEntityType = linkableEntities.find((entity) => entity.identifier === targetEntityId).entity_type;
      try {
        await gatewayApi.post(
          getLinkingApiUrl({
            sourceEntityType,
            sourceEntityId,
            targetEntityType,
            targetEntityId,
            targetLinkType,
          }),
        );
        for await (const query of queriesToInvalidateOnAdd) {
          await queryClient.invalidateQueries(query);
        }
        await queryClient.invalidateQueries(['entityLinks', sourceEntityId]);
        onClose();
      } catch (err) {
        toast.error(getApiErrorMessage(err?.response?.data));
      }
    },
  });

  const { data: sourceEntityLinks } = useQuery(['entityLinks', sourceEntityId], async () => {
    const res = await gatewayApi.get(`/${ENTITY_TYPE_TO_API_ENTITY_TYPE_MAP[sourceEntityType]}/${sourceEntityId}/link`);
    return res.data;
  });

  const { data: targetEntities, isLoading: isTargetEntitiesLoading } = useQuery(
    ['entitiesToLink', ENTITIES_METADATA_MAP[listEntityType]?.listQueryName || targetEntityAllListQueryName],
    async () => {
      const entityTypesTemp = [
        ...new Set(targetEntityTypes.map((entityType) => ENTITY_TYPE_TO_API_ENTITY_TYPE_MAP[entityType])),
      ];

      const requests = entityTypesTemp.map((entityType) => {
        const params =
          canLinkToMultipleParents !== undefined
            ? {
                params: {
                  has_parent: canLinkToMultipleParents,
                },
              }
            : {};
        return gatewayApi.get(`/${entityType}`, params);
      });
      const responses = await Promise.all(requests);

      return responses
        .map((res, index) =>
          res.data.entities
            .filter((entity) => entity.output_type !== 'dashboard')
            .map((entity) => ({
              entity_type: entity.output_type || targetEntityTypes[index],
              ...entity,
            })),
        )
        .flat()
        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    },
    {
      enabled: !!sourceEntityLinks,
      onSuccess: (linkableData) => {
        setLinkableEntities(linkableData);
      },
      onError: (err) => {
        toast.error(getApiErrorMessage(err?.response?.data));
      },
    },
  );

  const entitiesOptions = useMemo(() => {
    return targetEntities?.filter((entity) => {
      const isNotSourceEntity = entity.identifier !== sourceEntityId;
      const matchesEntityType = listEntityType === 'all' || listEntityType === entity.entity_type;
      const isNotChildOfSourceEntity = !sourceEntityLinks.children.find(
        (child) => child.identifier === entity.identifier,
      );
      const isNotParentOfSourceEntity = !sourceEntityLinks.parents.find(
        (child) => child.identifier === entity.identifier,
      );

      const matchesSearchQuery = [entity.name, entity.label, entity.owner].some((prop) =>
        prop?.toLowerCase().includes(search.toLowerCase()),
      );

      return (
        isNotSourceEntity &&
        matchesEntityType &&
        isNotChildOfSourceEntity &&
        isNotParentOfSourceEntity &&
        matchesSearchQuery
      );
    });
  }, [listEntityType, search, targetEntities, sourceEntityId, sourceEntityLinks]);

  return (
    <Box width="100%">
      <Box px={1}>
        <Box maxHeight="400px">
          {isTargetEntitiesLoading ? (
            <Spinner />
          ) : (
            <Stack
              direction="row"
              justifyContent="space-between"
              style={{
                flexWrap: 'wrap',
                maxHeight: '400px',
                overflowY: 'auto',
                '::-webkit-scrollbar': {
                  width: '1px',
                },
              }}
              gap={1}
              py={1}
            >
              {entitiesOptions.length > 0 ? (
                entitiesOptions.map((entity) => (
                  <EntityAddLinkItem
                    key={entity.identifier}
                    formId="targetEntityId"
                    formik={formik}
                    entityType={entity.entity_type}
                    {...entity}
                  />
                ))
              ) : (
                <Text disableGutter color={Colors.GRAY3}>
                  No results found.
                </Text>
              )}
            </Stack>
          )}
          {formik.touched.targetEntityId && formik.errors.targetEntityId && (
            <ErrorText>{formik.errors.targetEntityId}</ErrorText>
          )}
        </Box>
      </Box>

      <Divider />

      <Stack direction="row" justifyContent="flex-end" gap={1} p={1}>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          intent="primary"
          onClick={formik.handleSubmit}
          loading={formik.isSubmitting}
          disabled={isTargetEntitiesLoading}
        >
          Add
        </Button>
      </Stack>
    </Box>
  );
};

EntityAddLink.propTypes = {
  sourceEntityType: PropTypes.string.isRequired,
  sourceEntityId: PropTypes.string.isRequired,
  targetLinkType: PropTypes.oneOf(['child', 'parent']).isRequired,
  onClose: PropTypes.func.isRequired,
  canLinkToMultipleParents: PropTypes.bool,
  queriesToInvalidateOnAdd: PropTypes.array,
  filterValues: PropTypes.shape({
    search: PropTypes.string,
    listEntityType: PropTypes.string,
  }).isRequired,
};

EntityAddLink.defaultProps = {
  canLinkToMultipleParents: undefined,
  queriesToInvalidateOnAdd: [],
};

export default EntityAddLink;
