import PropTypes from 'prop-types';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { DataSet } from 'vis-data/standalone';
import { Network } from 'vis-network/standalone';

import useUpdateEffect from '../hooks/useUpdateEffect';
import { useUserStore } from '../store/userStore';
import useSingleEffect from '../hooks/useSingleEffect';
import ballImg from '../assets/images/purpleDot.svg';

import Box from './Box';
import Spinner from './Spinner';
import NetworkPopover from './NetworkPopover';

const defaultOptions = {
  physics: false,
  nodes: {
    shape: 'circle',
    size: 30,
    widthConstraint: 30,
    borderWidth: 2,
    borderWidthSelected: 2,
    chosen: false,
    font: {
      size: 16,
    },
  },
  edges: {
    smooth: {
      enabled: true,
      type: 'cubicBezier',
      forceDirection: 'horizontal',
      roundness: 0,
    },
    color: '#9582B4',
    width: 2,
  },
  interaction: {
    zoomSpeed: 0.4,
    navigationButtons: false,
    hover: true,
  },
  layout: {
    hierarchical: {
      direction: 'LR',
      sortMethod: 'directed',
    },
  },
};

const OFFSET_BORDER = 0.15;
const MAX_OFFSET = 1 - OFFSET_BORDER;
const MIN_OFFSET = 0 + OFFSET_BORDER;
const ANIMATION_TIME = 10000;
const ANIMATION_BALL_DELAY = 1000;
const ANIMATION_BALLS_COUNT = 100;
const BALL_SIZE = 8;

const ballCanvasImg = new Image();
ballCanvasImg.src = ballImg;

const VisNetwork = memo(
  ({
    data,
    options,
    events,
    style,
    getNetwork,
    getNodes,
    getEdges,
    popoverContentComponent: PopoverContentComponent,
    popoverContentProps,
    isLoading,
  }) => {
    const { isDarkTheme } = useUserStore();
    const nodes = useRef(new DataSet(data?.nodes || []));
    const edges = useRef(new DataSet(data?.edges || []));
    const network = useRef(null);
    const container = useRef(null);
    const [isDragActive, setIsDragActive] = useState(false);
    const animationStartTime = useRef(Date.now());
    const edgeAnimationFrameId = useRef(null);

    const getNode = useCallback((nodeId) => nodes.current.map((n) => n).find((node) => node.id === nodeId), []);

    const drawImageBall = useCallback(({ ctx, edge, nextOffsetTick, nextOffsetValue }) => {
      const p = edge.edgeType.getPoint(nextOffsetValue);
      const { x, y } = network.current.canvas.canvasToDOM({ x: p.x, y: p.y });

      const scale = network.current.canvas.body.view.scale;
      const ballScaleSize = scale < 0 ? BALL_SIZE : BALL_SIZE * scale;

      if (nextOffsetTick < MAX_OFFSET && nextOffsetTick > MIN_OFFSET) {
        ctx.beginPath();
        ctx.drawImage(ballCanvasImg, x, y - 4, ballScaleSize, ballScaleSize);
        ctx.closePath();
      }
    }, []);

    const drawAnimation = useCallback(
      async (ctx) => {
        network.current.body.emitter.emit('_redraw', ctx);

        const animatedEdges = [];

        Object.values(network.current?.body?.edges || {}).forEach((edge) => {
          const isEdgeFromHealthy = getNode(edge.from.id)?.state?.healthy;
          const isEdgeToHealthy = getNode(edge.to.id)?.state?.healthy;
          if (isEdgeFromHealthy && isEdgeToHealthy) {
            animatedEdges.push(edge);
          }
        });

        const now = Date.now();
        const elapsedMs = now - animationStartTime.current;

        if (elapsedMs >= ANIMATION_BALLS_COUNT * 1000 + ANIMATION_TIME - OFFSET_BORDER * 10) {
          animationStartTime.current = Date.now();
        }

        for (let i = 0; i < animatedEdges.length; i++) {
          const edge = animatedEdges[i];

          Array(ANIMATION_BALLS_COUNT)
            .fill(null)
            .map((_, index) => index * ANIMATION_BALL_DELAY)
            .forEach((tickDelayMs) => {
              const nextOffsetTick = (elapsedMs - tickDelayMs) / ANIMATION_TIME;
              const nextOffsetValue = nextOffsetTick > MAX_OFFSET ? MIN_OFFSET : nextOffsetTick;

              // drawImageBall({
              //   ctx,
              //   edge,
              //   ballCanvasImg,
              //   nextOffsetTick,
              //   nextOffsetValue,
              // });
            });
        }

        edgeAnimationFrameId.current = window.requestAnimationFrame(() => drawAnimation(ctx));
      },
      [drawImageBall, getNode],
    );

    useSingleEffect(() => {
      network.current = new Network(
        container.current,
        { nodes: nodes.current, edges: edges.current },
        { ...defaultOptions, ...options },
      );

      network.current.once('beforeDrawing', (ctx) => {
        ctx.save();
        // reset transform to identity
        ctx.setTransform(1, 0, 0, 1, 0, 0);

        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
        ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);
        ctx.restore();
      });

      network.current.once('afterDrawing', (ctx) => {
        animationStartTime.current = Date.now();
        edgeAnimationFrameId.current = window.requestAnimationFrame(() => drawAnimation(ctx));
      });

      network.current.on('dragStart', () => {
        setIsDragActive(true);
      });

      network.current.on('dragEnd', () => {
        setIsDragActive(false);
      });

      if (getNetwork) {
        getNetwork(network.current);
      }

      if (getNodes) {
        getNodes(nodes.current);
      }

      if (getEdges) {
        getEdges(edges.current);
      }
    });

    useUpdateEffect(() => {
      nodes.current.update(data?.nodes || []);
      edges.current.update(data?.edges || []);

      // If length of new data.nodes is lower than length of current nodes (nodes.current.get()), remove the extra nodes
      if (data?.nodes?.length < nodes.current.get().length) {
        const nodesToRemove = nodes.current.get().filter((node) => !data.nodes.find((n) => n.id === node.id));
        nodes.current.remove(nodesToRemove);
      }

      // Do same for the edges
      if (data?.edges?.length < edges.current.get().length) {
        const edgesToRemove = edges.current.get().filter((edge) => !data.edges.find((e) => e.id === edge.id));
        edges.current.remove(edgesToRemove);
      }

      // NOTE: Leave this commented. And re-check if it's needed.
      // if (network.current?.physics?.physicsEnabled) {
      //   network.current.stabilize(1000);
      // }
    }, [data]);

    useEffect(() => {
      network.current.setOptions({ ...defaultOptions, ...options });

      return () => {
        window.cancelAnimationFrame(edgeAnimationFrameId.current);
      };
    }, [options]);

    const eventHandler = useCallback(
      (eventName, visEvent) => {
        if (visEvent.event.type === 'press') {
          return;
        }

        events[eventName](visEvent);
      },
      [events],
    );

    useEffect(() => {
      // Add user provied events to network
      const eventHandlerRefs = {};

      for (const eventName of Object.keys(events)) {
        eventHandlerRefs[eventName] = eventHandler.bind(this, eventName);
        network.current.on(eventName, eventHandlerRefs[eventName]);
      }

      return () => {
        for (const eventName of Object.keys(events)) {
          network.current.off(eventName, eventHandlerRefs[eventName]);
        }
      };
    }, [eventHandler, events]);

    return (
      <Box
        style={{
          width: '100%',
          height: '100%',
          position: 'relative',
          backgroundColor: isDarkTheme() ? '#111418' : '#F6F7F9',
        }}
      >
        <div
          ref={container}
          style={{
            pointerEvents: isLoading ? 'none' : 'all',
            opacity: isLoading ? 0.4 : 1,
            width: '100%',
            height: '100%',
            ...style,
          }}
        />

        {isLoading && <Spinner centered />}

        {PopoverContentComponent && !isDragActive && network.current && nodes.current && (
          <NetworkPopover
            network={network}
            nodes={nodes}
            popoverContentComponent={PopoverContentComponent}
            popoverContentProps={popoverContentProps}
          />
        )}
      </Box>
    );
  },
);

VisNetwork.propTypes = {
  data: PropTypes.object,
  options: PropTypes.object,
  events: PropTypes.object,
  style: PropTypes.object,
  getNetwork: PropTypes.func,
  getNodes: PropTypes.func,
  getEdges: PropTypes.func,
  popoverContentComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  popoverContentProps: PropTypes.object,
  isLoading: PropTypes.bool,
};

VisNetwork.defaultProps = {
  options: {},
  events: {},
  style: {},
  getNetwork: undefined,
  getNodes: undefined,
  getEdges: undefined,
  popoverContentComponent: null,
  popoverContentProps: {},
  isLoading: false,
};

export default VisNetwork;
