import { useMemo, useCallback, useState } from 'react';
import { Button, Colors, Divider, Drawer } from '@blueprintjs/core';
import dayjs from 'dayjs';
import classNames from 'classnames';

import { useRegistryStore } from '../../../store/registry';
import gatewayApi from '../../../services/gatewayApi';
import useSingleEffect from '../../../hooks/useSingleEffect';
import { getTimeCalendarFormat } from '../../../utils/functions';
import Box from '../../Box';
import Stack from '../../Stack';
import Text from '../../Text';
import Icon from '../../Icon';

import './Notifications.scss';

const DRAWER_HEADING_HEIGHT = 55;

const Notifications = () => {
  const [isActivityOpen, setIsActivityOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [notificationData, setNotificationData] = useState([]);

  const unreadNotifications = useMemo(() => {
    return (
      notifications.filter(({ id }) => !notificationData.find((notification) => notification.id === id)?.seen).length ||
      0
    );
  }, [notificationData, notifications]);

  useSingleEffect(() => {
    useRegistryStore.subscribe(
      (state) => state.gatewayApi,
      async (newGatewayApi) => {
        const ticketRes = await gatewayApi.get('/notification/ticket');
        const strippedGatewayApi = newGatewayApi.replace(/(^\w+:|^)\/\//, '');
        const socket = new WebSocket(
          `wss://${strippedGatewayApi}/notification/ws?ticket_id=${ticketRes.data.ticket_id}&user_id=${ticketRes.data.user_id}`,
        );
        socket.onmessage = (event) => {
          setNotifications((prevState) => {
            const prevEvents = [...prevState, JSON.parse(event.data)];
            prevEvents.sort((a, b) => dayjs(b.message.event_timestamp).diff(dayjs(a.message.event_timestamp)));
            return prevEvents;
          });
        };
      },
      {
        fireImmediately: true,
      },
    );
  });

  const handleNotificationSeenToggle = useCallback(
    (notificationId) => {
      const tempNotificationData = [...notificationData];
      const notificationIndex = tempNotificationData.findIndex((notification) => notification.id === notificationId);

      if (notificationIndex >= 0) {
        tempNotificationData[notificationIndex].seen = !tempNotificationData[notificationIndex]?.seen;
      } else {
        const notification = notifications.find(({ id }) => id === notificationId);
        tempNotificationData.push({
          ...notification,
          seen: true,
        });
      }

      setNotificationData(tempNotificationData);
    },
    [notificationData, notifications],
  );

  const handleMarkAllSeen = useCallback(() => {
    const tempNotificationData = notifications.map((notification) => ({
      id: notification.id,
      seen: true,
    }));
    setNotificationData(tempNotificationData);
  }, [notifications]);

  const handleOnClose = useCallback(() => {
    setIsActivityOpen(false);

    const unseenNotificationIds = notificationData.filter(({ seen }) => !seen).map(({ id }) => id);
    const seenNotificationIds = notificationData.filter(({ seen }) => seen).map(({ id }) => id);

    unseenNotificationIds.forEach((notificationId) => {
      gatewayApi.put(`/notification/${notificationId}/unseen`);
    });

    seenNotificationIds.forEach((notificationId) => {
      gatewayApi.put(`/notification/${notificationId}/seen`);
    });
  }, [notificationData]);

  return (
    <>
      <Button
        icon={
          <Box style={{ position: 'relative' }}>
            {unreadNotifications > 0 && <Box className="notification-badge">{unreadNotifications}</Box>}
            <Icon name="bell" />
          </Box>
        }
        minimal
        onClick={() => setIsActivityOpen((prevState) => !prevState)}
        style={{
          background: 'transparent',
        }}
      >
        Activity
      </Button>
      <Drawer
        position="right"
        isOpen={isActivityOpen}
        size="440px"
        hasBackdrop={false}
        autoFocus={false}
        onClose={handleOnClose}
      >
        <Box>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            px={2}
            py={1.5}
            style={{ height: DRAWER_HEADING_HEIGHT }}
          >
            <Text disableGutter>Activities</Text>
            <Button
              rightIcon={<Icon name="check" />}
              minimal
              disabled={
                !notifications.length ||
                notificationData.filter((notification) => notification.seen).length === notifications.length
              }
              onClick={handleMarkAllSeen}
            >
              Mark all as read
            </Button>
          </Stack>
          <Divider />
          <Stack
            direction="column"
            style={{
              overflowY: 'auto',
              height: `calc(100vh - ${DRAWER_HEADING_HEIGHT}px)`,
            }}
          >
            {!notifications.length ? (
              <Text disableGutter style={{ textAlign: 'center', width: '100%', padding: '16px' }} color={Colors.GRAY3}>
                No activities
              </Text>
            ) : (
              notifications.map(({ id, message }) => (
                <Box
                  key={id}
                  className={classNames('notification-item', {
                    'notification-item--seen': notificationData.find((notification) => notification.id === id)?.seen,
                  })}
                  containerProps={{
                    onClick: () => handleNotificationSeenToggle(id),
                  }}
                >
                  <Stack direction="column" gap={1} px={2} py={1}>
                    <Text disableGutter>{message.message}</Text>
                    <Text disableGutter color={Colors.GRAY3}>
                      {dayjs(message.event_timestamp).calendar(dayjs(), getTimeCalendarFormat(message.event_timestamp))}
                    </Text>
                  </Stack>
                  <Divider />
                </Box>
              ))
            )}
          </Stack>
        </Box>
      </Drawer>
    </>
  );
};

export default Notifications;
