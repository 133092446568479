import { TextArea as BlueprintTextArea } from '@blueprintjs/core';
import PropTypes from 'prop-types';
import { useCallback } from 'react';

import { getNestedPropertyValue } from '../../utils/functions';
import Box from '../Box';

import InputHelperLabel from './InputHelperLabel';
import InputLabel from './InputLabel';
import { CustomInputGroup } from './CustomInputGroup';
import ErrorIconTooltip from './ErrorIconTooltip';

const TextArea = ({
  id,
  formik,
  label,
  fill,
  placeholder,
  readOnly,
  growVertically,
  inputRef,
  intent,
  large,
  disabled,
  className,
  small,
  helperText,
  labelStyle,
  tooltipContent,
  tooltipProps,
  errorTextStyle,
  ...rest
}) => {
  const getPropValue = useCallback((path, object) => getNestedPropertyValue(path, object), []);
  const isError = getPropValue(id, formik.touched) && Boolean(getPropValue(id, formik.errors));

  return (
    <Box position="relative" width="100%" pb={0.5}>
      <InputLabel
        id={id}
        label={label}
        labelStyle={labelStyle}
        tooltipContent={tooltipContent}
        tooltipProps={tooltipProps}
      />

      <CustomInputGroup
        rightElement={
          isError && (
            <Box
              style={{
                height: '100%',
                marginTop: 20,
              }}
            >
              <ErrorIconTooltip>{getNestedPropertyValue(id, formik.errors)}</ErrorIconTooltip>
            </Box>
          )
        }
      >
        <BlueprintTextArea
          id={id}
          name={id}
          onChange={(e) => formik.setFieldValue(id, e.target.value)}
          onBlur={() => formik.setFieldTouched(id, true)}
          fill={fill}
          growVertically={growVertically}
          inputRef={inputRef}
          large={large}
          small={small}
          placeholder={placeholder}
          readOnly={readOnly}
          intent={intent || (isError ? 'danger' : 'none')}
          className={{ 'bp5-input-group bp5-intent-danger': isError, ...className }}
          disabled={disabled}
          value={getPropValue(id, formik.values)}
          {...rest}
        />
      </CustomInputGroup>

      <InputHelperLabel
        id={id}
        isError={isError}
        errorTextStyle={errorTextStyle}
        formikErrors={formik?.errors}
        helperText={helperText}
      />
    </Box>
  );
};

TextArea.propTypes = {
  id: PropTypes.string.isRequired,
  formik: PropTypes.object.isRequired,
  fill: PropTypes.bool,
  growVertically: PropTypes.bool,
  inputRef: PropTypes.object,
  intent: PropTypes.oneOf(['none', 'primary', 'success', 'warning', 'danger']),
  large: PropTypes.bool,
  small: PropTypes.bool,
  label: PropTypes.string,
  helperText: PropTypes.string,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  labelStyle: PropTypes.object,
  tooltipContent: PropTypes.node,
  tooltipProps: PropTypes.object,
  errorTextStyle: PropTypes.object,
};

TextArea.defaultProps = {
  fill: false,
  growVertically: false,
  inputRef: null,
  intent: 'none',
  large: false,
  small: false,
  label: '',
  helperText: '',
  placeholder: '',
  readOnly: false,
  disabled: false,
  className: '',
  labelStyle: {},
  tooltipContent: null,
  tooltipProps: {},
  errorTextStyle: {},
};

export default TextArea;
