import { RadioGroup as BlueprintRadioGroup, Radio } from '@blueprintjs/core';
import PropTypes from 'prop-types';
import { useCallback } from 'react';

import { getNestedPropertyValue } from '../../utils/functions';
import Box from '../Box';
import Text from '../Text';

import ErrorText from './ErrorText';

const RadioGroup = ({ id, formik, label, onChange, optionsProps, disabled, inline, containerProps, ...rest }) => {
  const getPropValue = useCallback((path, object) => getNestedPropertyValue(path, object), []);
  const isError = getPropValue(id, formik.touched) && Boolean(getPropValue(id, formik.errors));

  return (
    <Box position="relative" width="100%" pb={0.5} {...containerProps}>
      <BlueprintRadioGroup
        onChange={onChange || ((e) => formik.setFieldValue(id, e.target.value))}
        selectedValue={getPropValue(id, formik.values)}
        disabled={disabled}
        inline={inline}
        {...(label && { label })}
        {...rest}
      >
        {optionsProps.map((optionProps) => (
          <Radio
            {...optionProps}
            key={optionProps.value}
            label={<Text style={{ display: 'inline' }}>{optionProps.label}</Text>}
          />
        ))}
      </BlueprintRadioGroup>

      {isError && <ErrorText>{getPropValue(id, formik.errors)}</ErrorText>}
    </Box>
  );
};

RadioGroup.propTypes = {
  id: PropTypes.string.isRequired,
  formik: PropTypes.object.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func,
  optionsProps: PropTypes.array,
  disabled: PropTypes.bool,
  inline: PropTypes.bool,
  containerProps: PropTypes.object,
};

RadioGroup.defaultProps = {
  onChange: null,
  optionsProps: [],
  disabled: false,
  inline: false,
  label: null,
  containerProps: {},
};

export default RadioGroup;
