import dayjs from 'dayjs';
import calendarTime from 'dayjs/plugin/calendar';
import PropTypes from 'prop-types';

import { TOPBAR_HEIGHT } from '../../constants';
import Box from '../Box';
import Stack from '../Stack';
import Text from '../Text';

import './Topbar.scss';

import Notifications from './components/Notifications';

dayjs.extend(calendarTime);

const Topbar = ({ leftElement, rightElement }) => {
  return (
    <Stack direction="row" width="100%" height={`${TOPBAR_HEIGHT}px`} className="topbar">
      <Box width="100%" height="100%" px={2} py={1}>
        <Stack justifyContent="space-between" direction="row" alignItems="center" height="100%">
          {leftElement}

          <Stack direction="row" alignItems="center" justifyContent="space-between" gap={3}>
            {rightElement}
            <Text disableGutter>v{import.meta.env.APP_VERSION}</Text>
            <Notifications />
          </Stack>
        </Stack>
      </Box>
    </Stack>
  );
};

Topbar.propTypes = {
  leftElement: PropTypes.node,
  rightElement: PropTypes.node,
};

export default Topbar;
